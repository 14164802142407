import * as React from "react";
import { AlertType } from "../../shared/constants/AppConst";
import { AlertTitle, Snackbar, Stack } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface VAAlertProps {
  alertMessage: AlertType;
  setAlertMessage: any;
}
const VAAlert: React.FC<VAAlertProps> = ({ alertMessage, setAlertMessage }) => {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage({ type: "", title: "", description: "", open: false });
  };
  return (
    <Stack sx={{ width: "90%" }} spacing={2}>
      <Snackbar open={alertMessage.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={alertMessage.type} sx={{ width: "90%" }}>
          {alertMessage.title ? <AlertTitle>{alertMessage.title}</AlertTitle> : null}
          {alertMessage.description}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
export default VAAlert;
