import React, { ReactElement, useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import ScheduleAppointmentTable from "./ScheduleAppointmentTable";
import { Grid, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DashboardLayout, InputWithIcon, FromSelect, DatePicker } from "../../../components";
import { getScheduleAppointments } from "../../../redux/actionCreators";
import { AppState } from "../../../redux/store";
import { ScheduleAppointmentModel } from "../../../types/models/Provider/ScheduleAppointment";
dayjs.extend(utc);
export const ScheduleAppointment = (): ReactElement => {
  /** START SCHEDULE APPOINTMENT STATES */
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [scheduleAppointments, setScheduleAppointments] = useState<ScheduleAppointmentModel[]>([]);
  const [searchDate, setSearchDate] = React.useState<dayjs.Dayjs | null>(null);
  const [searchPatient, setSearchPatient] = useState("");
  const [searchStatus, setSearchStatus] = React.useState("");
  const [statusOptions] = React.useState([
    { value: "", label: "None" },
    { value: "Pre-appointment paperwork remaining", label: "Paper work remaining" },
    { value: "Pre-appointment paperwork done", label: "Paper work done" },
    { value: "Verified", label: "Verified" },
  ]);
  /** END SCHEDULE APPOINTMENT STATES */

  const handleChange = (event: any) => {
    if (event.target.name === "patient") {
      setSearchPatient(event.target.value as string);
    }
    if (event.target.name === "status") {
      setSearchStatus(event.target.value as string);
    }
  };
  useEffect(() => {
    const getScheduleAppointmentsData = async () => {
      try {
        let res = await getScheduleAppointments({ biz_branch_id: userData.selected_branch.biz_branch_id });
        setScheduleAppointments(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getScheduleAppointmentsData();
  }, [userData]);
  const filteredAppointments = scheduleAppointments.filter((appointment) => {
    // Convert pendingAppointment.date to Dayjs object
    const appointmentDate = dayjs.utc(appointment.date);

    const dateMatch = !searchDate || appointmentDate.isSame(searchDate, "day");
    const patientNameMatch = appointment.patient_name.toLowerCase().includes(searchPatient.toLowerCase());
    let statusMatch = false;
    if (searchStatus.toLowerCase() === "verified") {
      statusMatch = appointment.verify === true;
    } else statusMatch = appointment.status.toLowerCase().includes(searchStatus.toLowerCase());
    return dateMatch && patientNameMatch && statusMatch;
  });
  return (
    <DashboardLayout>
      <div>
        <h2 className="text-[24px] text-primary-main font-[600]">Scheduled Appointments</h2>
      </div>
      <Stack direction="row" sx={{ mb: { xs: 3, xl: 4 }, mt: { xs: 2, xl: 3 } }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <DatePicker label="Date" value={searchDate} setValue={setSearchDate} />
          </Grid>

          <Grid item xs={6}>
            <InputWithIcon label="Search by patient" name="patient" value={searchPatient} onChange={handleChange} StartIcon={SearchIcon} />
          </Grid>

          <Grid item xs={3}>
            <FromSelect name="status" label="Status" value={searchStatus} onChange={handleChange} options={statusOptions} />
          </Grid>
        </Grid>
      </Stack>

      <ScheduleAppointmentTable scheduleAppointments={filteredAppointments} setScheduleAppointments={setScheduleAppointments} />
    </DashboardLayout>
  );
};
