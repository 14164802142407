import { AppActions } from "../../types";
import { SET_USER_DATA } from "../../types/actions/user.action";

const initialState: {
  userData: any;
} = {
  userData: {
    user_id: 0,
    name: "",
    email: "",
    type: "",
  },
};
const User = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    default:
      return state;
  }
};
export default User;
