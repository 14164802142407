// Create Profile Action Creator
import jwtAxios from "../../services/auth/jwt-auth";
import { ProviderInfoModel } from "../../types/models/Provider";

export const onSignup = async (signupData: ProviderInfoModel) => {
  try {
    const res = await jwtAxios.post("/user/signup", { signupData });
    return res.data;
  } catch (error) {
    console.log(error);
    alert(error);
  }
};

export const checkEmail = async (email: any) => {
  try {
    const res = await jwtAxios.post("/user/checkEmail", { email });
    return res.data;
  } catch (error) {
    console.log(error);
    alert(error);
  }
};
