import { ReactElement } from 'react';

export const AllDay = (): ReactElement => {
  return (
    <div className="grid grid-cols-7 mt-4 text-center  items-center gap-px font-semibold ">
      <div className="pl-1 text-sm p-[20px] ">Mo</div>
      <div className="pl-1 text-sm p-[20px] ">Tu</div>
      <div className="pl-1 text-sm p-[20px] ">We</div>
      <div className="pl-1 text-sm p-[20px] ">Th</div>
      <div className="pl-1 text-sm p-[20px] ">Fr</div>
      <div className="pl-1 text-sm p-[20px] ">Sa</div>
      <div className="pl-1 text-sm">Su</div>
    </div>
  );
};
