import { ReactElement } from "react";
import { Sidebar } from "../../Sidebar";

interface DashboardProps {
  children?: any;
}

export const DashboardLayout = ({ children }: DashboardProps): ReactElement => {
  return (
    <div className="flex flex-col w-full md:grid md:grid-cols-[30%_auto] h-[100vh]">
      <div className="">
        <Sidebar />
      </div>
      <div className=" bg-[#f1f2f5] p-[20px] overflow-y-auto">{children}</div>
    </div>
  );
};
