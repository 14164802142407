import { useState } from "react";
import { State, City } from "country-state-city";

export function useFormFieldsHandleChange<T>(initialState: T): [T, (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void] {
  const [inputs, setValues] = useState<T>(initialState);

  return [
    inputs,
    function (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      if (event.target.type === "checkbox") {
        const target = event.target as HTMLInputElement;
        setValues({
          ...inputs,
          [event.target.name]: target.checked,
        });
      } else {
        setValues({
          ...inputs,
          [event.target.name]: event.target.value,
        });
      }
    },
  ];
}
export function getStateIsoCode(countryCode: string, stateName: string) {
  if (!countryCode || !stateName) return "";

  const states = State.getStatesOfCountry(countryCode);
  const state = states.find((state) => state.name.toLowerCase() === stateName.toLowerCase());

  return state ? state.isoCode : "";
}
// Function to get a specific city by name
export function getCityByName(countryCode: string, stateCode: string, cityName: string) {
  const cities = City.getCitiesOfState(countryCode, stateCode);
  const city = cities.find((city) => city.name.toLowerCase() === cityName.toLowerCase());
  return city;
}
