import { ReactElement, useState } from "react";
import { styled } from "@mui/material/styles";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

interface InputProps {
  name?: string;
  label?: string | undefined;
  placeholder?: string;
  value?: string;
  required?: boolean;
  type?: string;
  StartIcon?: any;
  EndIcon?: any;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
  },
  "& input.MuiInputBase-input": {
    paddingLeft: "18px",
  },
  "& label.Mui-focused": {
    color: "#888A8C",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CDCFD2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#CDCFD2",
    },
    "&:hover fieldset": {
      borderColor: "#CDCFD2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CDCFD2",
    },
  },
});

export const InputWithIcon = ({ label, StartIcon, EndIcon, name, value, placeholder, type, required, onChange, onBlur }: InputProps): ReactElement => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <>
      {type === "password" ? (
        <CssTextField
          id="custom-css-outlined-input"
          label={label}
          name={name}
          value={value}
          placeholder={placeholder}
          type={showPassword ? "text" : type}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          InputProps={{
            startAdornment: StartIcon && (
              <InputAdornment position="start" sx={{ marginLeft: "15px" }}>
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: EndIcon && (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <CssTextField
          id="custom-css-outlined-input"
          label={label}
          name={name}
          value={value}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          InputProps={{
            startAdornment: StartIcon && (
              <InputAdornment position="start" sx={{ marginLeft: "15px" }}>
                <StartIcon />
              </InputAdornment>
            ),
            endAdornment: EndIcon && (
              <InputAdornment position="end">
                <EndIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
};
