import { ReactElement } from "react";
import { FormInput, FromSelect, NumberFormatField } from "../../../../../components";
import { CoAdminUserModel, ErrorsCoAdminUserModel } from "../../../../../types/models/Provider/Location/CoAdminUser";
import { MENU } from "../../../../../types/actions/Common.action";
import { Checkbox } from "@mui/material";
interface CoAdminUserProps {
  selectedMenu: MENU;
  providerRoles: any;
  coAdminUserInputs: CoAdminUserModel;
  errorsCoAdminUser: ErrorsCoAdminUserModel;
  validateErrorCoAdminUser: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleCoAdminUserChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const CoAdminUser = ({
  selectedMenu,
  providerRoles,
  coAdminUserInputs,
  errorsCoAdminUser: errors,
  validateErrorCoAdminUser: validateError,
  handleCoAdminUserChange,
}: CoAdminUserProps): ReactElement => {
  return (
    <div className="mt-[22px]">
      <div className="text-[14px] font-[600] text-[#1F2124]">Co Admin User</div>
      <div className="mt-[20px]">
        <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
          <FormInput
            name="firstName"
            label="Legal First Name"
            type="text"
            value={coAdminUserInputs.firstName}
            validate={{ error: errors.firstName.error, message: errors.firstName.message }}
            required
            onChange={handleCoAdminUserChange}
            onBlur={validateError}
          />
          <FormInput
            name="lastName"
            label="Legal Last Name"
            type="text"
            value={coAdminUserInputs.lastName}
            validate={{ error: errors.lastName.error, message: errors.lastName.message }}
            required
            onChange={handleCoAdminUserChange}
            onBlur={validateError}
          />
        </div>
        <div className="mt-[20px]">
          <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px] ">
            <FormInput
              name="email"
              label="Email"
              value={coAdminUserInputs.email}
              validate={{ error: errors.email.error, message: errors.email.message }}
              required
              onChange={handleCoAdminUserChange}
              onBlur={validateError}
            />
            <FromSelect
              name="role"
              label="Role"
              value={coAdminUserInputs.role}
              validate={{ error: errors.role.error, message: errors.role.message }}
              required
              onChange={handleCoAdminUserChange}
              options={providerRoles}
              onBlur={validateError}
            />
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
            <NumberFormatField
              name="phone"
              label="Phone Number"
              value={coAdminUserInputs.phone}
              validate={{ error: errors.phone.error, message: errors.phone.message }}
              required
              onChange={handleCoAdminUserChange}
              onBlur={validateError}
            />
            {selectedMenu === "ADD" && (
              <FormInput
                name="password"
                label="Password"
                type="password"
                value={coAdminUserInputs.password}
                validate={{ error: errors.password.error, message: errors.password.message }}
                required
                onChange={handleCoAdminUserChange}
                onBlur={validateError}
                autoComplete={"new-password"}
              />
            )}
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
            {selectedMenu === "ADD" && (
              <FormInput
                name="confirm_password"
                label="Confirm Password"
                type="password"
                value={coAdminUserInputs.confirm_password}
                validate={{ error: errors.confirm_password.error, message: errors.confirm_password.message }}
                required
                onChange={handleCoAdminUserChange}
                onBlur={validateError}
                autoComplete={"off"}
              />
            )}
            <div className="flex items-center ">
              <Checkbox name="active" checked={coAdminUserInputs.active} color={coAdminUserInputs.active ? "success" : "default"} onChange={handleCoAdminUserChange} />
              <span className="text-[#1F2124] text-[14px] font-[400]">{"Active user"}</span>
            </div>
            <div className="flex items-center ">
              <Checkbox name="branch_access" checked={coAdminUserInputs.branch_access} color={coAdminUserInputs.branch_access ? "success" : "default"} onChange={handleCoAdminUserChange} />
              <span className="text-[#1F2124] text-[14px] font-[400]">{"Branch access"}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
