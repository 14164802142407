import { ReactElement } from "react";
import { DayCard } from "./DayCard.component";

interface DayCardListProps {
  selectedMonthEvents: any;
  firstDayOfMonth: any;
  setSelectedMonthEvents: any;
}

export const DayCardList = ({ selectedMonthEvents, setSelectedMonthEvents, firstDayOfMonth }: DayCardListProps): ReactElement => {
  // Use for generating empty divs to fill days from previous month
  const DAYS: any = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };
  const daysFromPrevMonth = Array.from({ length: DAYS?.[firstDayOfMonth] }, (_, i) => i + 1);

  // Total number of squares in the calendar
  const totalDays = daysFromPrevMonth.length + selectedMonthEvents.length;
  // Number of rows in the calendar
  const numRows = Math.ceil(totalDays / 7);
  return (
    <div className={`grid flex-grow w-full h-auto grid-cols-7 grid-rows-${numRows} gap-px pt-px  bg-gray-200`}>
      {/* Empty div used for days that are not in the month */}
      {daysFromPrevMonth.map((day) => (
        <div key={`day-${day}`}></div>
      ))}

      {selectedMonthEvents.map((dayData: any) => {
        return <DayCard key={dayData.date} date={dayData.date} dayData={dayData} setSelectedMonthEvents={setSelectedMonthEvents} />;
      })}
    </div>
  );
};
