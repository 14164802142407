import React, { useEffect, useState } from "react";
import { DashboardLayout, FormInput, FromSelect } from "../../../components";
import { getBusinesses } from "../../../redux/actionCreators/SuperAdmin/Business";
import { MENU } from "../../../types/actions/Common.action";
import BusinessesTable from "./BusinessesTable";

// Usage example in a React component
export const Businesses: React.FC = () => {
  const [businesses, setBusinesses] = useState<any>([]);
  const [searchBusiness, setSearchBusiness] = useState("");
  const [statuses] = useState([
    { label: "All", value: "All" },
    { label: "Suspended", value: "Suspended" },
  ]);
  const [status, setStatus] = useState("All");
  const [locationTypes, setLocationTypes] = useState([]);
  const [locationType, setLocationType] = useState("");
  const [selectedMenu, setSelectedMenu] = useState<MENU>("VIEW");
  const handleChange = (event: any) => {
    if (event.target.name === "business") {
      setSearchBusiness(event.target.value as string);
    }
    if (event.target.name === "status") {
      setStatus(event.target.value as string);
    }
    if (event.target.name === "locationType") {
      setLocationType(event.target.value as string);
    }
  };

  // const onRowDetailClick = (rowData: any) => {
  //   setSelectedMenu("DETAIL");
  // };
  // const onClickBack = () => {
  //   setSelectedMenu("VIEW");
  // };
  const getBusinessesData = async () => {
    try {
      let res = await getBusinesses();
      setBusinesses(res.businesses);
      setLocationTypes(res.locationTypes);
      setSelectedMenu("VIEW");
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBusinessesData();
  }, []);
  const filteredBusinesses = businesses.filter((business: any) => {
    const nameMatch = business.biz_name.toLowerCase().includes(searchBusiness.toLowerCase());
    const activeMatch = business.biz_active === true;

    return nameMatch && (status === "All" || activeMatch);
  });

  const renderMenu = () => {
    switch (selectedMenu) {
      case "VIEW":
        return (
          <div>
            <div>
              <h2 className="text-[24px] text-primary-main font-[600]">Providers</h2>
            </div>
            <div className="flex items-center justify-between py-[20px]">
              <div className="grid grid-cols-[1fr_1fr_1fr] items-center gap-[50px]">
                <FormInput label="Search by Business 🔍" name="business" value={searchBusiness} onChange={handleChange} />
                <FromSelect name="status" label="Status" value={status} options={statuses} onChange={handleChange} />
                <FromSelect name="locationType" label="Location Type" value={locationType} options={locationTypes} onChange={handleChange} />
              </div>
            </div>
            <div className="mt-[30px]">
              <BusinessesTable businesses={filteredBusinesses} setBusinesses={setBusinesses} />
            </div>
          </div>
        );
      default:
        break;
    }
  };
  return <DashboardLayout>{renderMenu()}</DashboardLayout>;
};
