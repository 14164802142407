import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { Divider, MenuList, MenuItem, Collapse } from "@mui/material";
import { AddVaccineAndPaperwork, CreateSchedules, KeyPassword, Logout, PendingVaccines, ProfileUser, Setting, ViewSchedule } from "../../icons";
import { Branches } from "../Dialogs";
import { SET_USER_DATA } from "../../types/actions/user.action";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { AlertType } from "../../shared/constants/AppConst";
import VAAlert from "../Alert/Alert";

const superAdminLinks = [
  {
    title: "Vaccine Data Importer",
    link: "/superadmin/vaccine-data-importer",
    icon: <ViewSchedule />,
  },
  {
    title: "Categories",
    link: "/superadmin/categories",
    icon: <ViewSchedule />,
  },
  {
    title: "Manufacturers List",
    link: "/superadmin/manufacturers",
    icon: <ViewSchedule />,
  },
  {
    title: "Vaccines",
    link: "/superadmin/vaccines",
    icon: <ViewSchedule />,
  },
  {
    title: "Diagnostic Tests",
    link: "/superadmin/diagnostics",
    icon: <ViewSchedule />,
  },
  {
    title: "Upload VIS",
    link: "/superadmin/uploadvis",
    icon: <ViewSchedule />,
  },
  {
    title: "Providers",
    link: "/superadmin/businesses",
    icon: <ProfileUser />,
  },
  {
    title: "Setting",
    link: "#",
    icon: <Setting />,
    subLinks: [
      {
        title: "Change Password",
        link: "/change-password",
        icon: <KeyPassword />,
      },
      {
        title: "Logout",
        link: "/",
        icon: <Logout />,
      },
    ],
  },
];

const providerLinks = [
  {
    title: "Change Location",
    link: "/change-location",
    icon: <ViewSchedule />,
  },
  {
    title: "View Schedule",
    link: "/schedule-appointment",
    icon: <ViewSchedule />,
  },
  {
    title: "Pending Vaccine Records to Verify",
    link: "/pending-vaccine-appointment",
    icon: <PendingVaccines />,
  },
  {
    title: "Add Vaccine(s) and Paperwork",
    link: "/vaccines-paperwork",
    icon: <AddVaccineAndPaperwork />,
  },
  {
    title: "Create/modify Vaccination Schedule",
    link: "/vaccine-schedule",
    icon: <CreateSchedules />,
  },
  {
    title: "Add Diagnostic Test(s) and Paperwork",
    link: "/tests-paperwork",
    icon: <AddVaccineAndPaperwork />,
  },
  {
    title: "Manage business profile and users",
    link: "/manage-biz",
    icon: <Setting />,
  },
  // {
  //   title: "Manage business profile and users",
  //   link: "#",
  //   icon: <Setting />,
  //   subLinks: [
  //     {
  //       title: "Manage Users",
  //       link: "/my-profile",
  //       icon: <ProfileUser />,
  //     },
  //     {
  //       title: "Manage Business",
  //       link: "/manage-biz",
  //       icon: <KeyPassword />,
  //     },
  //   ],
  // },
  {
    title: "Setting",
    link: "#",
    icon: <Setting />,
    subLinks: [
      {
        title: "Change Password",
        link: "/change-password",
        icon: <KeyPassword />,
      },
      {
        title: "Logout",
        link: "/",
        icon: <Logout />,
      },
    ],
  },
];

export default function IconMenu() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(userData.selected_branch);
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const checkIfSublinkSelected = (links: any[]) => {
      links.forEach((link) => {
        if (link.subLinks) {
          link.subLinks.forEach((sublink: any) => {
            if (location.pathname === sublink.link) {
              setExpanded((prev) => ({ ...prev, [link.title]: true }));
            }
          });
        }
      });
    };

    if (userData.type === "VACCIFI") {
      checkIfSublinkSelected(superAdminLinks);
    } else if (userData.type === "PROVIDER" || userData.type === "COADMIN" || userData.type === "SECONDARY") {
      checkIfSublinkSelected(providerLinks);
    }
  }, [location.pathname, userData.type]);

  const onBranchSelect = (sBranch: any) => {
    let _selectedBranch = sBranch;

    if (!_selectedBranch.branch_active) {
      // If the selected branch/location is same as just deactivated branch then select the main branch
      if (Number(_selectedBranch.biz_branch_id) === Number(userData.selected_branch.biz_branch_id)) {
        _selectedBranch = userData?.branches.find((item: any) => item.branch_type === "Main") ?? {};
      } else _selectedBranch = userData.selected_branch;
      setAlertMessage({ type: "error", description: `You can not select the ${_selectedBranch.biz_branch_name} location because it is currently deactivated`, open: true });
    }
    setSelectedBranch(_selectedBranch);
    let dispatchData = { ...userData, selected_branch: _selectedBranch };

    dispatch({ type: SET_USER_DATA, payload: dispatchData });
    navigate("/schedule-appointment");
  };

  const handleExpandClick = (title: string) => {
    setExpanded((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  const renderLinks = (links: any[]) => {
    return links.map((linkMember) => {
      const hasPermission =
        linkMember.link === "/change-location" || linkMember.title === "Setting" ? true : userData?.permissions?.some((permission: any) => permission.permission === linkMember.title);
      if (userData.type !== "VACCIFI") {
        if (linkMember.subLinks) {
          return hasPermission ? (
            <React.Fragment key={linkMember.title}>
              <Divider />
              <MenuItem onClick={() => handleExpandClick(linkMember.title)}>
                <div className="flex justify-between w-full gap-[12px] py-[8px]">
                  <div className="flex gap-[12px]">
                    <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember.icon}</span>
                    <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember.title}</span>
                  </div>
                  {linkMember.subLinks ? expanded[linkMember.title] ? <ExpandLess /> : <ExpandMore /> : null}
                </div>
              </MenuItem>
              <Collapse in={expanded[linkMember.title]} timeout="auto" unmountOnExit>
                {linkMember.subLinks.map((sublink: any) => (
                  <MenuItem key={sublink.title} onClick={() => navigate(sublink.link)}>
                    <div className="flex gap-[12px] px-[28px] pt-[8px]">
                      <span className={` ${location.pathname === sublink.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{sublink.icon}</span>
                      <span className={` ${location.pathname === sublink.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{sublink.title}</span>
                    </div>
                  </MenuItem>
                ))}
              </Collapse>
            </React.Fragment>
          ) : null;
        }
        return hasPermission ? (
          <MenuItem
            key={linkMember.title}
            onClick={() => {
              if (linkMember.link !== "/change-location") navigate(linkMember.link);
            }}
          >
            <div className="flex gap-[12px] pt-[8px]">
              {linkMember.link === "/change-location" ? null : (
                <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.icon}</span>
              )}

              {linkMember.link === "/change-location" ? (
                <Branches selectedBranch={selectedBranch} onBranchSelect={onBranchSelect} /> // <span className={"text-[#ff5722]"}>{`Location: ${selectedBranch.biz_branch_name}`}</span>
              ) : (
                <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.title}</span>
              )}
              {/* <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember.icon}</span>
              <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember.title}</span> */}
            </div>
          </MenuItem>
        ) : null;
      } else {
        if (linkMember.subLinks) {
          return (
            <React.Fragment key={linkMember.title}>
              <Divider />
              <MenuItem onClick={() => handleExpandClick(linkMember.title)}>
                <div className="flex justify-between w-full gap-[12px] py-[8px]">
                  <div className="flex gap-[12px]">
                    <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember.icon}</span>
                    <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember.title}</span>
                  </div>
                  {linkMember.subLinks ? expanded[linkMember.title] ? <ExpandLess /> : <ExpandMore /> : null}
                </div>
              </MenuItem>
              <Collapse in={expanded[linkMember.title]} timeout="auto" unmountOnExit>
                {linkMember.subLinks.map((sublink: any) => (
                  <MenuItem key={sublink.title} onClick={() => navigate(sublink.link)}>
                    <div className="flex gap-[12px] px-[28px] pt-[8px]">
                      <span className={` ${location.pathname === sublink.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{sublink.icon}</span>
                      <span className={` ${location.pathname === sublink.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{sublink.title}</span>
                    </div>
                  </MenuItem>
                ))}
              </Collapse>
            </React.Fragment>
          );
        }

        return (
          <MenuItem
            key={linkMember.title}
            onClick={() => {
              if (linkMember.link !== "/change-location") navigate(linkMember.link);
            }}
          >
            <div className="flex gap-[12px] pt-[8px]">
              {linkMember.link === "/change-location" ? null : (
                <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.icon}</span>
              )}

              {linkMember.link === "/change-location" ? (
                <Branches selectedBranch={selectedBranch} onBranchSelect={onBranchSelect} /> // <span className={"text-[#ff5722]"}>{`Location: ${selectedBranch.biz_branch_name}`}</span>
              ) : (
                <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.title}</span>
              )}
              {/* <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember.icon}</span>
              <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember.title}</span> */}
            </div>
          </MenuItem>
        );
      }
    });
  };

  return (
    <MenuList>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      {userData.type === "VACCIFI" ? renderLinks(superAdminLinks) : userData.type === "PROVIDER" || userData.type === "COADMIN" || userData.type === "SECONDARY" ? renderLinks(providerLinks) : null}
      <Divider />
      {/* Additional links for and Logout */}
      {userData.type === "" ? (
        <MenuItem onClick={() => navigate("/")}>
          <div className="flex gap-[12px] px-[28px] pt-[8px]">
            <span className={` ${location.pathname === "/" ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>
              <Logout />
            </span>
            <span className={` ${location.pathname === "/" ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>Logout</span>
          </div>
        </MenuItem>
      ) : null}
    </MenuList>
  );
}
/*
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { Divider, MenuList, MenuItem } from "@mui/material";
import { AddVaccineAndPaperwork, CreateSchedules, KeyPassword, Logout, PendingVaccines, ProfileUser, Setting, ViewSchedule } from "../../icons";
import { Branches } from "../Dialogs";
import { SET_USER_DATA } from "../../types/actions/user.action";

const superAdminLinks = [
  {
    title: "Vaccine Data Importer",
    link: "/superadmin/vaccine-data-importer",
    icon: <ViewSchedule />,
  },
  {
    title: "Categories",
    link: "/superadmin/categories",
    icon: <ViewSchedule />,
  },
  {
    title: "Manufacturers List",
    link: "/superadmin/manufacturers",
    icon: <ViewSchedule />,
  },
  {
    title: "Vaccines",
    link: "/superadmin/vaccines",
    icon: <ViewSchedule />,
  },
  {
    title: "Diagnostic Tests",
    link: "/superadmin/diagnostics",
    icon: <ViewSchedule />,
  },
  {
    title: "Upload VIS",
    link: "/superadmin/uploadvis",
    icon: <ViewSchedule />,
  },
  {
    title: "Providers",
    link: "/superadmin/businesses",
    icon: <ProfileUser />,
  },
  {
    title: "Setting",
    link: "#",
    icon: <Setting />,
    subLinks: [
      {
        title: "Change Password",
        link: "/change-password",
        icon: <KeyPassword />,
      },
      {
        title: "Logout",
        link: "/",
        icon: <Logout />,
      },
    ],
  },
];

const providerLinks = [
  {
    title: "Change Location",
    link: "/change-location",
    icon: <ViewSchedule />,
  },
  {
    title: "View Schedule",
    link: "/schedule-appointment",
    icon: <ViewSchedule />,
  },
  {
    title: "Pending Vaccine Records to Verify",
    link: "/pending-vaccine-appointment",
    icon: <PendingVaccines />,
  },
  {
    title: "Add Vaccine(s) and Paperwork",
    link: "/vaccines-paperwork",
    icon: <AddVaccineAndPaperwork />,
  },
  {
    title: "Create/modify Vaccination Schedule",
    link: "/vaccine-schedule",
    icon: <CreateSchedules />,
  },
  {
    title: "Add Diagnostic Test(s) and Paperwork",
    link: "/tests-paperwork",
    icon: <AddVaccineAndPaperwork />,
  },

  {
    title: "Manage business profile and users",
    link: "#",
    icon: <Setting />,
    subLinks: [
      {
        title: "Manage Users",
        link: "/my-profile",
        icon: <ProfileUser />,
      },
      {
        title: "Manage Business",
        link: "/my-profile",
        icon: <KeyPassword />,
      },
    ],
  },
  {
    title: "Setting",
    link: "#",
    icon: <Setting />,
    subLinks: [
      {
        title: "Change Password",
        link: "/change-password",
        icon: <KeyPassword />,
      },
      {
        title: "Logout",
        link: "/",
        icon: <Logout />,
      },
    ],
  },
];
export default function IconMenu() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [selectedBranch, setSelectedBranch] = useState<any>(userData.selected_branch);

  const onBranchSelect = (sBranch: any) => {
    setSelectedBranch(sBranch);
    let dispatchData = userData;
    dispatchData.selected_branch = sBranch;
    dispatch({ type: SET_USER_DATA, payload: dispatchData });
  };

  return (
    <MenuList>
      {userData.type === "VACCIFI"
        ? superAdminLinks?.map((linkMember: any) => {
            if (linkMember?.subLinks) {
              return (
                <React.Fragment key={linkMember.title}>
                  <Divider />
                  <MenuItem>
                    <div className="flex gap-[12px] py-[8px]">
                      <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.icon}</span>
                      <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.title}</span>
                    </div>
                  </MenuItem>
                  {linkMember?.subLinks?.map((link: any) => {
                    return (
                      <MenuItem
                        key={link.title}
                        onClick={() => {
                          navigate(link.link);
                        }}
                      >
                        <div className="flex gap-[12px] px-[28px] pt-[8px]">
                          <span className={` ${location.pathname === link.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{link?.icon}</span>
                          <span className={` ${location.pathname === link.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{link?.title}</span>
                        </div>
                      </MenuItem>
                    );
                  })}
                </React.Fragment>
              );
            }
            return (
              <MenuItem
                key={linkMember.title}
                onClick={() => {
                  navigate(linkMember.link);
                }}
              >
                <div className="flex gap-[12px] pt-[8px]">
                  <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.icon}</span>
                  <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.title}</span>
                </div>
              </MenuItem>
            );
          })
        : userData.type === "PROVIDER" || userData.type === "COADMIN" || userData.type === "SECONDARY"
        ? providerLinks.map((linkMember: any) => {
            const hasPermission =
              userData.type === "SECONDARY" && userData.branches.length > 1 && linkMember.title === "Change Location"
                ? true
                : (userData.type !== "SECONDARY" && linkMember.title === "Change Location") || linkMember.title === "Setting"
                ? true
                : userData.permissions.some((permission: any) => permission.permission === linkMember.title);

            return hasPermission ? (
              <div key={linkMember.title}>
                <MenuItem
                  onClick={() => {
                    if (linkMember.link !== "/change-location") navigate(linkMember.link);
                  }}
                >
                  <div className="flex gap-[12px] pt-[8px]">
                    {linkMember.link === "/change-location" ? null : (
                      <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.icon}</span>
                    )}

                    {linkMember.link === "/change-location" ? (
                      <Branches branches={userData.branches} selectedBranch={selectedBranch} onBranchSelect={onBranchSelect} /> // <span className={"text-[#ff5722]"}>{`Location: ${selectedBranch.biz_branch_name}`}</span>
                    ) : (
                      <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.title}</span>
                    )}
                  </div>
                </MenuItem>
                {linkMember.subLinks && linkMember.subLinks.length > 0 && (
                  <React.Fragment>
                    <Divider />
                    {linkMember.subLinks.map((sublink: any) => {
                      const hasPermission =
                        sublink.title === "Change Password" || sublink.title === "Logout" || sublink.title === "Manage Users" || sublink.title === "Manage Business"
                          ? true
                          : userData.permissions.some((permission: any) => permission.permission === sublink.title);
                      return hasPermission ? (
                        <MenuItem
                          key={sublink.title}
                          onClick={() => {
                            navigate(sublink.link);
                          }}
                        >
                          <div className="flex gap-[12px] px-[28px] pt-[8px]">
                            <span className={` ${location.pathname === sublink.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{sublink?.icon}</span>
                            <span className={` ${location.pathname === sublink.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{sublink?.title}</span>
                          </div>
                        </MenuItem>
                      ) : null;
                    })}
                  </React.Fragment>
                )}
              </div>
            ) : null;
          })
        : null}
      <Divider />
      {userData.type === "" ? (
        <MenuItem
          onClick={() => {
            navigate("/");
          }}
        >
          <div className="flex gap-[12px] px-[28px] pt-[8px]">
            <span className={` ${location.pathname === "/" ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>
              <Logout />
            </span>
            <span className={` ${location.pathname === "/" ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>Logout</span>
          </div>
        </MenuItem>
      ) : null}
    </MenuList>
  );
}
*/
