import { ReactElement } from 'react';

export const AuthenticateAccount = (): ReactElement => {
  return (
    <svg
      width="74"
      height="74"
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5625 30.8333V13.875C11.5625 13.2617 11.8061 12.6735 12.2398 12.2398C12.6735 11.8061 13.2617 11.5625 13.875 11.5625H60.125C60.7383 11.5625 61.3265 11.8061 61.7602 12.2398C62.1939 12.6735 62.4375 13.2617 62.4375 13.875V30.8333C62.4375 55.119 41.8256 63.165 37.71 64.5295C37.2499 64.6879 36.7501 64.6879 36.29 64.5295C32.1744 63.165 11.5625 55.119 11.5625 30.8333Z"
        fill="#0F5175"
      />
      <path
        d="M49.7188 27.75L32.7603 43.9375L24.2812 35.8438"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
