import jwtAxios from "../../../services/auth/jwt-auth";

export const getCategoryAndMfgs = async () => {
  try {
    const res = await jwtAxios.post("/superadmin/vaccinedataimporter/getcatandmfg", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addVaccinesData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/vaccinedataimporter/addvaccinesdata", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
