import AddVaccine from "./Provider/AddVaccine";
import CreateProfile from "./Provider/CreateProfile";
import VaccineAndPaperwork from "./Provider/VaccineAndPaperwork";
import Common from "./Common";
import Login from "./Login";
import SignUp from "./SignUp";
import User from "./userData";

const reducers = {
  common: Common,
  createProfile: CreateProfile,
  Login: Login,
  signup: SignUp,
  AddVaccine: AddVaccine,
  User: User,
  VaccineAndPaperwork: VaccineAndPaperwork,
};

export default reducers;
