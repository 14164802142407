import React from "react";
import { Table, TableHead, TableBody } from "@mui/material";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

interface ManufacturersTableProps {
  manufacturers: any[];
  onClickEdit: (rowData: any) => void;
  onClickDelete: (manufacturer_id: any) => void;
}

const ManufacturersTable: React.FC<ManufacturersTableProps> = ({ manufacturers, onClickEdit, onClickDelete }) => {
  return (
    <Table stickyHeader sx={{ minWidth: 650 }} size="small" className="table">
      <TableHead>
        <TableHeading />
      </TableHead>
      <TableBody>
        {manufacturers.map((data: any, index: number) => (
          <TableItem data={data} key={data.id} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ManufacturersTable;
