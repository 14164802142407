import { isSameDay } from "date-fns";
import { ReactElement, useState } from "react";

interface RescheduleDayCardProps {
  date: any;
  setSelectedDate: any;
  selectedDate: any;
  scheduleData: any;
  setOpentimeSlotDialog: any;
}

export const RescheduleDayCard = ({ date, selectedDate, setSelectedDate, scheduleData, setOpentimeSlotDialog }: RescheduleDayCardProps): ReactElement => {
  //Extracts day from date object
  const day = date.getDate();

  //Checks if current day matches date
  const sameDayCheck = scheduleData.some((data: any) => isSameDay(new Date(data.date), new Date(date)));
  const isoDate = new Date(selectedDate).getDate();

  const currentDayStyles =
    sameDayCheck === true
      ? selectedDate !== null && day === isoDate
        ? {
            backgroundColor: "#45818e",
            color: "white",
          }
        : {
            backgroundColor: "#F48744",
            color: "white",
          }
      : {
          backgroundColor: "#ffffff",
        };

  const [show, setShow] = useState(false);
  const clickMethod = () => {
    scheduleData.forEach((element: any) => {
      if (new Date(element.date).toDateString() === date.toDateString()) {
        setShow(!show);
        setSelectedDate(element.date);
        setOpentimeSlotDialog(true);
      }
    });
  };

  return (
    <div
      onClick={() => clickMethod()}
      className={` ${show ? "text-[#FF6666]" : "text-[gray]"} relative flex flex-col items-center justify-center group px-[4px] cursor-pointer `}
      style={currentDayStyles}
    >
      <div className="flex justify-center items-center">
        <span className="mt-1 p-[8px] h-[35px] w-[35px] flex justify-center items-center  text-sm font-semibold ">{day}</span>
      </div>
    </div>
  );
};
