import React, { useState } from "react";
import { AppState } from "../../redux/store";
import { useSelector } from "react-redux";
import { ListItemText, ListItemButton, Collapse, MenuList, MenuItem } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ViewSchedule } from "../../icons";

interface Props {
  selectedBranch: any;
  onBranchSelect: (selectedBranch: any) => void;
}

export const Branches: React.FC<Props> = ({ selectedBranch, onBranchSelect }) => {
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [open, setOpen] = useState(false);

  const handleBranchSelect = (branch: any) => {
    onBranchSelect(branch);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  console.log("Branches: ", userData.branches);
  return (
    <div>
      <ListItemButton onClick={handleToggle}>
        <ListItemText primary={`Location - ${selectedBranch.biz_branch_name}`} className="text-[#ffffff]" />
        {open ? <ExpandLess className="text-[#ffffff]" /> : <ExpandMore className="text-s[#ffffff]" />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <MenuList component="div" disablePadding>
          {userData?.branches?.map((branch: any) => (
            <MenuItem
              key={branch.biz_branch_id}
              selected={selectedBranch?.biz_branch_id === branch.biz_branch_id}
              onClick={() => handleBranchSelect(branch)}
              className={` ${selectedBranch?.biz_branch_id === branch.biz_branch_id ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}
            >
              <div className="flex gap-[12px] px-[28px] pt-[8px]">
                <span className={` ${selectedBranch?.biz_branch_id === branch.biz_branch_id ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>
                  <ViewSchedule />
                </span>
                <span className={` ${selectedBranch?.biz_branch_id === branch.biz_branch_id ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{branch.biz_branch_name}</span>
              </div>
            </MenuItem>
            // <ListItemButton
            //   key={branch.biz_branch_id}
            //   selected={selectedBranch?.biz_branch_id === branch.biz_branch_id}
            //   onClick={() => handleBranchSelect(branch)}
            //   className={` ${selectedBranch?.biz_branch_id === branch.biz_branch_id ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}
            // >
            //   <ListItemText primary={branch.biz_branch_name} className={` ${selectedBranch?.biz_branch_id === branch.biz_branch_id ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`} />
            // </ListItemButton>
          ))}
        </MenuList>
      </Collapse>
    </div>
  );
};

// import React from "react";
// import { Box, Modal, List, ListItemText, ListItemButton } from "@mui/material";
// import { VAButton } from "..";

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   bgcolor: "background.paper",
//   border: "none",
//   borderStyle: "none",
//   borderRadius: "43px",
//   boxShadow: 24,
//   maxHeight: "100vh",
//   overflowY: "auto",
// };

// interface Props {
//   open: boolean;
//   setOpen: (value: React.SetStateAction<boolean>) => void;
//   branches: any[];
//   selectedBranch: any;
//   onBranchSelect: (selectedBranch: any) => void;
// }

// export const Branches: React.FC<Props> = ({ open, setOpen, branches, selectedBranch, onBranchSelect }) => {
//   const handleBranchSelect = (branch: any) => {
//     onBranchSelect(branch);
//     setOpen(false);
//   };

//   return (
//     <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
//       <Box sx={style}>
//         <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px]">
//           <div className="max-w-[360px] flex flex-col gap-[12px]">
//             <h2 className="text-[20px] font-[600] text-[#000000]">Select Location</h2>
//           </div>
//           <div className="mt-[12px]">
//             <div className="flex flex-col gap-[12px]">
//               <List>
//                 {branches.map((branch) => (
//                   <ListItemButton key={branch.biz_branch_id} selected={selectedBranch?.biz_branch_id === branch.biz_branch_id} onClick={() => handleBranchSelect(branch)}>
//                     <ListItemText primary={branch.biz_branch_name} />
//                   </ListItemButton>
//                 ))}
//               </List>
//             </div>
//           </div>
//         </div>
//       </Box>
//     </Modal>
//   );
// };
