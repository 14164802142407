import { ReactElement } from 'react';

interface ProfileUserProps {
  src?: string;
  name?: string;
  number?: string;
}

export const ProfileUser = ({
  src,
  name,
  number,
}: ProfileUserProps): ReactElement => {
  return (
    <div className="flex items-center gap-[12px]">
      <div className="relative flex">
        <div className="">
          <img src={src} alt="profile user" />
        </div>
        <div className="absolute -right-[10px] -bottom-[2px]">
          <span className=" py-[1px] px-[6px] bg-secondary-main rounded-[50%] text-[#ffffff] ">
            {number}
          </span>
        </div>
      </div>
      <div>
        <span className="text-[16px] font-[600] text-[#ffffff]">{name}</span>
      </div>
    </div>
  );
};
