import jwtAxios from "../../../services/auth/jwt-auth";

export const getVaccineCategory = async () => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/getvaccinecategory", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTestCategory = async () => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/gettestcategory", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOfferedMfgs = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/getofferedmfgs", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVaccineDataByCategory = async (categoryId: string) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/getvaccinebycategory", { categoryId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getOfferedVaccinesByMfg = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/getofferedvaccinesbymfg", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const AddNewVaccine = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/addnewvaccine", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
