import { ReactElement } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

interface InputProps {
  name?: string;
  label?: any;
  heading?: string;
  value?: string;
  placeholder?: string;
  validate?: { error: boolean; message: string };
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
  },
  "& input.MuiInputBase-input": {
    paddingLeft: "18px",
  },
  "& label.Mui-focused": {
    color: "#888A8C",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CDCFD2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#CDCFD2",
    },
    "&:hover fieldset": {
      borderColor: "#CDCFD2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CDCFD2",
    },
  },
});

export const FormInputMulti = ({ label, heading, name, value, placeholder, validate, required, onChange, onBlur }: InputProps): ReactElement => {
  return (
    <div>
      {heading ? (
        <div className="flex flex-col gap-[12px]">
          <h2 className="text-[16px] font-[600] text-[#1F2124]">{heading}</h2>
          <CssTextField
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            error={validate?.error}
            helperText={validate?.message}
            required={required}
            onChange={onChange}
            onBlur={onBlur}
            id="custom-css-outlined-input"
            multiline
            rows={4}
            maxRows={4}
          />
        </div>
      ) : (
        <div className="relative w-full">
          <CssTextField
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            error={validate?.error}
            helperText={validate?.message}
            required={required}
            onChange={onChange}
            onBlur={onBlur}
            id="custom-css-outlined-input"
            multiline
            rows={4}
            maxRows={4}
          />
        </div>
      )}
    </div>
  );
};
