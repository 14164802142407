import React from "react";
import { Table, TableHead, TableBody } from "@mui/material";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

interface businessesTableProps {
  businesses: any[];
  setBusinesses: any;
}

const businessesTable: React.FC<businessesTableProps> = ({ businesses, setBusinesses }) => {
  return (
    <Table stickyHeader sx={{ minWidth: 650 }} size="small" className="table">
      <TableHead>
        <TableHeading />
      </TableHead>
      <TableBody>
        {businesses.map((data: any, index: number) => (
          <TableItem data={data} key={data.id} setBusinesses={setBusinesses} />
        ))}
      </TableBody>
    </Table>
  );
};

export default businessesTable;
