import jwtAxios from "../../../services/auth/jwt-auth";

export const getScheduleAppointments = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleappointment/getscheduleappointments", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPendingVaccineAppointments = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleappointment/getpendingvaccineappointments", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyVaccineAppointment = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleappointment/verifyvaccineappointment", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyTestAppointment = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleappointment/verifytestappointment", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const shareVaccineAppointment = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleappointment/sharevaccineappointment", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
