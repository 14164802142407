import { ReactElement } from "react";
// components
import { MonthAndYear } from "./MonthAndYear.component";
import { DayCardList } from "./DayCardList.component";
import { parse } from "date-fns";
import { AllDays } from "./AllDays.component";
import useDate from "../../hooks/useDate";
import { getEventsByDayNumber, getMatchMonthAndYear } from "../../utils/calendar";
interface CalendarProps {
  selectedMonthEvents: any;
  setSelectedMonthEvents: any;
}
export const Calendar = ({ selectedMonthEvents, setSelectedMonthEvents }: CalendarProps): ReactElement => {
  const date = useDate();

  const eventsInSelectedMonth = getMatchMonthAndYear(date.month, date.year, selectedMonthEvents);

  // An array of days containing events for populating the calendar
  const days = Array.from({ length: date.daysInMonth }, (_, i) => {
    const currentDay = i + 1;
    const currentDayEvents: any = getEventsByDayNumber(currentDay, eventsInSelectedMonth);

    // const events = currentDayEvents.map((event: any) => {
    //   return event.events;
    // });
    //Creates dateObject using month spelled out in a string, currentDay and year
    const dateObject = parse(`${date.month}, ${currentDay}, ${date.year}`, "MMMM, d, yyyy", new Date());

    return {
      date: dateObject,
      // selectedMonthEvents: selectedMonthEvents,
      events: currentDayEvents.length > 0 ? currentDayEvents[0].events : [],
    };
  });
  return (
    <div className="overflow-x-scroll w-[800px] pr-[20px] md:w-full md:pr-[0px] flex flex-grow h-screen md:overflow-auto text-gray-700">
      <div className="flex flex-col flex-grow">
        <MonthAndYear month={date.month} year={date.year} handleNextMonth={date.getNextMonth} handlePreviousMonth={date.getPreviousMonth} />
        <AllDays />
        <DayCardList selectedMonthEvents={days} setSelectedMonthEvents={setSelectedMonthEvents} firstDayOfMonth={date.firstDay} />
      </div>
    </div>
  );
};
