import { Box, Card, Grid, Stack, Checkbox, IconButton } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardLayout, VAButton } from "../../../../components";
import { EditPenIcon } from "../../../../icons";
import { getSecondaryUsers } from "../../../../redux/actionCreators/Provider/ManageUsers";
import { AppState } from "../../../../redux/store";
export const ManageUsers = (): ReactElement => {
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [secondaryUsers, setSecondaryUsers] = useState<
    {
      name: "";
      role: "";
      email: "";
      phoneNo: "";
      licence: "";
    }[]
  >([]);
  const [editPermission] = useState(true);
  const [permissions, setPermissions] = useState<{ id: number; desc: string; checked: boolean }[]>([]);
  const onCheckBoxClick = (item: { id: number; desc: string; checked: boolean }) => {
    item.checked = !item.checked;
    setPermissions((prev) => prev.map((i) => (i.id === item.id ? item : i)));
  };
  const handlePermissionsChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPermissions({ ...permissions, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getSecondaryUsers({ email: userData.email });
        setSecondaryUsers(response.data.secondaryUsers);
        setPermissions(response.data.permissions);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [userData]);
  return (
    <DashboardLayout>
      <div>
        <h2 className="text-[24px] text-primary-main font-[600]">Manage Users</h2>
      </div>
      <Box flex={1} mt={4}>
        <Box mx="auto">
          <Box mb={6}>
            <Grid container spacing={2}>
              {secondaryUsers.map((user) => (
                <Grid item xs={12}>
                  <Card
                    sx={{
                      width: "100%",
                      padding: 2.5,
                      paddingLeft: { xs: 8, md: 2 },
                      overflow: "hidden",
                      borderRadius: 3,
                      boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Box>
                          <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Name: </span>
                          <br />
                          {user.name}
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Email: </span>
                          <br /> {user.email}
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Phone Number: </span>
                          <br /> {user.phoneNo}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={3}>
                        <Box>
                          <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Select Role: </span>
                          <br />
                        </Box>
                        <Stack direction="row" spacing={1}>
                          <Box>{user.role}</Box>
                          <IconButton style={{ padding: 0 }}>
                            <EditPenIcon fill="#114059" />
                          </IconButton>
                        </Stack>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">State Licence: </span>
                          <br /> {user.licence}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={9}>
                        <Stack direction="row" spacing={1}>
                          <Box>{"Assigned permissions"}</Box>
                          <IconButton style={{ padding: 0 }}>
                            <EditPenIcon fill="#114059" />
                          </IconButton>
                        </Stack>
                        {permissions.map((item) => (
                          <>
                            <div className="flex items-center gap-[12px]">
                              {editPermission ? <Checkbox checked={item.checked} onClick={() => onCheckBoxClick(item)} onChange={handlePermissionsChange} color="success" /> : null}
                              {item.desc}
                            </div>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12}>
                        {editPermission ? (
                          <Stack direction="row" alignItems="center" justifyContent={"end"} spacing={2}>
                            <VAButton text="Cancel" outlined size="small" />
                            <VAButton text="save" dark size="small" />
                          </Stack>
                        ) : (
                          <Stack direction="row" alignItems="center" justifyContent={"end"} spacing={2}>
                            <VAButton text="Delete user" outlined size="small" />
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};
