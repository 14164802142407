import { ReactElement } from "react";

export const AllDays = (): ReactElement => {
  return (
    <div className="grid grid-cols-7 mt-4 text-center bg-[white] items-center gap-px font-semibold ">
      <div className="pl-1 text-sm p-[20px] border-r border-r-[#e5e7eb]">
        Monday
      </div>
      <div className="pl-1 text-sm p-[20px] border-r border-r-[#e5e7eb]">
        Tuesday
      </div>
      <div className="pl-1 text-sm p-[20px] border-r border-r-[#e5e7eb]">
        Wednesday
      </div>
      <div className="pl-1 text-sm p-[20px] border-r border-r-[#e5e7eb]">
        Thursday
      </div>
      <div className="pl-1 text-sm p-[20px] border-r border-r-[#e5e7eb]">
        Friday
      </div>
      <div className="pl-1 text-sm p-[20px] border-r border-r-[#e5e7eb]">
        Saturday
      </div>
      <div className="pl-1 text-sm">Sunday</div>
    </div>
  );
};
