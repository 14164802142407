import React from "react";
import { Table, TableHead, TableBody } from "@mui/material";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

interface UploadVISTableProps {
  rows: any[];
  onClickDelete: (sa_vis_id: any) => void;
  onClickEdit: (sa_vis_id: any) => void;
}

const UploadVISTable: React.FC<UploadVISTableProps> = ({ rows, onClickDelete, onClickEdit }) => {
  return (
    <Table stickyHeader sx={{ minWidth: 650 }} size="small" className="table">
      <TableHead>
        <TableHeading />
      </TableHead>
      <TableBody>
        {rows.map((row: any, index: number) => (
          <TableItem row={row} key={index} onClickDelete={onClickDelete} onClickEdit={onClickEdit} />
        ))}
      </TableBody>
    </Table>
  );
};

export default UploadVISTable;
