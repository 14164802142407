import { ReactElement } from 'react';

export const CreateSchedules = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 2.25H10.9436C9.10582 2.24998 7.65019 2.24997 6.51098 2.40313C5.33856 2.56076 4.38961 2.89288 3.64124 3.64124C2.89288 4.38961 2.56076 5.33856 2.40313 6.51098C2.24997 7.65019 2.24998 9.10582 2.25 10.9436V11V13V13.0564C2.24998 14.8942 2.24997 16.3498 2.40313 17.489C2.56076 18.6614 2.89288 19.6104 3.64124 20.3588C4.38961 21.1071 5.33856 21.4392 6.51098 21.5969C7.65018 21.75 9.1058 21.75 10.9435 21.75H10.9436H11H13H13.0564H13.0565C14.8942 21.75 16.3498 21.75 17.489 21.5969C18.6614 21.4392 19.6104 21.1071 20.3588 20.3588C21.1071 19.6104 21.4392 18.6614 21.5969 17.489C21.75 16.3498 21.75 14.8942 21.75 13.0565V13.0564V13V11V10.9436V10.9435C21.75 9.1058 21.75 7.65018 21.5969 6.51098C21.4392 5.33856 21.1071 4.38961 20.3588 3.64124C19.6104 2.89288 18.6614 2.56076 17.489 2.40313C16.3498 2.24997 14.8942 2.24998 13.0564 2.25H13H11ZM4.7019 4.7019C5.12511 4.27869 5.70476 4.02502 6.71085 3.88976C7.73851 3.75159 9.09318 3.75 11 3.75H13C14.9068 3.75 16.2615 3.75159 17.2892 3.88976C18.2952 4.02502 18.8749 4.27869 19.2981 4.7019C19.7213 5.12511 19.975 5.70476 20.1102 6.71085C20.2484 7.73851 20.25 9.09318 20.25 11V13C20.25 14.9068 20.2484 16.2615 20.1102 17.2892C19.975 18.2952 19.7213 18.8749 19.2981 19.2981C18.8749 19.7213 18.2952 19.975 17.2892 20.1102C16.2615 20.2484 14.9068 20.25 13 20.25H11C9.09318 20.25 7.73851 20.2484 6.71085 20.1102C5.70476 19.975 5.12511 19.7213 4.7019 19.2981C4.27869 18.8749 4.02502 18.2952 3.88976 17.2892C3.75159 16.2615 3.75 14.9068 3.75 13V11C3.75 9.09318 3.75159 7.73851 3.88976 6.71085C4.02502 5.70476 4.27869 5.12511 4.7019 4.7019ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V11.25H7.99997C7.58576 11.25 7.24997 11.5858 7.24997 12C7.24997 12.4142 7.58576 12.75 7.99997 12.75H11.25V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H12.75V8Z"
        fill="white"
        fill-opacity="0.6"
      />
    </svg>
  );
};
