import jwtAxios from "../../../services/auth/jwt-auth";

export const getCategories = async () => {
  try {
    const res = await jwtAxios.post("/superadmin/categories/getcategories", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addCategory = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/categories/addcategory", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateCategory = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/categories/updatecategory", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteCategory = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/categories/deletecategory", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
