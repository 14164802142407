import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../../components/Table/TableHeader";
const TableHeading: React.FC = () => {
  return (
    <TableHeader>
      <TableCell align="left">Patient name</TableCell>
      <TableCell align="left">Vaccine</TableCell>
      <TableCell align="left">Appointment time</TableCell>
      <TableCell align="left">Status</TableCell>
      <TableCell align="center">Action</TableCell>
    </TableHeader>
  );
};

export default TableHeading;
