/* eslint-disable array-callback-return */
import { isSameDay } from "date-fns";
import { ReactElement, useState } from "react";

interface DayCardProps {
  selectedDays: { day: number }[];
  setSelectedDays: any;
  date: any;
  events: any;
}

export const DayCard = ({ date, events, selectedDays, setSelectedDays }: DayCardProps): ReactElement => {
  //Extracts day from date object
  const day = date.getDate();

  //Checks if current day matches date
  const sameDayCheck = isSameDay(new Date(date), new Date());

  //Aplies styling if isSameDay is true
  const currentDayStyles =
    sameDayCheck === true
      ? {
          backgroundColor: "rgba(244, 135, 68, 0.25)",
          borderRadius: "50%",
        }
      : {};
  const [show, setShow] = useState(false);
  const handleSelectedDates = (slctdDay: any) => {
    const existingIndex = selectedDays.findIndex((selectedDay) => selectedDay.day === slctdDay);

    if (existingIndex >= 0) {
      // Day already exists, remove it
      const newSelectedDays = selectedDays.filter((selectedDay) => selectedDay.day !== slctdDay);
      setSelectedDays(newSelectedDays);
    } else {
      // Day doesn't exist, add it
      const newSelectedDays = [...selectedDays, { day: slctdDay }];
      setSelectedDays(newSelectedDays);
    }

    setShow((show) => !show);
  };
  return (
    <div
      onClick={events.length > 0 ? undefined : () => handleSelectedDates(day)}
      className={` ${events.length > 0 ? "text-white" : show ? "text-white" : "text-[#F48744]"} relative flex flex-col items-center justify-center group px-[4px] cursor-pointer `}
      style={{ backgroundColor: events.length > 0 ? "#B4B8BB" : show ? "#FF914D" : "#ffffff" }}
    >
      <div className="flex justify-center items-center">
        <span style={currentDayStyles} className="mt-1 p-[8px] h-[35px] w-[35px] flex justify-center items-center  text-sm font-semibold ">
          {day}
        </span>
      </div>
    </div>
  );
};
