import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../../redux/store";
import { SET_USER_DATA } from "../../../types/actions/user.action";
import { CustomStepper, DashboardLayout, VAButton } from "../../../components";
import { ManageBizSection } from "../../../sections/Provider/ManageBiz";
import { getBizBranches, onCreateLocation, onEditLocation, onUpdateLocation, updateBiz } from "../../../redux/actionCreators";
import VAAlert from "../../../components/Alert/Alert";
import { AlertType } from "../../../shared/constants/AppConst";
import { MENU } from "../../../types/actions/Common.action";
import { City, ICity, IState, State } from "country-state-city";
import { CoAdminUserModel } from "../../../types/models/Provider/Location/CoAdminUser";
import { CreateLocationModel } from "../../../types/models/Provider/Location/CreateLocation";
import { getProviderRolesAndLocTypes } from "../../../redux/actionCreators/Provider/ManageUsers";
import { LocationInfo, LocationUserProfile } from "../../../sections/Provider/ManageBiz/CreateLocation";
import { Back } from "../../../icons";
import { Box } from "@mui/material";
import { LocationSecondaryUserModel } from "../../../types/models/Provider/Location/LocationSecondaryUser";
import { getCityByName, getStateIsoCode } from "../../../utils/CustomFunctions.util";
import { UpdateLocationModel } from "../../../types/models/Provider/Location/UpdateLocation";
import { LocationInfoModel } from "../../../types/models/Provider/Location/LocationInfo";

export const ManageBiz = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [selectedMenu, setSelectedMenu] = useState<MENU>("VIEW");
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [editMainBranch, setEditMainBranch] = useState(false);
  const [mainBranch, setMainBranch] = useState<any>([]);
  const [searchSubBranchName, setSearchSubBranchName] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [statusOptions] = useState([
    { value: "", label: "Unselect" },
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ]);
  const [searchLocType, setSearchLocType] = useState("");
  const [locTypeOptions, setLocTypeOptions] = useState([]);
  const [allLocTypeOptions, setAllLocTypeOptions] = useState([]); // used for location selection dropdown when editing the record
  const [subBranches, setSubBranches] = useState<any>([]);

  // Start Sub Location Create/Edit States
  const steps = ["Location Info", "User Info"];
  const [activeStep, setActiveStep] = useState(0);
  const [providerRoles, setProviderRoles] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // End   Sub Location Create/Edit States

  // Location Info State and Handlers
  const [locationInfoInputs, setLocationInfoInputs] = useState<LocationInfoModel>({
    id: 0,
    locationName: "",
    locationPhone: "",
    locationAddress: "",
    locationEmail: "",
    locationCity: null,
    locationType: "",
    locationState: null,
    locationZip: "",
    active: true,
  });
  const [errorsLocationInfo, setErrorsLocationInfo] = useState({
    locationName: { error: false, message: "" },
    locationPhone: { error: false, message: "" },
    locationAddress: { error: false, message: "" },
    locationEmail: { error: false, message: "" },
    locationCity: { error: false, message: "" },
    locationType: { error: false, message: "" },
    locationState: { error: false, message: "" },
    locationZip: { error: false, message: "" },
  });
  const handleResetLocationInfo = () => {
    setLocationInfoInputs({
      locationName: "",
      locationPhone: "",
      locationAddress: "",
      locationEmail: "",
      locationCity: null,
      locationType: "",
      locationState: null,
      locationZip: "",
      active: true,
    });
    setErrorsLocationInfo({
      locationName: { error: false, message: "" },
      locationPhone: { error: false, message: "" },
      locationAddress: { error: false, message: "" },
      locationEmail: { error: false, message: "" },
      locationCity: { error: false, message: "" },
      locationType: { error: false, message: "" },
      locationState: { error: false, message: "" },
      locationZip: { error: false, message: "" },
    });
  };
  const handleLocationFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value, type } = e.target;
    const updatedLocationInfo: any = { ...locationInfoInputs };
    if (type === "checkbox") {
      const target = e.target as HTMLInputElement;
      (updatedLocationInfo as any)[name] = target.checked;
    } else (updatedLocationInfo as any)[name] = value;
    setLocationInfoInputs(updatedLocationInfo);
  };
  const handleAutoComplete = (event: React.SyntheticEvent, value: any | [], name: any) => {
    // Create a copy of the current locationInfo
    const updatedLocationInfo: any = { ...locationInfoInputs };

    // Use type assertion to tell TypeScript that `name` is a valid key
    (updatedLocationInfo as any)[name] = value;
    // Update the state with the modified locationInfo
    setLocationInfoInputs(updatedLocationInfo);
    if (name === "locationState") {
      if (value === null) {
        updatedLocationInfo.locationCity = null;
        setCities([]); // Assuming setCities is a state updater for cities, reset it to an empty array when state is null
        return; // Exit the function early
      }
      updatedLocationInfo.locationCity = null;
      const cities = City.getCitiesOfState("US", value.isoCode);
      setCities(cities);
    }
  };
  const validateErrorLocationInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsLocationInfo({ ...errorsLocationInfo, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorsLocationInfo({ ...errorsLocationInfo, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormLocationInfo = () => {
    let formIsValid = true;
    let localErrorsLocationInfo = errorsLocationInfo;
    const message = "Required Field!";
    if (locationInfoInputs.locationAddress === "") {
      formIsValid = false;
      localErrorsLocationInfo.locationAddress = { error: true, message };
    }
    if (locationInfoInputs.locationCity === "") {
      formIsValid = false;
      localErrorsLocationInfo.locationCity = { error: true, message };
    }
    if (locationInfoInputs.locationEmail === "") {
      formIsValid = false;
      localErrorsLocationInfo.locationEmail = { error: true, message };
    } else if (!emailPattern.test(locationInfoInputs.locationEmail)) {
      formIsValid = false;
      localErrorsLocationInfo.locationEmail = { error: true, message: "Invalid Email!" };
    }
    if (locationInfoInputs.locationType === "") {
      formIsValid = false;
      localErrorsLocationInfo.locationType = { error: true, message };
    }
    if (locationInfoInputs.locationName === "") {
      formIsValid = false;
      localErrorsLocationInfo.locationName = { error: true, message };
    }
    if (locationInfoInputs.locationName.length < 3) {
      formIsValid = false;
      localErrorsLocationInfo.locationName = { error: true, message: "Name should have at least three letters!" };
    }
    if (locationInfoInputs.locationPhone === "") {
      formIsValid = false;
      localErrorsLocationInfo.locationPhone = { error: true, message };
    }

    if (locationInfoInputs.locationState === "") {
      formIsValid = false;
      localErrorsLocationInfo.locationState = { error: true, message };
    }
    if (locationInfoInputs.locationZip === "") {
      formIsValid = false;
      localErrorsLocationInfo.locationZip = { error: true, message };
    }
    setErrorsLocationInfo({ ...errorsLocationInfo, ...localErrorsLocationInfo });
    return formIsValid;
  };

  // Co-admin User State and Handlers
  const [coAdminUser] = useState<CoAdminUserModel>({ firstName: "", lastName: "", password: "", confirm_password: "", email: "", phone: "", role: "", active: true, branch_access: true });
  const [coAdminUserInputs, setCoAdminUserInputs] = useState<CoAdminUserModel>(coAdminUser);
  const handleCoAdminUserChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setCoAdminUserInputs({ ...coAdminUserInputs, [e.target.name]: e.target.type === "checkbox" ? target.checked : e.target.value });
  };
  const [errorsCoAdminUser, setErrorsCoAdminUser] = useState({
    firstName: { error: false, message: "" },
    lastName: { error: false, message: "" },
    password: { error: false, message: "" },
    confirm_password: { error: false, message: "" },
    phone: { error: false, message: "" },
    role: { error: false, message: "" },
    email: { error: false, message: "" },
  });
  const handleResetCoAdminUser = () => {
    setCoAdminUserInputs(coAdminUser);
    setErrorsCoAdminUser({
      firstName: { error: false, message: "" },
      lastName: { error: false, message: "" },
      password: { error: false, message: "" },
      confirm_password: { error: false, message: "" },
      phone: { error: false, message: "" },
      role: { error: false, message: "" },
      email: { error: false, message: "" },
    });
  };
  const validateErrorCoAdminUser = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsCoAdminUser({ ...errorsCoAdminUser, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsCoAdminUser({ ...errorsCoAdminUser, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormCoAdminUser = () => {
    let formIsValid = true;
    let localErrorsCoAdminUser = errorsCoAdminUser;
    const message = "Required Field!";
    if (coAdminUserInputs.firstName === "") {
      formIsValid = false;
      localErrorsCoAdminUser.firstName = { error: true, message };
    }
    if (coAdminUserInputs.lastName === "") {
      formIsValid = false;
      localErrorsCoAdminUser.lastName = { error: true, message };
    }

    if (coAdminUserInputs.email === "") {
      formIsValid = false;
      localErrorsCoAdminUser.email = { error: true, message };
    } else if (!emailPattern.test(coAdminUserInputs.email)) {
      formIsValid = false;
      localErrorsCoAdminUser.email = { error: true, message: "Invalid Email!" };
    }
    if (selectedMenu === "ADD") {
      if (coAdminUserInputs.password === "") {
        formIsValid = false;
        localErrorsCoAdminUser.password = { error: true, message };
      }
      if (coAdminUserInputs.confirm_password === "") {
        formIsValid = false;
        localErrorsCoAdminUser.confirm_password = { error: true, message };
      }
      if (coAdminUserInputs.password !== coAdminUserInputs.confirm_password) {
        formIsValid = false;
        localErrorsCoAdminUser.password = { error: true, message: "Password should be same as Confirm Password!" };
        localErrorsCoAdminUser.confirm_password = { error: true, message: "Confirm Password should be same as Password!" };
      }
    }
    if (coAdminUserInputs.phone === "") {
      formIsValid = false;
      localErrorsCoAdminUser.phone = { error: true, message };
    }
    if (coAdminUserInputs.role === "") {
      formIsValid = false;
      localErrorsCoAdminUser.role = { error: true, message };
    }
    setErrorsCoAdminUser({ ...errorsCoAdminUser, ...localErrorsCoAdminUser });
    return formIsValid;
  };

  // Secondary User staState and Handlers
  const [allSecondaryUsers, setAllSecondaryUsers] = useState<LocationSecondaryUserModel[]>([]); // it will holds the all secondary users that are fetched when calling the editbranch
  const [secondaryUsers, setSecondaryUsers] = useState<LocationSecondaryUserModel[]>([]);
  const [secondaryUser] = useState<LocationSecondaryUserModel>({
    recordType: "ADD",
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    phone: "",
    password: "",
    confirm_password: "",
    permissions: [],
    active: true,
    branch_access: true,
  });
  const [secondaryUserInputs, setSecondaryUserInputs] = useState<LocationSecondaryUserModel>(secondaryUser);
  const [errorsSecondaryUser, setErrorsSecondaryUser] = useState({
    firstName: { error: false, message: "" },
    lastName: { error: false, message: "" },
    password: { error: false, message: "" },
    confirm_password: { error: false, message: "" },
    email: { error: false, message: "" },
    role: { error: false, message: "" },
    phone: { error: false, message: "" },
  });
  const handleSecondaryUserChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setSecondaryUserInputs({ ...secondaryUserInputs, [e.target.name]: e.target.type === "checkbox" ? target.checked : e.target.value });
  };

  const handleResetSecondaryUser = () => {
    setSecondaryUserInputs(secondaryUser);
    setErrorsSecondaryUser({
      firstName: { error: false, message: "" },
      lastName: { error: false, message: "" },
      password: { error: false, message: "" },
      confirm_password: { error: false, message: "" },
      email: { error: false, message: "" },
      role: { error: false, message: "" },
      phone: { error: false, message: "" },
    });
  };

  const validateErrorSecondaryUser = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsSecondaryUser({ ...errorsSecondaryUser, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsSecondaryUser({ ...errorsSecondaryUser, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateFormSecondaryUser = () => {
    let formIsValid = true;
    let localErrorsSecondaryUser = errorsSecondaryUser;
    const message = "Required Field!";
    if (secondaryUserInputs.firstName === "") {
      formIsValid = false;
      localErrorsSecondaryUser.firstName = { error: true, message };
    }
    if (secondaryUserInputs.firstName.length < 3) {
      formIsValid = false;
      localErrorsSecondaryUser.firstName = { error: true, message: "Name should have at least three letters!" };
    }
    if (secondaryUserInputs.lastName === "") {
      formIsValid = false;
      localErrorsSecondaryUser.lastName = { error: true, message };
    }
    if (secondaryUserInputs.email === "") {
      formIsValid = false;
      localErrorsSecondaryUser.email = { error: true, message };
    } else if (!emailPattern.test(secondaryUserInputs.email)) {
      formIsValid = false;
      localErrorsSecondaryUser.email = { error: true, message: "Invalid Email!" };
    }
    if (secondaryUserInputs.role === "") {
      formIsValid = false;
      localErrorsSecondaryUser.role = { error: true, message };
    }
    if (secondaryUserInputs.recordType === "ADD") {
      if (secondaryUserInputs.password === "") {
        formIsValid = false;
        localErrorsSecondaryUser.password = { error: true, message };
      }
      if (secondaryUserInputs.confirm_password === "") {
        formIsValid = false;
        localErrorsSecondaryUser.confirm_password = { error: true, message };
      }
      if (secondaryUserInputs.password !== secondaryUserInputs.confirm_password) {
        formIsValid = false;
        localErrorsSecondaryUser.password = { error: true, message: "Password should be same as Confirm Password!" };
        localErrorsSecondaryUser.confirm_password = { error: true, message: "Confirm Password should be same as Password!" };
      }
    }

    setErrorsSecondaryUser({ ...errorsSecondaryUser, ...localErrorsSecondaryUser });
    return formIsValid;
  };
  const handleResetCreateLocationStates = () => {
    handleResetLocationInfo();
    handleResetCoAdminUser();
    handleResetSecondaryUser();
    setActiveStep(0);
  };
  const onSubmitCreateLocation = async () => {
    try {
      //To prevent to move further in case of empty fields
      if (!validateFormCoAdminUser()) return;
      const createLocationData: CreateLocationModel = {
        locationInfo: locationInfoInputs,
        coAdminUser: coAdminUserInputs,
        secondaryUsers: secondaryUsers,
        biz_id: userData.biz_id,
        user_id: userData.user_id,
      };
      const updateLocationData: UpdateLocationModel = {
        locationInfo: locationInfoInputs,
        coAdminUser: coAdminUserInputs,
        secondaryUsers: secondaryUsers,
        user_id: userData.user_id,
      };
      let res = selectedMenu === "ADD" ? await onCreateLocation(createLocationData) : await onUpdateLocation(updateLocationData);
      let updatedUserData = userData;
      updatedUserData.branches = res.branches;
      let updatedBranch = updatedUserData.branches.filter((item: any) => Number(item.biz_branch_id) === Number(locationInfoInputs.id));
      if (updatedBranch.length > 0) {
        if (!updatedBranch[0].branch_active) {
          // If the selected branch/location is same as just deactivated branch then select the main branch
          if (Number(updatedBranch[0].biz_branch_id) === Number(userData.selected_branch.biz_branch_id)) {
            updatedUserData.selected_branch = updatedUserData?.branches.find((item: any) => item.branch_type === "Main") ?? {};
            setAlertMessage({ type: res.status, description: res.message, open: true });
            dispatch({ type: SET_USER_DATA, payload: updatedUserData });
            navigate("/schedule-appointment");
            return;
          }
          setAlertMessage({
            type: "error",
            description: `You can not select the ${updatedBranch[0].biz_branch_name} location because it is currently deactivated that is why you are redirected to the Main location`,
            open: true,
          });
        }
      }
      dispatch({ type: SET_USER_DATA, payload: updatedUserData });
      setAlertMessage({ type: res.status, description: res.message, open: true });
      handleResetCreateLocationStates();
      await fetchData();
      setSelectedMenu("VIEW");
    } catch (err) {
      setAlertMessage({ type: "error", description: "Something went wrong, please contact with the administrator", open: true });
    }
  };

  // END Functions of SubLocation
  const onSubmitLocationInfo = async () => {
    if (!validateFormLocationInfo()) return;
    if (selectedMenu === "EDIT") {
      setSecondaryUsers(allSecondaryUsers);
      setSecondaryUserInputs(secondaryUser);
    }
    setActiveStep(1);
  };
  const handleChange = (event: any) => {
    if (event.target.name === "searchSubBranchName") {
      setSearchSubBranchName(event.target.value as string);
    }
    if (event.target.name === "searchStatus") {
      setSearchStatus(event.target.value as string);
    }
    if (event.target.name === "searchLocType") {
      setSearchLocType(event.target.value as string);
    }
  };
  // Update Main Business Information
  const onClickUpdateBiz = async (updateData: any) => {
    try {
      updateData.biz_id = userData.biz_id;
      const resUpdate = await updateBiz(updateData);
      setAlertMessage({ type: resUpdate.status, description: resUpdate.message, open: true });
      const res = await getBizBranches({ biz_id: userData.biz_id });
      setMainBranch(res.data.main_branch);
      setSubBranches(res.data.sub_branches);
      setLocTypeOptions(res.data.locationTypes);
      setAllLocTypeOptions(res.data.allLoctionTypes);
    } catch (err) {
      console.log(err);
    }
  };
  const country = "US";

  const onClickAddEditLocation = async (menu: MENU, data?: any) => {
    try {
      if (menu === "ADD") setSelectedMenu(menu);
      if (menu === "EDIT") {
        await fetchData();
        const { status, locationInfo, coAdminUser, secondaryUsers } = await onEditLocation({ biz_branch_id: data.id });
        if (status === "success") {
          // Get State and City of Location
          const locationIsoCode = getStateIsoCode(country, locationInfo.state); //Custom function
          const locationState = State.getStateByCodeAndCountry(locationIsoCode, country);
          const locationCity = getCityByName(country, locationIsoCode, locationInfo.city); //Custom function
          const states = State.getStatesOfCountry(country);
          setStates(states);

          const cities = City.getCitiesOfState(country, locationIsoCode);
          setCities(cities);
          setLocationInfoInputs({
            id: locationInfo.id,
            locationName: locationInfo.name,
            locationPhone: locationInfo.phone_no,
            locationAddress: locationInfo.address,
            locationEmail: locationInfo.email,
            locationCity: locationCity,
            locationType: locationInfo.loc_type,
            locationState: locationState,
            locationZip: locationInfo.zip,
            active: locationInfo.active,
          });
          setCoAdminUserInputs({
            id: coAdminUser.id,
            firstName: coAdminUser.first_name,
            lastName: coAdminUser.last_name,
            password: "",
            confirm_password: "",
            email: coAdminUser.email,
            phone: coAdminUser.phone_no,
            role: coAdminUser.role_type,
            active: coAdminUser.active,
            branch_access: coAdminUser.branch_access,
          });

          const localSecondaryUsers: LocationSecondaryUserModel[] = [];
          for (let i = 0; i < secondaryUsers.length; i++) {
            const element = secondaryUsers[i];
            localSecondaryUsers.push({
              recordType: "EDIT",
              id: element.id,
              firstName: element.first_name,
              lastName: element.last_name,
              email: element.email,
              role: element.role_type,
              phone: element.phone_no,
              password: "",
              confirm_password: "",
              permissions: element.permissions,
              active: element.active,
              branch_access: element.branch_access,
            });
          }
          setSelectedMenu(menu);
          setSecondaryUsers(localSecondaryUsers);
          setAllSecondaryUsers(localSecondaryUsers);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickBack = () => {
    setSelectedMenu("VIEW");
  };
  async function fetchData() {
    try {
      const res = await getBizBranches({ biz_id: userData.biz_id });
      setMainBranch(res.data.main_branch);
      setSubBranches(res.data.sub_branches);
      setLocTypeOptions(res.data.locationTypes);
      setAllLocTypeOptions(res.data.allLoctionTypes);

      const res1 = await getProviderRolesAndLocTypes();
      setProviderRoles(res1.providerRoles);
      setLocationTypes(res1.locationTypes);
      const states = State.getStatesOfCountry(country);
      setStates(states);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    (async () => {
      try {
        const res = await getBizBranches({ biz_id: userData.biz_id });
        setMainBranch(res.data.main_branch);
        setSubBranches(res.data.sub_branches);
        setLocTypeOptions(res.data.locationTypes);
        setAllLocTypeOptions(res.data.allLoctionTypes);

        const res1 = await getProviderRolesAndLocTypes();
        setProviderRoles(res1.providerRoles);
        setLocationTypes(res1.locationTypes);
        const states = State.getStatesOfCountry(country);
        setStates(states);
      } catch (err) {
        console.log(err);
      }
      setActiveStep(0);
    })();
  }, [userData, setActiveStep, setMainBranch, setSubBranches, setLocTypeOptions, setAllLocTypeOptions, setProviderRoles, setLocationTypes, setStates]);

  // useEffect(() => {
  //   fetchData()
  //   setActiveStep(0);
  // }, []);

  const renderMenu = () => {
    switch (selectedMenu) {
      case "VIEW":
        return (
          <ManageBizSection
            mainBranch={mainBranch}
            subBranches={subBranches}
            editMainBranch={editMainBranch}
            setEditMainBranch={setEditMainBranch}
            searchSubBranchName={searchSubBranchName}
            searchStatus={searchStatus}
            statusOptions={statusOptions}
            searchLocType={searchLocType}
            locTypeOptions={locTypeOptions}
            allLocTypeOptions={allLocTypeOptions}
            handleChange={handleChange}
            onClickUpdateBiz={onClickUpdateBiz}
            onClickAddEditLocation={onClickAddEditLocation}
          />
        );
      case "ADD":
      case "EDIT":
        return (
          <Box flex={1} mt={2}>
            <div className="outer__right-header">
              <div className="flex items-center gap-[5px] cursor-pointer">
                <Back />
                <div onClick={onClickBack} className="text-[16px] font-[600] text-[#888A8C]">
                  Back
                </div>
              </div>
              <h1 className="outer__right-header-heading">Create Location</h1>
              <p className="outer__right-header-text">Enter your Location and its Users Details</p>
            </div>
            <div>
              <CustomStepper steps={steps} activeStep={activeStep} />
              {activeStep === 0 ? (
                <LocationInfo
                  locationTypes={locationTypes}
                  states={states}
                  cities={cities}
                  handleAutoComplete={handleAutoComplete}
                  inputs={locationInfoInputs}
                  errorsLocationInfo={errorsLocationInfo}
                  validateErrorLocationInfo={validateErrorLocationInfo}
                  onChange={handleLocationFieldChange}
                />
              ) : null}
              {activeStep === 1 ? (
                <LocationUserProfile
                  selectedMenu={selectedMenu}
                  providerRoles={providerRoles}
                  // Co Admin User Props
                  coAdminUserInputs={coAdminUserInputs}
                  errorsCoAdminUser={errorsCoAdminUser}
                  validateErrorCoAdminUser={validateErrorCoAdminUser}
                  handleCoAdminUserChange={handleCoAdminUserChange}
                  // Secondary User Props
                  setSecondaryUserInputs={setSecondaryUserInputs}
                  secondaryUserInputs={secondaryUserInputs}
                  errorsSecondaryUser={errorsSecondaryUser}
                  validateErrorSecondaryUser={validateErrorSecondaryUser}
                  validateFormSecondaryUser={validateFormSecondaryUser}
                  handleSecondaryUserChange={handleSecondaryUserChange}
                  secondaryUsers={secondaryUsers}
                  setSecondaryUsers={setSecondaryUsers}
                  handleResetSecondaryUser={handleResetSecondaryUser}
                />
              ) : null}
              <div className="mt-[40px] flex justify-end mb-[20px]">
                {activeStep === 0 ? (
                  <VAButton text="Next" dark onClick={onSubmitLocationInfo} />
                ) : (
                  <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                    <VAButton text="Back" light onClick={() => setActiveStep(0)} />
                    <VAButton text={selectedMenu === "ADD" ? "Create location and send invites" : "Update location and send invites"} dark onClick={onSubmitCreateLocation} />
                  </div>
                )}
              </div>
            </div>
          </Box>
        );

      default:
        break;
    }
  };

  return (
    <DashboardLayout>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      {renderMenu()}
    </DashboardLayout>
  );
};
