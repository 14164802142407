import React from "react";
import { Table, TableHead, TableBody } from "@mui/material";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";
import { ScheduleAppointmentModel } from "../../../../types/models/Provider/ScheduleAppointment";

interface PendingVaccineAppointmentTableProps {
  pendingVaccineAppointments: ScheduleAppointmentModel[];
  setPendingVaccineAppointments: React.Dispatch<React.SetStateAction<ScheduleAppointmentModel[]>>;
}

const PendingVaccineAppointmentTable: React.FC<PendingVaccineAppointmentTableProps> = ({ pendingVaccineAppointments, setPendingVaccineAppointments }) => {
  return (
    <Table stickyHeader sx={{ minWidth: 650 }} size="small" className="table">
      <TableHead>
        <TableHeading />
      </TableHead>
      <TableBody>
        {pendingVaccineAppointments.map((data: any, index: number) =>
          !data.verify ? (
            <TableItem data={data} key={data.schedule_appointment_id} setPendingVaccineAppointments={setPendingVaccineAppointments} pendingVaccineAppointments={pendingVaccineAppointments} />
          ) : null
        )}
      </TableBody>
    </Table>
  );
};

export default PendingVaccineAppointmentTable;
