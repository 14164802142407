export enum Fonts {
  LIGHT = "300",
  REGULAR = "400",
  MEDIUM = "500",
  BOLD600 = "600",
  SEMI_BOLD = "700",
  BOLD = "800",
}

export enum AuthType {
  FIREBASE = "firebase",
  AWS_COGNITO = "aws_cognito",
  AUTH0 = "auth0",
  JWT_AUTH = "jwt_auth",
}
