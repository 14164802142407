import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../components";
import { addVaccinesData, getCategoryAndMfgs } from "../../../redux/actionCreators/SuperAdmin/VaccineDataImporter";
import { AlertType } from "../../../shared/constants/AppConst";
import VAAlert from "../../../components/Alert/Alert";
import { VaccineDataImporterSection } from "../../../sections/Superadmin";

interface VaccineData {
  // Define the structure of the vaccine data here
  name: "P-Pfizer";
  no_of_doses: number;
  time_span: string;
  time_span_no: number;
  age_greater: number;
  age_less: number;
  note: string;
}

const useVaccineDataImporter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [vaccineData, setVaccineData] = useState<VaccineData[]>([]);
  const [importVaccinefile, setImportVaccinefile] = useState<File | string | null>(null);
  const importVaccineData = async (file: File, filePath: string) => {
    setIsLoading(true);
    setError(null);

    try {
      // Read the file content as text
      const fileContent = await file.text();
      // Parse the JSON data from the file
      const jsonData: VaccineData[] = JSON.parse(fileContent).vaccines;

      // Perform any necessary validation on the jsonData
      // For example, check for required fields, data types, etc.

      // TODO: Implement the logic to add the data to the database
      // You can use an API call or any other method to update the database with jsonData.
      setImportVaccinefile(filePath);
      setVaccineData(jsonData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError("Error importing vaccine data. Please ensure the JSON file is valid.");
    }
  };

  return { isLoading, error, importVaccinefile, importVaccineData, vaccineData };
};

// Usage example in a React component
export const VaccineDataImporter: React.FC = () => {
  const { isLoading, error, importVaccinefile, importVaccineData, vaccineData } = useVaccineDataImporter();
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [vaccineCategories, setVaccineCategories] = useState<{ value: false; label: "" }[]>([]);
  const [selectedVaccineCategory, setSelectedVaccineCategory] = useState("");
  const [vaccineMfgs, setVaccineMfgs] = useState<{ value: false; label: "" }[]>([]);
  const [selectedVaccineMfg, setSelectedVaccineMfg] = useState("");
  const [errors, setErrors] = useState({
    importVaccinefile: { error: false, message: "" },
    SelectedVaccineCategory: { error: false, message: "" },
    SelectedVaccineMfg: { error: false, message: "" },
  });
  const validateError = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrors({ ...errors, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrors({ ...errors, [e.target.name]: { error: false, message: "" } });
    }
  };
  const onChangeTextFields = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.name === "selectedVaccineCategory") {
      setSelectedVaccineCategory(e.target.value);
    } else if (e.target.name === "selectedVaccineMfg") {
      setSelectedVaccineMfg(e.target.value);
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      importVaccineData(file, event.target.value);
    }
  };
  const handleSubmit = async () => {
    try {
      let res = await addVaccinesData({ category_id: selectedVaccineCategory, mfg_id: selectedVaccineMfg, vaccineData });
      setAlertMessage({ type: "success", description: res.message, open: true });
    } catch (err) {
      setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
      console.log(err);
    }
  };
  useEffect(() => {
    const getScheduleAppointmentsData = async () => {
      try {
        let res = await getCategoryAndMfgs();
        setVaccineCategories(res.categories);
        setVaccineMfgs(res.manufacturers);
      } catch (err) {
        console.log(err);
      }
    };
    getScheduleAppointmentsData();
  }, []);
  return (
    <DashboardLayout>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      {isLoading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <VaccineDataImporterSection
        vaccineCategories={vaccineCategories}
        selectedVaccineCategory={selectedVaccineCategory}
        vaccineMfgs={vaccineMfgs}
        selectedVaccineMfg={selectedVaccineMfg}
        onChangeTextFields={onChangeTextFields}
        importVaccinefile={importVaccinefile}
        handleFileChange={handleFileChange}
        validateError={validateError}
        handleSubmit={handleSubmit}
      />
    </DashboardLayout>
  );
};
