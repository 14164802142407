import jwtAxios from "../../../services/auth/jwt-auth";

export const getSecondaryUsers = async (email: any) => {
  try {
    const res = await jwtAxios.post("/manageusers/getsecondaryusers", { email });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProviderRolesAndLocTypes = async () => {
  try {
    const res = await jwtAxios.post("/manageusers/getproviderrolesandloctypes");
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
