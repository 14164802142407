import { CreateProfileModel } from "../../models/Provider/createProfile/CreateProfile";

export const CREATE_PROFILE = "CREATE_PROFILE";

export interface CreateProfileAction {
  type: typeof CREATE_PROFILE;
  payload: CreateProfileModel;
}

export type CreateProfileActionTypes = CreateProfileAction;
