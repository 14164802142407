import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { Box, Checkbox, Modal, FormControlLabel, FormControl, FormGroup, FormHelperText } from "@mui/material";
import { VAButton, FormInput, DatePicker, FromSelect } from "..";
import { getOfferedMfgs, getOfferedVaccinesByMfg } from "../../redux/actionCreators";
import VAAlert from "../Alert/Alert";
import { AlertType } from "../../shared/constants/AppConst";
import { getFormattedDate } from "../../shared/Util";

dayjs.extend(utc);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};

const specimenSources = [
  { label: "Blood", value: "Blood" },
  { label: "Buccal", value: "Buccal" },
  { label: "Gentalia Externa", value: "Gentalia Externa" },
  { label: "Nasopharyngeal", value: "Nasopharyngeal" },
  { label: "Nasopharynx", value: "Nasopharynx" },
  { label: "Oropharynx", value: "Oropharynx" },
  { label: "Oral Cavity", value: "Oral Cavity" },
  { label: "Rectum", value: "Rectum" },
  { label: "Saliva", value: "Saliva" },
  { label: "Skin of Forearm", value: "Skin of Forearm" },
  { label: "Skin Structure", value: "Skin Structure" },
  { label: "Urine", value: "Urine" },
];

const testTypeOptions = [
  { label: "Categorical", value: "Categorical" },
  { label: "Numerical", value: "Numerical" },
];

const CategoricalResultOptions = [
  { label: "Positive", value: "Positive" },
  { label: "Negative", value: "Negative" },
  { label: "Indeterminate", value: "Indeterminate" },
  { label: "Nonreactive", value: "Nonreactive" },
];

const NumericalResults = [
  { label: "mg/dL", value: "mg/dL" },
  { label: "ng/dL", value: "ng/dL" },
  { label: "g/dL", value: "g/dL" },
  { label: "TH/µL", value: "TH/µL" },
  { label: "mil/µL", value: "mil/µL" },
  { label: "pg/mL", value: "pg/mL" },
  { label: "mEq/L", value: "mEq/L" },
  { label: "mmol/L", value: "mmol/L" },
  { label: "U/L", value: "U/L" },
  { label: "mL/min/1.73*2", value: "mL/min/1.73*2" },
  { label: "WBC", value: "WBC" },
  { label: "fL", value: "fL" },
  { label: "pg", value: "pg" },
  { label: "%", value: "%" },
  { label: "mm", value: "mm" },
  { label: "cm", value: "cm" },
  { label: "in", value: "in" },
  { label: "n/a", value: "n/a" },
];

interface props {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  patient_name?: string;
  prov_vaccine_id: number;
  biz_branch_id: number;
  vaccine_type: string;
  scheduled_mfg_id: string;
  onClickVerify?: (data: any) => void;
  editData?: any;
  setEditData?: any;
}

export const VerifyTestSA: React.FC<props> = ({ patient_name, open, setOpen, prov_vaccine_id, vaccine_type, biz_branch_id, scheduled_mfg_id, onClickVerify, editData, setEditData }) => {
  const [mfgs, setMfgs] = useState([]);
  const [mfg, setMfg] = useState(scheduled_mfg_id);
  const [vaccines, setVaccines] = useState([]);
  const [provVaccineId, setProvVaccineId] = useState(prov_vaccine_id.toString());
  const [specimenSource, setSpecimenSource] = useState("");
  const [testType, setTestType] = useState("");
  const [categoricalResult, setCategoricalResult] = useState("");
  const [numericalResult, setNumericalResult] = useState(NumericalResults[0].value);
  const [numericalValue, setNumericalValue] = useState("");
  const [testDate, setTestDate] = React.useState<dayjs.Dayjs | null>(null);
  const [testDateResulted, setTestDateResulted] = React.useState<dayjs.Dayjs | null>(null);
  const [note, setNote] = useState("");
  const [confirmCheck, setConfirmCheck] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [errors, setErrors] = useState({
    mfg: { error: false, message: "" },
    provVaccineId: { error: false, message: "" },
    specimenSource: { error: false, message: "" },
    testType: { error: false, message: "" },
    categoricalResult: { error: false, message: "" },
    numericalResult: { error: false, message: "" },
    numericalValue: { error: false, message: "" },
    testDate: { error: false, message: "" },
    testDateResulted: { error: false, message: "" },
    confirmCheck: { error: false, message: "" },
  });
  useEffect(() => {
    if (editData) {
      setSpecimenSource(editData.specimenSource || "");
      setTestType(editData.testType || "");
      setTestDate(editData.testDate ? dayjs(editData.testDate) : null);
      setTestDateResulted(editData.testDateResulted ? dayjs(editData.testDateResulted) : null);
      setNote(editData.note || "");

      if (editData.testType === "Categorical") {
        setCategoricalResult(editData.categoricalResult || "");
        setNumericalResult("");
        setNumericalValue("");
      } else if (editData.testType === "Numerical") {
        setCategoricalResult("");
        setNumericalResult(editData.numericalResult || NumericalResults[0].value);
        setNumericalValue(editData.numericalValue || "");
      }
    }
  }, [editData]);
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown; checked?: boolean }>) => {
    const { name, value, checked } = event.target;
    if (name === "confirmCheck") {
      setConfirmCheck(checked || false); // Ensure confirmCheck is always set to true if checked, or false if unchecked
    } else {
      setFieldValue(name as string, value as string);
    }
  };

  const setFieldValue = async (fieldName: string, value: string) => {
    switch (fieldName) {
      case "mfg":
        setMfg(value);
        await getAllOfferedVaccinesByMfgs(value);
        break;
      case "provVaccineId":
        setProvVaccineId(value);
        break;
      case "specimenSource":
        setSpecimenSource(value);
        break;
      case "testType":
        setTestType(value);
        setCategoricalResult(""); // Reset categorical result when test type changes
        if (value === "Numerical") setNumericalResult(NumericalResults[0].value); // Reset numerical result when test type changes
        else setNumericalResult("");
        break;
      case "categoricalResult":
        setCategoricalResult(value);
        break;
      case "numericalResult":
        setNumericalResult(value);
        break;
      case "numericalValue":
        setNumericalValue(value);
        break;
      case "note":
        setNote(value);
        break;
      default:
        break;
    }
  };

  const validateErrors = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrors({ ...errors, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrors({ ...errors, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    const message = "Required Field!";
    const fieldsToValidate = ["mfg", "provVaccineId", "specimenSource", "testType", "testDate", "testDateResulted", "confirmCheck"];

    // If test type is Categorical, ignore validating numericalResult and numericalValue
    if (testType === "Categorical") {
      fieldsToValidate.push("categoricalResult");
    } else {
      // If test type is Numerical, ignore validating categoricalResult
      fieldsToValidate.push("numericalResult", "numericalValue");
    }

    // Set all error states to false initially
    const initialErrorState: any = {
      mfg: { error: false, message: "" },
      provVaccineId: { error: false, message: "" },
      specimenSource: { error: false, message: "" },
      testType: { error: false, message: "" },
      categoricalResult: { error: false, message: "" },
      numericalResult: { error: false, message: "" },
      numericalValue: { error: false, message: "" },
      testDate: { error: false, message: "" },
      testDateResulted: { error: false, message: "" },
      confirmCheck: { error: false, message: "" },
    };

    // Validate each field
    fieldsToValidate.forEach((field) => {
      if (!getFieldValue(field)) {
        formIsValid = false;
        // Set the specific error state for the field
        initialErrorState[field] = { error: true, message };
      }
    });

    // Update the errors state with the new error values
    setErrors(initialErrorState);

    return formIsValid;
  };

  const getFieldValue = (fieldName: string) => {
    switch (fieldName) {
      case "mfg":
        return mfg;
      case "provVaccineId":
        return provVaccineId;
      case "specimenSource":
        return specimenSource;
      case "testType":
        return testType;
      case "categoricalResult":
        return categoricalResult;
      case "numericalResult":
        return numericalResult;
      case "numericalValue":
        return numericalValue;
      case "testDate":
        return testDate;
      case "testDateResulted":
        return testDateResulted;
      case "note":
        return note;
      case "confirmCheck":
        return confirmCheck;
      default:
        return null;
    }
  };

  const onClickVerifyConfirm = async () => {
    if (validateForm()) {
      if (onClickVerify)
        onClickVerify({
          prov_vaccine_id: provVaccineId,
          specimen_source: specimenSource,
          test_type: testType,
          categorical_result: categoricalResult,
          numerical_result: numericalResult,
          numerical_value: numericalValue,
          test_date: getFormattedDate(testDate),
          test_date_resulted: getFormattedDate(testDateResulted),
          note,
        });
    }
  };

  const getAllOfferedVaccinesByMfgs = async (selected_mfg_id: string) => {
    try {
      let res = await getOfferedVaccinesByMfg({ mfg_id: selected_mfg_id, biz_branch_id });
      if (res.message === "SUCCESS") setVaccines(res.data);
    } catch (err) {
      console.error("Error when getting the Vaccine Mfgs: ", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getOfferedMfgs({ vaccine_type, biz_branch_id });
        if (res.message === "SUCCESS") setMfgs(res.data);
        let res2 = await getOfferedVaccinesByMfg({ mfg_id: mfg, biz_branch_id });
        if (res2.message === "SUCCESS") setVaccines(res2.data);
      } catch (err) {
        console.error("Error when getting the Vaccine Mfgs or Offered Vaccines by MFG: ", err);
      }
    };

    fetchData();
  }, [vaccine_type, biz_branch_id, mfg]);

  const handleClose = () => {
    setOpen(false);
    if (editData) setEditData(null);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{ ...style, maxHeight: "100vh", overflowY: "auto" }}>
        <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
          <div className="max-w-[360px] flex flex-col gap-[12px]">
            <h2 className="text-[20px] font-[600] text-[#000000]">Verify Test for {patient_name}</h2>
          </div>
          <div className="mt-[12px]">
            <div className="flex flex-col gap-[12px]">
              <FromSelect
                name="mfg"
                label="Manufacturer"
                value={mfg}
                options={mfgs}
                onChange={handleChange}
                onBlur={validateErrors}
                validate={{ error: errors.mfg.error, message: errors.mfg.message }}
              />
              <FromSelect
                name="provVaccineId"
                label="Test"
                value={provVaccineId}
                options={vaccines}
                onChange={handleChange}
                onBlur={validateErrors}
                validate={{ error: errors.provVaccineId.error, message: errors.provVaccineId.message }}
              />
              <FromSelect
                name="specimenSource"
                label="Specimen Source"
                value={specimenSource}
                options={specimenSources}
                onChange={handleChange}
                onBlur={validateErrors}
                validate={{ error: errors.specimenSource.error, message: errors.specimenSource.message }}
              />
              <FromSelect
                name="testType"
                label="Test Type"
                value={testType}
                options={testTypeOptions}
                onChange={handleChange}
                onBlur={validateErrors}
                validate={{ error: errors.testType.error, message: errors.testType.message }}
              />
              {testType === "Categorical" && (
                <FromSelect
                  name="categoricalResult"
                  label="Categorical Result"
                  value={categoricalResult}
                  options={CategoricalResultOptions}
                  onChange={handleChange}
                  onBlur={validateErrors}
                  validate={{ error: errors.categoricalResult.error, message: errors.categoricalResult.message }}
                />
              )}
              {testType === "Numerical" && (
                <div className="flex flex-col gap-[12px]">
                  <div className="flex gap-[12px]">
                    <div style={{ flexBasis: "50%", flexGrow: 1 }}>
                      <FromSelect
                        name="numericalResult"
                        label="Numerical Result"
                        value={numericalResult}
                        options={NumericalResults}
                        onChange={handleChange}
                        onBlur={validateErrors}
                        validate={{ error: errors.numericalResult.error, message: errors.numericalResult.message }}
                      />
                    </div>
                    <div style={{ flexBasis: "50%", flexGrow: 1 }}>
                      <FormInput
                        name="numericalValue"
                        label={`Numerical Value`}
                        type="number"
                        variant="standard"
                        value={numericalValue}
                        onChange={handleChange}
                        onBlur={validateErrors}
                        validate={{ error: errors.numericalValue.error, message: errors.numericalValue.message }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-[12px]">
                <div className="flex gap-[12px]">
                  <div style={{ flexBasis: "50%", flexGrow: 1 }}>
                    <DatePicker label="Test Date" value={testDate} setValue={setTestDate} onBlur={validateErrors} validate={{ error: errors.testDate.error, message: errors.testDate.message }} />
                  </div>
                  <div style={{ flexBasis: "50%", flexGrow: 1 }}>
                    <DatePicker
                      label="Test Date Resulted"
                      value={testDateResulted}
                      setValue={setTestDateResulted}
                      onBlur={validateErrors}
                      validate={{ error: errors.testDateResulted.error, message: errors.testDateResulted.message }}
                    />
                  </div>
                </div>
              </div>
              <FormInput name="note" label="Note" type="text" value={note} onChange={handleChange} />
              <FormControl required error={errors.confirmCheck.error} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={confirmCheck} onChange={handleChange} name="confirmCheck" />}
                    label={<span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[6px]">I confirm above data to be accurate.</span>}
                    style={{ border: "none", padding: 0 }}
                  />
                </FormGroup>
                <FormHelperText>{errors.confirmCheck.message}</FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="flex flex-col gap-[20px] mt-[10px] w-full">
            <VAButton text="Verify" dark onClick={onClickVerifyConfirm} />
            <VAButton text="Cancel" onClick={handleClose} outlined />
          </div>
        </div>
        {alertMessage !== null && <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />}
      </Box>
    </Modal>
  );
};
