import React, { useEffect, useState } from "react";
import { DashboardLayout, FormInput, VAButton } from "../../../components";
import { AlertType } from "../../../shared/constants/AppConst";
import VAAlert from "../../../components/Alert/Alert";
import { addVaccine, deleteVaccine, getVaccines, updateVaccine } from "../../../redux/actionCreators/SuperAdmin/Vaccine";
import { MENU } from "../../../types/actions/Common.action";
import VaccinesTable from "./VaccinesTable";
import { Vaccine } from "../../../sections/Superadmin";

// Usage example in a React component
export const Vaccines: React.FC = () => {
  const [categories, setCategories] = useState<any>([]);
  const [category_id, setCategoryId] = useState("");
  const [manufacturers, setManufacturers] = useState<any>([]);
  const [mfg_id, setManufacturerId] = useState("");
  const [name, setName] = useState("");
  const [no_of_doses, setNoOfDoses] = useState("");
  const [vaccine_cvx_code, setVaccineCVXCode] = useState("");
  const [time_spans, setTimeSpans] = useState<any>([]);
  const [time_span, setTimeSpan] = useState("");
  const [time_span_no, setTimeSpanNo] = useState("");
  const [age_greater, setAgeGreater] = useState("");
  const [age_less, setAgeLess] = useState("");
  const [note, setNote] = useState("");
  const [vaccines, setVaccines] = useState<any>([]);
  const [vaccine_id, setVaccineId] = useState("");
  const [searchVaccine, setSearchVaccine] = useState("");
  const [errorFields, setErrorFields] = useState({
    category_id: { error: false, message: "" },
    mfg_id: { error: false, message: "" },
    name: { error: false, message: "" },
    no_of_doses: { error: false, message: "" },
    vaccine_cvx_code: { error: false, message: "" },
    time_span: { error: false, message: "" },
    time_span_no: { error: false, message: "" },
    age_greater: { error: false, message: "" },
    age_less: { error: false, message: "" },
    note: { error: false, message: "" },
  });
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<MENU>("VIEW");
  const handleChange = (event: any) => {
    if (event.target.name === "category_id") {
      setCategoryId(event.target.value as string);
    }
    if (event.target.name === "mfg_id") {
      setManufacturerId(event.target.value as string);
    }
    if (event.target.name === "name") {
      setName(event.target.value as string);
    }
    if (event.target.name === "no_of_doses") {
      setNoOfDoses(event.target.value as string);
    }
    if (event.target.name === "vaccine_cvx_code") {
      setVaccineCVXCode(event.target.value as string);
    }
    if (event.target.name === "time_span") {
      setTimeSpan(event.target.value as string);
    }
    if (event.target.name === "time_span_no") {
      setTimeSpanNo(event.target.value as string);
    }
    if (event.target.name === "age_greater") {
      setAgeGreater(event.target.value as string);
    }
    if (event.target.name === "age_less") {
      setAgeLess(event.target.value as string);
    }
    if (event.target.name === "note") {
      setNote(event.target.value as string);
    }
    if (event.target.name === "searchVaccine") {
      setSearchVaccine(event.target.value as string);
    }
  };
  const onBlurField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    if (!value) {
      setErrorFields({ ...errorFields, [name]: { error: true, message: "Required field" } });
    } else {
      setErrorFields({ ...errorFields, [name]: { error: false, message: "" } });
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    const errorFields = {
      category_id: { error: false, message: "" },
      mfg_id: { error: false, message: "" },
      name: { error: false, message: "" },
      no_of_doses: { error: false, message: "" },
      vaccine_cvx_code: { error: false, message: "" },
      time_span: { error: false, message: "" },
      time_span_no: { error: false, message: "" },
      age_greater: { error: false, message: "" },
      age_less: { error: false, message: "" },
      note: { error: false, message: "" },
    };
    if (!category_id) {
      errorFields.category_id.error = true;
      errorFields.category_id.message = "Required field";
      formIsValid = false;
    }
    if (!mfg_id) {
      errorFields.mfg_id.error = true;
      errorFields.mfg_id.message = "Required field";
      formIsValid = false;
    }
    if (!name) {
      errorFields.name.error = true;
      errorFields.name.message = "Required field";
      formIsValid = false;
    }
    if (!no_of_doses) {
      errorFields.no_of_doses.error = true;
      errorFields.no_of_doses.message = "Required field";
      formIsValid = false;
    }
    if (!time_span) {
      errorFields.time_span.error = true;
      errorFields.time_span.message = "Required field";
      formIsValid = false;
    }
    if (!time_span_no) {
      errorFields.time_span_no.error = true;
      errorFields.time_span_no.message = "Required field";
      formIsValid = false;
    }
    if (!vaccine_cvx_code) {
      errorFields.vaccine_cvx_code.error = true;
      errorFields.vaccine_cvx_code.message = "Required field";
      formIsValid = false;
    }
    if (!age_greater) {
      errorFields.age_greater.error = true;
      errorFields.age_greater.message = "Required field";
      formIsValid = false;
    }
    if (!age_less) {
      errorFields.age_less.error = true;
      errorFields.age_less.message = "Required field";
      formIsValid = false;
    }
    if (!note) {
      errorFields.note.error = true;
      errorFields.note.message = "Required field";
      formIsValid = false;
    }
    setErrorFields(errorFields);
    return formIsValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        let res =
          selectedMenu === "ADD"
            ? await addVaccine({ category_id, mfg_id, name, no_of_doses, time_span, time_span_no, age_greater, age_less, vaccine_cvx_code, type: "VACCINE", note })
            : await updateVaccine({ vaccine_id: vaccine_id, category_id, mfg_id, name, no_of_doses, time_span, time_span_no, age_greater, age_less, type: "VACCINE", vaccine_cvx_code, note });
        setAlertMessage({ type: "success", description: res.message, open: true });
        setVaccines(res.vaccines);
        setCategories(res.categories);
        setManufacturers(res.mgfs);
        setTimeSpans(res.time_spans);
        setSelectedMenu("VIEW");
      } catch (err) {
        setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
        console.log(err);
      }
    }
  };
  const onClickAdd = () => {
    setSelectedMenu("ADD");
    setCategoryId("");
    setManufacturerId("");
    setName("");
    setNoOfDoses("");
    setTimeSpan("");
    setTimeSpanNo("");
    setAgeGreater("");
    setAgeLess("");
    setNote("");
  };
  const onClickEdit = (rowData: any) => {
    setSelectedMenu("EDIT");
    setVaccineId(rowData.id);
    setCategoryId(rowData.category_id);
    setManufacturerId(rowData.mfg_id);
    setName(rowData.name);
    setNoOfDoses(rowData.no_of_doses);
    setVaccineCVXCode(rowData.cvx_code);
    setTimeSpan(rowData.time_span);
    setTimeSpanNo(rowData.time_span_no);
    setAgeGreater(rowData.age_greater);
    setAgeLess(rowData.age_less);
    setNote(rowData.note);
  };
  const onClickDelete = async (vaccine_id: any) => {
    if (window.confirm("Are you sure, you want to delete this record?")) {
      try {
        let res = await deleteVaccine({ vaccine_id });
        setAlertMessage({ type: "success", description: res.message, open: true });
        setVaccines(res.vaccines);
        setCategories(res.categories);
        setManufacturers(res.mgfs);
        setTimeSpans(res.time_spans);
        setSelectedMenu("VIEW");
      } catch (err) {
        setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
        console.log(err);
      }
    }
  };
  const onClickBack = () => {
    setSelectedMenu("VIEW");
  };
  const getVaccinesData = async () => {
    try {
      let res = await getVaccines();
      setVaccines(res.vaccines);
      setCategories(res.categories);
      setManufacturers(res.mfgs);
      setTimeSpans(res.time_spans);
      setSelectedMenu("VIEW");
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getVaccinesData();
  }, []);
  const filteredVaccines = vaccines.filter((vaccine: any) => vaccine.name.toLowerCase().includes(searchVaccine.toLowerCase()));
  const renderMenu = () => {
    switch (selectedMenu) {
      case "VIEW":
        return (
          <div>
            <div>
              <h2 className="text-[24px] text-primary-main font-[600]">Vaccines</h2>
            </div>
            <div className="flex items-center justify-between py-[20px]">
              <FormInput label="Search by name 🔍" name="searchVaccine" value={searchVaccine} onChange={handleChange} />
              <VAButton dark text="Add New" onClick={onClickAdd} />
            </div>
            <div className="mt-[30px]">
              <VaccinesTable vaccines={filteredVaccines} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
            </div>
          </div>
        );
      case "ADD":
      case "EDIT":
        return (
          <div>
            <div>
              <h2 className="text-[20px] font-[600] text-[#0F5175]">{selectedMenu === "ADD" ? "Add New Vaccine" : "Update Vaccine"}</h2>
              <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">Enter Information</p>
            </div>
            <div className="mt-[30px]">
              <Vaccine
                selectedMenu={selectedMenu}
                categories={categories}
                category_id={category_id}
                manufacturers={manufacturers}
                mfg_id={mfg_id}
                name={name}
                no_of_doses={no_of_doses}
                vaccine_cvx_code={vaccine_cvx_code}
                time_spans={time_spans}
                time_span={time_span}
                time_span_no={time_span_no}
                age_greater={age_greater}
                age_less={age_less}
                note={note}
                handleChange={handleChange}
                onBlurField={onBlurField}
                errorFields={errorFields}
                handleSubmit={handleSubmit}
                onClickBack={onClickBack}
              />
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <DashboardLayout>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      {renderMenu()}
    </DashboardLayout>
  );
};
