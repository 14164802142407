import jwtAxios from "../../../services/auth/jwt-auth";

export const getSuperAdminVIS = async () => {
  try {
    const res = await jwtAxios.post("/superadmin/uploadvis/getsuperadminvis", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addSuperAdminVIS = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/uploadvis/addsuperadminvis", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editSuperAdminVIS = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/uploadvis/editsuperadminvis", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateSuperAdminVIS = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/uploadvis/updatesuperadminvis", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSuperAdminVIS = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/uploadvis/deletesuperadminvis", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
