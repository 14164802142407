import React, { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { Box, TextField, Autocomplete } from "@mui/material";
const CssAutocomplete = styled(Autocomplete)({
  "&": {
    width: "100%",
  },
  "& label.Mui-focused": {
    color: "#888A8C",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CDCFD2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#CDCFD2",
    },
    "&:hover fieldset": {
      borderColor: "#CDCFD2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CDCFD2",
    },
  },
});
interface AutoCompleteProps {
  value?: string | null;
  label?: string;
  options?: any;
  heading?: string;
  name?: string;
  validate?: { error: boolean; message: string };
  required?: true;
  disabled?: true;
  multiple?: true;
  onChange?: (event: React.SyntheticEvent, value: any | [], name: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}
export const AutoComplete = ({ label, options, value, name, validate, required, disabled, multiple, onChange, onBlur }: AutoCompleteProps): ReactElement => {
  return (
    <CssAutocomplete
      id="country-select-demo"
      multiple={multiple}
      options={options}
      value={value}
      onChange={(event, value) => onChange?.(event, value, name)}
      disabled={disabled}
      autoHighlight
      getOptionLabel={(option: any) => option.name}
      renderOption={(props, option: any) => (
        <Box component="li" {...props}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          name={name}
          required={required}
          error={validate?.error}
          helperText={validate?.message}
          onBlur={onBlur}
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
