import { LoginModel } from "../models/Login";

export const SEND_LOGIN_DATA = "SEND_LOGIN_DATA";

export interface LoginAction {
  type: typeof SEND_LOGIN_DATA;
  payload: LoginModel;
}

export type LoginActionTypes = LoginAction;
