import { AppActions } from "../../../types";
import { GET_CATEGORY, GET_VACCINES } from "../../../types/actions/Provider/AddVaccine.action";
import { categoryModel, vaccinesModel } from "../../../types/models/Provider/AddVaccine";

const initialState: {
  category: categoryModel[];
  vaccines: vaccinesModel[];
} = {
  category: [],
  vaccines: [],
};
const AddVaccine = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case GET_VACCINES:
      return {
        ...state,
        vaccines: action.payload,
      };
    default:
      return state;
  }
};
export default AddVaccine;
