import { ReactElement } from "react";
import { VAButton, FormInput } from "../../components";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { LoginModel } from "../../types/models/Login";
interface LoginFormProps {
  fields: LoginModel;
  handleFieldChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlurField: (event: React.FocusEvent<HTMLInputElement>) => void;
  errorFields: {
    email: { error: boolean; message: string };
    password: { error: boolean; message: string };
  };
  handleSubmit: () => void;
}
export const LoginForm = ({ fields, handleFieldChange, onBlurField, errorFields, handleSubmit }: LoginFormProps): ReactElement => {
  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <FormInput
          name="email"
          label="Email"
          type="email"
          value={fields.email}
          onChange={handleFieldChange}
          onBlur={onBlurField}
          validate={{ error: errorFields.email.error, message: errorFields.email.message }}
        />
        <FormInput
          name="password"
          label="Password"
          type="password"
          value={fields.password}
          onChange={handleFieldChange}
          onBlur={onBlurField}
          validate={{ error: errorFields.password.error, message: errorFields.password.message }}
        />
        <div className="flex gap-[12px]">
          <Box>
            <Checkbox color="success" />
            <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[20px] mt[revert]">Remember me</span>
          </Box>
        </div>
        <div>
          {/* <Link to="" className="text-[16px] text-[#000000] font-[400px]"> */}
          <VAButton text="Login" dark onClick={handleSubmit} />
          {/* </Link> */}
        </div>
        <div style={{ textAlign: "center" }}>
          <span className="text-[16px] font-[400] text-[#888A8C]">
            <Link to="/forgot-password" className="text-[16px] text-[#000000] font-[400px]">
              Forget password?
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};
