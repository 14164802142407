import { ReactElement } from "react";
import { Calendar, VAButton } from "../../../components";
interface VaccineScheduleProps {
  activeStep: any;
  selectedMonthEvents: any;
  setSelectedMonthEvents: any;
  handleCreateNewClick: () => void;
}
export const VaccineSchedule = ({ selectedMonthEvents, setSelectedMonthEvents, handleCreateNewClick }: VaccineScheduleProps): ReactElement => {
  return (
    <div>
      <h2 className="text-[24px] text-primary-main font-[600]">Create/Modify Vaccination Schedule</h2>
      <div className="flex flex-col gap-[20px] md:flex-row md:justify-between md:items-center mt-[16px] ">
        <div className="md:ml-auto">
          <VAButton onClick={handleCreateNewClick} dark text="Create new" />
        </div>
      </div>
      <div className="mt-[16px]">
        <Calendar selectedMonthEvents={selectedMonthEvents} setSelectedMonthEvents={setSelectedMonthEvents} />
      </div>
    </div>
  );
};
