import { ReactElement, ReactNode } from 'react';

interface DashboardCardProps {
  children: ReactNode;
}

export const DashboardCard = ({
  children,
}: DashboardCardProps): ReactElement => {
  return (
    <div className="rounded-[12px] shadow-[0px_4px_10px_rgba(0,0,0,0.2)]">
      {children}
    </div>
  );
};
