import jwtAxios from "../../../services/auth/jwt-auth";

/** START RESCHEDULE AND CANCEL FUNCTIONS */
export const getScheduledData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/getscheduleddata", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const rescheduleAppointment = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/rescheduleappointment", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSelectedRecord = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/deleteselectedrecord", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

/** END RESCHEDULE AND CANCEL FUNCTIONS */
export const getVaccineSchedules = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/getvaccineschedules", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVaccineDetail = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/getvaccinedetail", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addVaccineSchedules = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/addvaccineschedules", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateVaccineSchedules = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/updatevaccineschedules", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const viewPaperwork = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/viewpaperwork", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const viewVerifiedData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineschedules/viewverifieddata", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
