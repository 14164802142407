import { ReactElement } from "react";
import { VAButton, FormInput } from "../../components";
import { ResetPassModel, ErrorsResetPassInfoModel } from "../../types/models/ResetPass";
interface resetPassProps {
  inputs: ResetPassModel;
  errorsResetPassInfo: ErrorsResetPassInfoModel;
  validateErrorResetPassInfo: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onSubmit: any;
}

export const ResetPasswordForm = ({ inputs, errorsResetPassInfo: errors, validateErrorResetPassInfo: validateError, onChange, onSubmit }: resetPassProps): ReactElement => {
  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <FormInput
          name="newPassword"
          label="New Password"
          type="password"
          value={inputs.newPassword}
          validate={{ error: errors.newPassword.error, message: errors.newPassword.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />
        <FormInput
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          value={inputs.confirmPassword}
          validate={{ error: errors.confirmPassword.error, message: errors.confirmPassword.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />
        <div>
          <VAButton text="Reset Password" dark onClick={onSubmit} />
        </div>
      </div>
    </>
  );
};
