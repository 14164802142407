import { ReactElement } from "react";
import { PrimaryUser } from "./PrimaryUser";
import { SecondaryUser } from "./SecondaryUser";
import { CustomRadioButton } from "../../../components";
import { PrimaryUserModel, ErrorsPrimaryUserModel } from "../../../types/models/Provider/createProfile/PrimaryUser";
import { SecondaryUserModel, ErrorsSecondaryUserModel } from "../../../types/models/Provider/createProfile/SecondaryUser";

interface UserProfileProps {
  providerRoles: any;
  userTypes: string[];
  userType: string;
  setUserType: (e: string) => void;
  primaryUserInputs: PrimaryUserModel;
  errorsPrimaryUser: ErrorsPrimaryUserModel;
  validateErrorPrimaryUser: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handlePrimaryUserChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  /** Secondary users props */
  setSecondaryUserInputs: any;
  secondaryUserInputs: SecondaryUserModel;
  errorsSecondaryUser: ErrorsSecondaryUserModel;
  validateErrorSecondaryUser: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  validateFormSecondaryUser: () => boolean;
  handleSecondaryUserChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  secondaryUsers: SecondaryUserModel[];
  setSecondaryUsers: (e: SecondaryUserModel[]) => void;
  handleResetSecondaryUser: () => void;
}

export const UserProfile = ({
  providerRoles,
  userTypes,
  userType,
  setUserType,
  primaryUserInputs,
  errorsPrimaryUser,
  validateErrorPrimaryUser,
  handlePrimaryUserChange,
  setSecondaryUserInputs,
  secondaryUserInputs,
  errorsSecondaryUser,
  validateErrorSecondaryUser,
  validateFormSecondaryUser,
  handleSecondaryUserChange,
  secondaryUsers,
  setSecondaryUsers,
  handleResetSecondaryUser,
}: UserProfileProps): ReactElement => {
  return (
    <>
      <div>
        <div className="outer__right-user-info">
          <h1 className="outer__right-user-info-heading">User Info</h1>
        </div>
        <div className="outer__right-radio-buttons flex items-center gap-[12px]">
          <div className="outer__right-radio-buttons-btns">
            <CustomRadioButton options={userTypes} active={userType} setActive={setUserType} />
          </div>
          <div>
            <p className="outer__right-radio-buttons-text">Will this location have a single user or multiple users?</p>
          </div>
        </div>
      </div>
      <div>
        <PrimaryUser
          providerRoles={providerRoles}
          primaryUserInputs={primaryUserInputs}
          errorsPrimaryUser={errorsPrimaryUser}
          validateErrorPrimaryUser={validateErrorPrimaryUser}
          handlePrimaryUserChange={handlePrimaryUserChange}
        />
      </div>
      {userType === "Multiple" ? (
        <div className="mt-[30px]">
          <SecondaryUser
            providerRoles={providerRoles}
            setSecondaryUserInputs={setSecondaryUserInputs}
            secondaryUserInputs={secondaryUserInputs}
            errorsSecondaryUser={errorsSecondaryUser}
            validateErrorSecondaryUser={validateErrorSecondaryUser}
            validateFormSecondaryUser={validateFormSecondaryUser}
            handleSecondaryUserChange={handleSecondaryUserChange}
            secondaryUsers={secondaryUsers}
            setSecondaryUsers={setSecondaryUsers}
            handleResetSecondaryUser={handleResetSecondaryUser}
          />
        </div>
      ) : null}
    </>
  );
};
