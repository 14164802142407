import { ReactElement } from "react";
import { FormInput, FormInputMulti, VAButton } from "../../../components";
import { Box } from "@mui/material";
import { MENU } from "../../../types/actions/Common.action";
interface ManufacturerProps {
  selectedMenu: MENU;
  name: string;
  description: string;
  handleChange: (event: any) => void;
  onBlurField: (event: React.FocusEvent<HTMLInputElement>) => void;
  errorFields: { name: { error: boolean; message: string } };
  handleSubmit: () => void;
  onClickBack: () => void;
}
export const Manufacturer = ({ selectedMenu, name, description, handleChange, onBlurField, errorFields, handleSubmit, onClickBack }: ManufacturerProps): ReactElement => {
  return (
    <div className="mt-[22px]">
      <div className="mt-[20px]">
        <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
          <div className="flex flex-col gap-[30px]">
            <FormInput
              name="name"
              label="Manufacturer Name"
              type="text"
              value={name}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.name.error, message: errorFields.name.message }}
            />
            <FormInputMulti name="description" label="Description" value={description} onChange={handleChange} />
            <div>
              <Box display="flex" flexDirection="row" alignItems="center" width={1} justifyContent="end">
                <Box sx={{ mr: 2 }}>
                  <VAButton text="Back" light onClick={onClickBack} />
                </Box>
                <Box>
                  <VAButton text={selectedMenu === "ADD" ? "Add" : "Update"} dark onClick={handleSubmit} />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
