import jwtAxios from "../../../services/auth/jwt-auth";

export const getVaccines = async () => {
  try {
    const res = await jwtAxios.post("/superadmin/vaccines/getvaccines", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDiagnostics = async () => {
  try {
    const res = await jwtAxios.post("/superadmin/vaccines/getdiagnostics", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addVaccine = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/vaccines/addvaccine", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateVaccine = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/vaccines/updatevaccine", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteVaccine = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/vaccines/deletevaccine", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
