import React, { useState } from "react";
import { useSelector } from "react-redux";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { styled } from "@mui/material/styles";
import { Stack, TableCell, TableRow, Box, Grid, Button, Modal, Dialog, DialogProps, Link } from "@mui/material";
import { VAButton, VerifyScheduleAppointment, VerifyTestSA } from "../../../../components";
import VAAlert from "../../../../components/Alert/Alert";
import { ViewPaperworkModal } from "../../../../components/CustomModal/ViewPaperworkModal.component";
import {
  viewPaperwork,
  verifyVaccineAppointment,
  getScheduledData,
  rescheduleAppointment,
  getScheduleAppointments,
  deleteSelectedRecord,
  verifyTestAppointment,
  viewVerifiedData,
  shareVaccineAppointment,
} from "../../../../redux/actionCreators";
import { AppState } from "../../../../redux/store";
import { createTimeSlots, getFormattedDate } from "../../../../shared/Util";
import { AlertType } from "../../../../shared/constants/AppConst";
import { ScheduleAppointmentModel } from "../../../../types/models/Provider/ScheduleAppointment";
import { SelectDateAndTime } from "../SelectDateAndTime";
import { ViewVerifiedDataModal } from "../../../../components/CustomModal/ViewVerifiedDataModal.component";

function loadFile(url: string, callback: (error: any, content: any) => void) {
  PizZipUtils.getBinaryContent(url, callback);
}

interface CustomError extends Error {
  properties?: {
    errors: Array<{ properties: { explanation: string } }>;
  };
}

function replaceErrors(key: any, value: any): any {
  if (value instanceof Error) {
    const errorObj: any = {};
    const errorProperties = Object.getOwnPropertyNames(value);

    for (const prop of errorProperties) {
      errorObj[prop] = value[prop as keyof Error];
    }

    return errorObj;
  }
  return value;
}
const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));

interface TableItemProps {
  row: ScheduleAppointmentModel;
  selecctedAppointmentIdx: number;
  scheduleAppointments: ScheduleAppointmentModel[];
  setScheduleAppointments: React.Dispatch<React.SetStateAction<ScheduleAppointmentModel[]>>;
}

const TableItem: React.FC<TableItemProps> = ({ row, selecctedAppointmentIdx, scheduleAppointments, setScheduleAppointments }) => {
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [fullWidth] = React.useState(true);
  const [xsDialogWidth] = React.useState<DialogProps["maxWidth"]>("xs");
  const [smDialogWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  /** START RESCHEDULE AND CANCEL STATES */
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [scheduleData, setScheduleData] = useState<any>([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>(null);
  const [idx, setIdx] = useState(0);

  const filteredData = selectedDate ? scheduleData.filter((data: any) => data.date === selectedDate) : scheduleData;

  let availableTimeSlots: any = [];
  if (filteredData.length > 0) availableTimeSlots = createTimeSlots(filteredData[0].scheduledVaccines);

  const formatedDate = `${getFormattedDate(row.date)}, ${row.slot}`;
  /** END RESCHEDULE AND CANCEL STATES */

  /** START VERIFY DIALOG STATES */
  const [openVerify, setOpenVerify] = useState(false);
  /** END VERIFY DIALOG STATES */
  const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
  const [opentimeSlotDialog, setOpentimeSlotDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openPaperworkModal, setopenPaperworkModal] = useState(false);
  const [paperWorkModalData, setPaperWorkModalData] = useState([]);
  const [openVerfiedDataModal, setOpenVerfiedDataModal] = useState(false);
  const [verfiedDataModal, setVerfiedDataModal] = useState({});
  const [editVerifiedData, setEditVerifiedData] = useState<any>(null);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const viewPaperWorkClick = async () => {
    try {
      let res = await viewPaperwork({ schedule_appointment_id: row.schedule_appointment_id });
      setPaperWorkModalData(res);
      setopenPaperworkModal(true);
    } catch (err) {
      console.log("viewPaperwork Error: ", err);
    }
  };

  const viewVerifiedClick = async () => {
    try {
      let res = await viewVerifiedData({ schedule_appointment_id: row.schedule_appointment_id, vaccine_type: row.vaccine_type });
      if (res.data.length > 0) {
        const resData = {
          vaccine_type: row.vaccine_type,
          vaccine_name: row.vaccine_name,
          manufacturer: row.manufacturer,
          patient_name: row.patient_name,
          ...res.data[0],
        };
        setVerfiedDataModal(resData);
        setOpenVerfiedDataModal(true);
      }
    } catch (err) {
      console.log("viewVerifiedClick Error: ", err);
    }
  };

  const downloadPaperWorkClick = async () => {
    try {
      let res = await viewPaperwork({ schedule_appointment_id: row.schedule_appointment_id });
      loadFile(`${process.env.REACT_APP_DOCUMENT_URL}${res.vaccine_type === "VACCINE" ? "PatientVaccineInfo.docx" : "PatientDiagnosticTestInfo.docx"}`, function (error, content) {
        if (error) {
          throw error;
        }
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          nullGetter: () => "", // Set options for nested loop rendering
        });
        const downloadPatientInfo = {
          first_name: row.first_name,
          last_name: row.last_name,
          signature: row.signature,
          date_of_signature: new Date(row.date_of_signature).toDateString().substring(0, 10),
          email: row.email,
          phone: row.phone_no,
          gender: row.gender,
          address: row.address,
          city: row.city,
          state: row.state,
          vaccine_name: row.vaccine_name,
          dosage: row.dosage,
          date: formatedDate,
          screeningNames: res.screeningData.map((item: any) => ({
            screening_name: item.screening_name,
            screeningQuestions: item.screening_questions_List.map((sqItem: any) => ({
              question: sqItem.screeningData.question,
              options: sqItem.options.map((option: any) => option.text).join(", "),
              answer: sqItem.screeningData.selected_option_id !== "-1" ? sqItem.screeningData.selected_option : "N/A",
            })),
          })),
          consent_form: res.prov_vaccine_consent,
          vis: res.vis,
        };
        // if (res.vaccine_type === "TEST" && downloadPatientInfo.screeningNames.length === 0) delete downloadPatientInfo.screeningNames;
        doc.setData(downloadPatientInfo);
        try {
          doc.render();
        } catch (error) {
          const typedError = error as CustomError;
          console.log(JSON.stringify({ error: typedError }, replaceErrors));

          if (typedError.properties && typedError.properties.errors instanceof Array) {
            const errorMessages = typedError.properties.errors
              .map(function (error: any) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
          }
          throw typedError;
        }
        var out = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        saveAs(out, "output.docx");
      });
    } catch (err) {
      console.log("viewPaperwork Error: ", err);
    }
  };

  const handleOpen = (dialog: string) => {
    if (dialog === "VERIFY") setOpenVerify(true);
  };
  const detailsCell = () => {
    return (
      <Grid container spacing={1}>
        <React.Fragment key={row.schedule_appointment_id}>
          <Grid item xs={12}>
            <Box>
              <span style={{ color: "#0F5175" }}>{"Name: "}</span>
              {row.patient_name}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <span style={{ color: "#0F5175" }}>{"Vaccine: "}</span>
              {row.vaccine_name}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <span style={{ color: "#0F5175" }}>{"Dosage: "}</span>
              {row.dosage}
            </Box>
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const statusCell = () => {
    return (
      <Grid container spacing={1}>
        <React.Fragment key={row.schedule_appointment_id}>
          {row.verify && (
            <React.Fragment>
              <Grid item xs={12}>
                <Box sx={{ color: "#3B873E" }}>
                  <Link sx={{ color: "#3B873E" }} href="#" onClick={viewVerifiedClick}>
                    View Verified
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <span style={{ color: "#0F5175" }}>{"Manufacturer: "}</span>
                  {row.manufacturer}
                </Box>
              </Grid>{" "}
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <Box>
              {row.status}
              <br />
              <Link href="#" onClick={viewPaperWorkClick}>
                View
              </Link>
              <Link style={{ marginLeft: 5 }} href="#" onClick={downloadPaperWorkClick}>
                Download
              </Link>
            </Box>
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };
  const allActionsCell = () => {
    return (
      <Stack direction="row" alignItems="start" justifyContent={"center"} spacing={2}>
        <VAButton size="small" dark text="Verify" onClick={() => handleOpen("VERIFY")} />
        <VAButton size="small" light text="Reschedule" onClick={rescheduleClick} />
        <VAButton size="small" outlined text="Cancel" onClick={() => setOpenConfirmationDialog(true)} />
      </Stack>
    );
  };
  const actionCell = () => {
    return (
      <Stack direction="row" alignItems="start" justifyContent={"center"} spacing={2}>
        <VAButton size="small" light text="Reschedule" onClick={rescheduleClick} />
        <VAButton size="small" outlined text="Cancel" onClick={() => setOpenConfirmationDialog(true)} />
      </Stack>
    );
  };
  const onClickVerify = async (verifyformData: any) => {
    try {
      let reqData = {
        biz_branch_id: userData.selected_branch.biz_branch_id,
        schedule_appointment_id: row.schedule_appointment_id,
        patient_id: row.patient_id,
        verify_by: userData.user_id,
        ...verifyformData,
      };
      let res = row.vaccine_type === "TEST" ? await verifyTestAppointment(reqData) : await verifyVaccineAppointment(reqData);
      setScheduleAppointments(res.data);
      setAlertMessage({ type: "success", description: res.message, open: true });
      setOpenVerify(false);
      setEditVerifiedData(null);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickShare = async (shareFormData: any) => {
    try {
      let reqData = {
        schedule_appointment_id: row.schedule_appointment_id,
        patient_id: row.patient_id,
        patient_name: row.patient_name,
        patient_email: row.email,
        ...shareFormData,
      };

      const res = await shareVaccineAppointment(reqData);
      setAlertMessage({ type: "success", description: res.message, open: true });
      setOpenVerfiedDataModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickEditVerifiedData = async (editFormData: any) => {
    try {
      setEditVerifiedData(
        editFormData.vaccine_type === "VACCINE"
          ? {
              lotNumber: editFormData.lot_no,
              dateExp: editFormData.date_exp,
              ndc: editFormData.ndc,
              dateAdministered: editFormData.date_administered,
              note: editFormData.note,
              vaccine_type: editFormData.vaccine_type,
            }
          : {
              specimenSource: editFormData.specimen_source,
              testType: editFormData.test_type,
              categoricalResult: editFormData.categorical_result,
              numericalResult: editFormData.numerical_result,
              numericalValue: editFormData.numerical_value,
              testDate: editFormData.test_date,
              testDateResulted: editFormData.test_date_resulted,
              note: editFormData.note,
              vaccine_type: editFormData.vaccine_type,
            }
      );

      setOpenVerify(true);
    } catch (err) {
      console.log(err);
    }
  };

  /** START RESCHEDULE AND CANCEL FUNCTIONS */
  const handlesSelectedTimeSlot = async (slot: string) => {
    setSelectedTimeSlot(slot);
  };

  const rescheduleClick = async () => {
    let data = {
      biz_branch_id: scheduleAppointments[selecctedAppointmentIdx].biz_branch_id,
      // prov_vaccine_id: scheduleAppointments[selecctedAppointmentIdx].prov_vaccine_id,
    };
    let scheduleData = await getScheduledData(data);

    setScheduleData(scheduleData.data);

    //set already selected date and slot
    let date = scheduleAppointments[selecctedAppointmentIdx].date;
    let slot = scheduleAppointments[selecctedAppointmentIdx].slot;

    setSelectedDate(date);
    setSelectedTimeSlot(slot);
    setIdx(selecctedAppointmentIdx);
    setOpenRescheduleDialog(true);
  };

  const reschedule = async () => {
    let data = {
      schedule_appointment_id: scheduleAppointments[idx].schedule_appointment_id,
      date: selectedDate,
      slot: selectedTimeSlot,
    };

    let res = await rescheduleAppointment(data);
    setAlertMessage({ type: "success", description: res.message, open: true });

    try {
      let res2 = await getScheduleAppointments({ biz_branch_id: userData.selected_branch.biz_branch_id });
      setScheduleAppointments(res2.data);
    } catch (err) {
      console.log(err);
    }
    setOpentimeSlotDialog(false);
    setOpenRescheduleDialog(false);
  };

  const cancelClick = async () => {
    const schedule_appointment_id = scheduleAppointments[selecctedAppointmentIdx].schedule_appointment_id;
    await deleteSelectedRecord({ schedule_appointment_id, vaccine_type: row.vaccine_type });

    try {
      let res2 = await getScheduleAppointments({ biz_branch_id: userData.selected_branch.biz_branch_id });
      setScheduleAppointments(res2.data);
    } catch (err) {
      console.log(err);
    }
  };

  const RescheduleDialog = () => {
    return (
      <Dialog fullWidth={fullWidth} maxWidth={smDialogWidth} open={openRescheduleDialog} onClose={() => setOpenRescheduleDialog(false)}>
        <div style={{ alignContent: "center", margin: "30px" }}>
          <div className="outer__right-header text-center">
            <h1 className="outer__right-header-heading">Select the date to reschedule your appointment!</h1>
          </div>
          <br />
          <SelectDateAndTime selectedDate={selectedDate} scheduleData={scheduleData} setSelectedDate={setSelectedDate} setOpentimeSlotDialog={setOpentimeSlotDialog} />
          <br />
          <VAButton
            text="Cancel"
            light
            onClick={() => {
              setOpenRescheduleDialog(false);
            }}
          />
        </div>
      </Dialog>
    );
  };
  const TimeSlotDialog = () => {
    return (
      <>
        {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
        <Dialog fullWidth={fullWidth} maxWidth={xsDialogWidth} open={opentimeSlotDialog} onClose={() => setOpentimeSlotDialog(false)}>
          <div style={{ alignContent: "center", margin: "30px" }}>
            <div className="outer__right-header text-center">
              <h1 className="outer__right-header-heading">Select the time slot of your appointment?</h1>
            </div>
            <br />
            <div className="flex flex-col md:grid md:items-end md:grid-cols-[6fr] justify-between w-full gap-[10px] mt-[10px] text-center">
              {selectedDate === undefined || selectedDate === null ? null : (
                <Grid container spacing={6}>
                  {availableTimeSlots.map((scheduledVaccine: any) => (
                    <Grid item key={scheduledVaccine} xs={4}>
                      <Button
                        variant={selectedTimeSlot === scheduledVaccine.slot ? "contained" : "outlined"}
                        fullWidth
                        onClick={() => {
                          handlesSelectedTimeSlot(scheduledVaccine.slot);
                        }}
                        style={{
                          backgroundColor: selectedTimeSlot === scheduledVaccine.slot ? "#45818e" : "transparent",
                          color: selectedTimeSlot === scheduledVaccine.slot ? "white" : "#45818e",
                        }}
                      >
                        {scheduledVaccine.slot}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
            <br />

            <VAButton text="Reschedule" dark onClick={reschedule} />
            <br />
            <VAButton
              text="Cancel"
              light
              onClick={() => {
                setOpentimeSlotDialog(false);
              }}
            />
          </div>
        </Dialog>
      </>
    );
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    borderStyle: "none",
    borderRadius: "43px",
    boxShadow: 24,
  };
  const ConfirmationDialog = () => {
    return (
      <Modal open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
            <div className="max-w-[360px] justify-center items-center flex flex-col gap-[22px] mt-[17px]">
              <h2 className="text-[20px] font-[600] text-[#000000] max-w-[300px]">Cancel appointment</h2>
              <span style={{ textAlign: "center" }} className="text-[14px] font-[400] text-[#888A8C] max-w-[300px]">
                Are you sure you want to cancel {row.patient_name}’s appointment for Covid 19 {row.vaccine_name}!
              </span>
            </div>
            <div className="flex flex-col gap-[20px] mt-[28px] w-full">
              <VAButton
                text="Yes, Cancel"
                dark
                onClick={() => {
                  cancelClick();
                  setOpenConfirmationDialog(false);
                }}
              />
              <VAButton text="No, Not now" onClick={() => setOpenConfirmationDialog(false)} outlined />
            </div>
          </div>
        </Box>
      </Modal>
    );
  };
  /** END RESCHEDULE AND CANCEL FUNCTIONS */
  return (
    <>
      <TableRow key={row.schedule_appointment_id} className="item-hover">
        <StyledTableCell align="left">{formatedDate}</StyledTableCell>
        <StyledTableCell align="left">{detailsCell()}</StyledTableCell>
        <StyledTableCell align="left">{row.vaccine_type}</StyledTableCell>
        <StyledTableCell align="left">{statusCell()}</StyledTableCell>
        {!row.verify ? <StyledTableCell align="center">{allActionsCell()}</StyledTableCell> : actionCell()}
      </TableRow>
      {openVerify && row.vaccine_type === "VACCINE" && (
        <VerifyScheduleAppointment
          open={openVerify}
          setOpen={setOpenVerify}
          patient_name={row.patient_name}
          prov_vaccine_id={row.prov_vaccine_id}
          biz_branch_id={row.biz_branch_id}
          vaccine_type={row.vaccine_type}
          scheduled_mfg_id={row.mfg_id}
          onClickVerify={onClickVerify}
          {...(editVerifiedData !== null &&
            editVerifiedData.vaccine_type === "VACCINE" && {
              editData: editVerifiedData,
              setEditData: setEditVerifiedData,
            })}
        />
      )}
      {openVerify && row.vaccine_type === "TEST" && (
        <VerifyTestSA
          open={openVerify}
          setOpen={setOpenVerify}
          patient_name={row.patient_name}
          prov_vaccine_id={row.prov_vaccine_id}
          biz_branch_id={row.biz_branch_id}
          vaccine_type={row.vaccine_type}
          scheduled_mfg_id={row.mfg_id}
          onClickVerify={onClickVerify}
          {...(editVerifiedData !== null &&
            editVerifiedData.vaccine_type === "TEST" && {
              editData: editVerifiedData,
              setEditData: setEditVerifiedData,
            })}
        />
      )}
      <RescheduleDialog />
      <TimeSlotDialog />
      <ConfirmationDialog />
      {openPaperworkModal && <ViewPaperworkModal openPaperworkModal={openPaperworkModal} setopenPaperworkModal={setopenPaperworkModal} paperWorkModalData={paperWorkModalData} />}
      {openVerfiedDataModal && (
        <ViewVerifiedDataModal open={openVerfiedDataModal} setOpen={setOpenVerfiedDataModal} data={verfiedDataModal} onClickShare={onClickShare} onClickEditVerifiedData={onClickEditVerifiedData} />
      )}
    </>
  );
};

export default TableItem;
