/* eslint-disable array-callback-return */
import { isSameDay } from "date-fns";
import { ReactElement, useState } from "react";
import { BulletPoint } from "../../icons";
import useDate from "../../hooks/useDate";
import { Popover } from "@mui/material";
import React from "react";
import { EditScheduleDialog } from "./ScheduleDialog.component";

const DetailModal = ({ children, day, dayData, anchorEl, open, handleClose, onClickDaySchedule }: any) => {
  const date = useDate();

  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="p-[20px] shadow-[0px_4px_10px_rgba(0,0,0,0.2)] bg-[white] rounded-[8px]">
          <div className=" flex gap-[5px] text-[#1F2124] text-[14px] font-medium">
            <p>{date.month}</p>
            <p>{day},</p>
            <p>{date.year}</p>
          </div>
          <div className="mt-[6px]">
            {dayData.events.map((dayEvent: any, idx: any) => {
              return (
                <div onClick={() => onClickDaySchedule(dayEvent, dayData.events)} key={idx} className="text-[13px] flex gap-[4px] items-center" style={{ cursor: "pointer" }}>
                  <span>
                    <BulletPoint />
                  </span>
                  <p>
                    {dayEvent.starttime}-{dayEvent.endtime}({dayEvent.timeduration + "m"})
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Popover>
      {children}
    </>
  );
};

interface DayCardProps {
  date: any;
  dayData: any;
  setSelectedMonthEvents: any;
}
export const DayCard = ({ date, dayData, setSelectedMonthEvents }: DayCardProps): ReactElement => {
  /** States and Functions related to Detail Modal */
  const [anchorEl, setAnchorEl] = React.useState<HTMLParagraphElement | null>(null);

  const [openEditScheduleModal, setOpenEditScheduleModal] = useState(false);
  // Set time slots type to { starttime: string; endtime: string; timeduration: string; }[]
  const [timeSlots, setTimeSlots] = useState<{ starttime: string; endtime: string; timeduration: string }[]>([...dayData.events]);
  // get date from first record of the dayData events array and set it to the date state variable if it is not empty
  const [selectedScheduleDate, setSelectedScheduleDate] = useState("");
  const [starttime, setStartTime] = useState("");
  const [endtime, setEndTime] = useState("");
  const [timeduration, setTimeDuration] = useState(""); //put the time slots here
  const [startTimeSlots] = useState([
    { value: "9:00 AM", label: "9:00 AM" },
    { value: "9:30 AM", label: "9:30 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "10:30 AM", label: "10:30 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "11:30 AM", label: "11:30 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "12:30 PM", label: "12:30 PM" },
    { value: "1:00 PM", label: "1:00 PM" },
    { value: "1:30 PM", label: "1:30 PM" },
    { value: "2:00 PM", label: "2:00 PM" },
    { value: "2:30 PM", label: "2:30 PM" },
    { value: "3:00 PM", label: "3:00 PM" },
    { value: "3:30 PM", label: "3:30 PM" },
    { value: "4:00 PM", label: "4:00 PM" },
    { value: "4:30 PM", label: "4:30 PM" },
    { value: "5:00 PM", label: "5:00 PM" },
    { value: "5:30 PM", label: "5:30 PM" },
    { value: "6:00 PM", label: "6:00 PM" },
    { value: "6:30 PM", label: "6:30 PM" },
    { value: "7:00 PM", label: "7:00 PM" },
    { value: "7:30 PM", label: "7:30 PM" },
  ]);
  // set the end time slots argument type to { value: string; label: string; }[]
  const [endTimeSlots, setEndTimeSlots] = useState<{ value: string; label: string }[]>([]);
  const timeDurationSlots = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "45", label: "45" },
    { value: "60", label: "60" },
  ];
  const handleStartTime = (e: any) => {
    setStartTime(e.target.value);
    // setEndTimeSlots after the selected start time
    const startTimeIndex = startTimeSlots.findIndex((item) => item.value === e.target.value);
    const endTimeSlots = [];
    for (let i = startTimeIndex + 1; i < startTimeSlots.length; i++) {
      endTimeSlots.push(startTimeSlots[i]);
    }
    setEndTimeSlots(endTimeSlots);
  };
  const handleDetailModal = (event: React.MouseEvent<HTMLParagraphElement>) => {
    // When date is viewd convert the date into the Local Date);
    setSelectedScheduleDate(dayData.events[0].date);
    setAnchorEl(event.currentTarget);
  };

  // onClickEdit function when events are less than or equal to 3
  const onClickEdit = (dayEvents: any) => {
    setOpenEditScheduleModal(!openEditScheduleModal);
    // create a copy of dayEvents array
    const dayEventsCopy = [...dayEvents];
    // When date is viewd convert the date into the Local Date
    setSelectedScheduleDate(dayEventsCopy[0].date);

    setTimeSlots(dayEventsCopy);
    setEndTime("");
    setTimeDuration("");
  };
  const handleCloseDetailModal = () => {
    setAnchorEl(null);
  };

  const openDetailModal = Boolean(anchorEl);
  const onClickDaySchedule = (daySelectedEvent: any, dayEvents: any) => {
    setOpenEditScheduleModal(!openEditScheduleModal);

    setStartTime(daySelectedEvent.starttime);
    // setEndTimeSlots after the selected start time
    const startTimeIndex = startTimeSlots.findIndex((item) => item.value === daySelectedEvent.starttime);
    const endTimeSlots = [];
    for (let i = startTimeIndex + 1; i < startTimeSlots.length; i++) {
      endTimeSlots.push(startTimeSlots[i]);
    }
    setEndTimeSlots(endTimeSlots);
    setEndTime(daySelectedEvent.endtime);
    setTimeDuration(daySelectedEvent.timeduration);
    // create a copy of dayEvents array
    const dayEventsCopy = [...dayEvents];
    // remove the selected event from the day events
    const dayEventsIndex = dayEventsCopy.findIndex((dItem: any) => dItem.starttime === daySelectedEvent.starttime);
    dayEventsCopy.splice(dayEventsIndex, 1);
    setTimeSlots(dayEventsCopy);
  };

  /** States and Functions related to Edit Schedule Modal */

  const handleEditScheduleModal = () => {
    setOpenEditScheduleModal(!openEditScheduleModal);
    setAnchorEl(null);
  };

  //Extracts day from date object
  const day = date.getDate();
  //Checks if current day matches date
  const sameDayCheck = isSameDay(date, new Date());

  //Aplies styling if isSameDay is true
  const currentDayStyles =
    sameDayCheck === true
      ? {
          backgroundColor: "rgba(244, 135, 68, 0.25)",
          borderRadius: "50%",
        }
      : {};
  return (
    <div className="relative flex flex-col bg-white group px-[4px]">
      <div className="flex justify-center">
        <span style={currentDayStyles} className="mt-1 p-[8px] h-[35px] w-[35px] flex justify-center  text-sm font-semibold text-[#F48744]">
          {day}
        </span>
      </div>
      <div>
        {dayData.events.map((dayEvent: any, idx: any) => {
          if (idx < 3) {
            return (
              <div key={idx} className="text-[11px]">
                {dayEvent.starttime}-{dayEvent.endtime}({dayEvent.timeduration + "m"})
                {idx === dayData.events.length - 1 ? (
                  <p onClick={() => onClickEdit(dayData.events)} key={idx} className="underline cursor-pointer">
                    Edit
                  </p>
                ) : null}
              </div>
            );
          } else {
            if (idx === dayData.events.length - 1) {
              return (
                <div className="flex gap-[12px] mt-[5px] underline font-medium text-[#1F2124] text-[11px]">
                  <DetailModal
                    onClickDaySchedule={onClickDaySchedule}
                    handleDetailModal={handleDetailModal}
                    idx={idx}
                    anchorEl={anchorEl}
                    open={openDetailModal}
                    handleClose={handleCloseDetailModal}
                    dayData={dayData}
                    day={day}
                  >
                    <p onClick={handleDetailModal} key={idx} className="cursor-pointer">
                      {dayData.events.length - 3} more Edit
                    </p>
                  </DetailModal>
                </div>
              );
            }
          }
        })}
      </div>
      <EditScheduleDialog
        day={day}
        startTimeSlots={startTimeSlots}
        endTimeSlots={endTimeSlots}
        setEndTimeSlots={setEndTimeSlots}
        timeDurationSlots={timeDurationSlots}
        handleStartTime={handleStartTime}
        starttime={starttime}
        setStartTime={setStartTime}
        endtime={endtime}
        setEndTime={setEndTime}
        timeduration={timeduration}
        setTimeDuration={setTimeDuration}
        timeSlots={timeSlots}
        setTimeSlots={setTimeSlots}
        dayData={dayData}
        openEditScheduleModal={openEditScheduleModal}
        handleEditScheduleModal={handleEditScheduleModal}
        selectedScheduleDate={selectedScheduleDate}
        setSelectedMonthEvents={setSelectedMonthEvents}
      />
    </div>
  );
};
