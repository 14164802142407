import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { Box, Checkbox, Modal, FormControlLabel, FormControl, FormGroup, FormHelperText } from "@mui/material";
import { VAButton, FormInput, DatePicker, FromSelect } from "..";
import { getOfferedMfgs, getOfferedVaccinesByMfg } from "../../redux/actionCreators";
import VAAlert from "../Alert/Alert";
import { AlertType } from "../../shared/constants/AppConst";
import { getFormattedDate } from "../../shared/Util";
dayjs.extend(utc);
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};
interface props {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  patient_name?: string;
  prov_vaccine_id: number;
  biz_branch_id: number;
  vaccine_type: string;
  scheduled_mfg_id: string;
  onClickVerify?: (data: any) => void;
  editData?: any;
  setEditData?: any;
}
export const VerifyScheduleAppointment: React.FC<props> = ({ patient_name, open, setOpen, prov_vaccine_id, vaccine_type, biz_branch_id, scheduled_mfg_id, onClickVerify, editData, setEditData }) => {
  const [mfgs, setMfgs] = useState([]);
  const [mfg, setMfg] = useState(scheduled_mfg_id);
  const [vaccines, setVaccines] = useState([]);
  const [vaccine, setVaccine] = useState(prov_vaccine_id.toString());
  const [lotNumber, setLotNumber] = useState("");
  const [dateExp, setDateExp] = React.useState<dayjs.Dayjs | null>(null);
  const [ndc, setNdc] = useState(0);
  const [dateAdministered, setDateAdministered] = React.useState<dayjs.Dayjs | null>(null);
  const [note, setNote] = useState("");
  const [confirmCheck, setConfirmCheck] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [errors, setErrors] = useState({
    mfg: { error: false, message: "" },
    vaccine: { error: false, message: "" },
    lotNumber: { error: false, message: "" },
    dateExp: { error: false, message: "" },
    ndc: { error: false, message: "" },
    dateAdministered: { error: false, message: "" },
    confirmCheck: { error: false, message: "" },
  });
  useEffect(() => {
    if (editData) {
      setLotNumber(editData.lotNumber || "");
      setDateExp(editData.dateExp ? dayjs(editData.dateExp) : null);
      setNdc(editData.ndc || 0);
      setDateAdministered(editData.dateAdministered ? dayjs(editData.dateAdministered) : null);
      setNote(editData.note || "");
    }
  }, [editData]);
  const validateErrors = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrors({ ...errors, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrors({ ...errors, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateForm = () => {
    let formIsValid = true;
    let localErrors = {
      mfg: { error: false, message: "" },
      vaccine: { error: false, message: "" },
      lotNumber: { error: false, message: "" },
      dateExp: { error: false, message: "" },
      ndc: { error: false, message: "" },
      dateAdministered: { error: false, message: "" },
      confirmCheck: { error: false, message: "" },
    };

    if (mfg === "") {
      formIsValid = false;
      localErrors.mfg = { error: true, message: "Required Field!" };
    }

    if (vaccine === "") {
      formIsValid = false;
      localErrors.vaccine = { error: true, message: "Required Field!" };
    }

    if (lotNumber === "") {
      formIsValid = false;
      localErrors.lotNumber = { error: true, message: "Required Field!" };
    }

    if (dateExp === null) {
      formIsValid = false;
      localErrors.dateExp = { error: true, message: "Required Field!" };
    }

    if (ndc === 0) {
      formIsValid = false;
      localErrors.ndc = { error: true, message: "Required Field!" };
    }

    if (dateAdministered === null) {
      formIsValid = false;
      localErrors.dateAdministered = { error: true, message: "Required Field!" };
    }

    if (confirmCheck === false) {
      formIsValid = false;
      localErrors.confirmCheck = { error: true, message: "Required Field!" };
    }
    setErrors({ ...errors, ...localErrors });
    return formIsValid;
  };
  const handleChange = async (event: any) => {
    if (event.target.type === "checkbox") {
      const target = event.target as HTMLInputElement;
      setConfirmCheck(target.checked);
    } else {
      if (event.target.name === "lotNumber") {
        setLotNumber(event.target.value as string);
      }
      if (event.target.name === "ndc") {
        setNdc(event.target.value as number);
      }
      if (event.target.name === "vaccine") {
        setVaccine(event.target.value as string);
      }
      if (event.target.name === "mfg") {
        setMfg(event.target.value as string);
        await getAllOfferedVaccinesByMfgs(event.target.value as string);
      }
      if (event.target.name === "note") {
        setNote(event.target.value as string);
      }
    }
  };
  const onClickVerifyConfirm = async () => {
    if (validateForm()) {
      if (onClickVerify) onClickVerify({ prov_vaccine_id: vaccine, lot_no: lotNumber, date_exp: getFormattedDate(dateExp), ndc, date_administered: getFormattedDate(dateAdministered), note });
    }
  };
  const getAllOfferedVaccinesByMfgs = async (selected_mfg_id: string) => {
    try {
      let res = await getOfferedVaccinesByMfg({ mfg_id: selected_mfg_id, biz_branch_id });
      if (res.message === "SUCCESS") setVaccines(res.data);
    } catch (err) {
      console.error("Error when getting the Vaccine Mfgs: ", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getOfferedMfgs({ vaccine_type, biz_branch_id });
        if (res.message === "SUCCESS") setMfgs(res.data);
        let res2 = await getOfferedVaccinesByMfg({ mfg_id: mfg, biz_branch_id });
        if (res2.message === "SUCCESS") setVaccines(res2.data);
      } catch (err) {
        console.error("Error when getting the Vaccine Mfgs or Offered Vaccines by MFG: ", err);
      }
    };

    fetchData();
  }, [vaccine_type, biz_branch_id, mfg]);
  const handleClose = () => {
    setOpen(false);
    if (editData) setEditData(null);
  };
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{ ...style, maxHeight: "100vh", overflowY: "auto" }}>
        <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
          <div className="max-w-[360px] flex flex-col gap-[12px]">
            <h2 className="text-[20px] font-[600] text-[#000000]">Verify vaccine for {patient_name}</h2>
          </div>
          <div className="mt-[12px]">
            <div className="flex flex-col gap-[12px]">
              <FromSelect
                name="mfg"
                label="Manufacturer"
                value={mfg}
                options={mfgs}
                onChange={handleChange}
                onBlur={validateErrors}
                validate={{ error: errors.mfg.error, message: errors.mfg.message }}
              />
              <FromSelect
                name="vaccine"
                label="Vaccine"
                value={vaccine}
                options={vaccines}
                onChange={handleChange}
                onBlur={validateErrors}
                validate={{ error: errors.vaccine.error, message: errors.vaccine.message }}
              />
              <FormInput
                name="lotNumber"
                label="Lot Number"
                type="text"
                value={lotNumber}
                onChange={handleChange}
                onBlur={validateErrors}
                validate={{ error: errors.lotNumber.error, message: errors.lotNumber.message }}
              />
              <DatePicker label="Exp Date" value={dateExp} setValue={setDateExp} onBlur={validateErrors} validate={{ error: errors.dateExp.error, message: errors.dateExp.message }} />
              <FormInput name="ndc" label="NDC" type="number" value={ndc} onChange={handleChange} onBlur={validateErrors} validate={{ error: errors.ndc.error, message: errors.ndc.message }} />
              <DatePicker
                label="Date administered "
                value={dateAdministered}
                setValue={setDateAdministered}
                onBlur={validateErrors}
                validate={{ error: errors.dateAdministered.error, message: errors.dateAdministered.message }}
              />
              <FormInput name="note" label="Note" type="text" value={note} onChange={handleChange} />
              <FormControl required error={errors.confirmCheck.error} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={confirmCheck} onChange={handleChange} name="confirmCheck" />}
                    label={<span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[6px]">I confirm above data to be accurate.</span>}
                    style={{ border: "none", padding: 0 }}
                  />
                </FormGroup>
                <FormHelperText>{errors.confirmCheck.message}</FormHelperText>
              </FormControl>
              {/* <FormControlLabel
                sx={{ border: "none" }}
                control={<Checkbox color="success" name="confirm" value={confirm} onChange={handleConfirm} required />}
                label={<span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">I confirm above data to be accurate.</span>}
              /> */}
            </div>
          </div>
          <div className="flex flex-col gap-[20px] mt-[10px] w-full">
            <VAButton text="Verify" dark onClick={onClickVerifyConfirm} />
            <VAButton text="Cancel" onClick={handleClose} outlined />
          </div>
        </div>
        {alertMessage !== null && <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />}
      </Box>
    </Modal>
  );
};
