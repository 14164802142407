import { ReactElement } from 'react';

export const AddIcon = (): ReactElement => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.3" cx="18" cy="18" r="18" fill="#0F5175" />
      <path
        d="M11.125 18H24.875"
        stroke="#114059"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 11.125V24.875"
        stroke="#114059"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
