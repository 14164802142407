import { ProviderInfoModel } from "../models/Provider";


export const SIGN_UP = "SIGN_UP";

export interface SignupAction {
  type: typeof SIGN_UP;
  payload: ProviderInfoModel;
}


export type SignupActionTypes = SignupAction;
