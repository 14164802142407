import { ReactElement } from 'react';

export const TextIcon = ({ number }: { number: string }): ReactElement => {
  return (
    <div className="relative">
      <span className="text-[#fff] text-[22px] font-[600] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        {number}
      </span>
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="21" cy="21" r="21" fill="#0F5175" />
      </svg>
    </div>
  );
};
