import { AppActions } from "../../../types";
import { GET_PROV_VACCINES, GET_PROV_TESTS } from "../../../types/actions/Provider/vaccineAndPaperwork.action";

const initialState: {
  provVaccines: any[];
  provTests: any[];
} = {
  provVaccines: [],
  provTests: [],
};
const VaccineAndPaperwork = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_PROV_VACCINES:
      return {
        ...state,
        provVaccines: action.payload,
      };
    case GET_PROV_TESTS:
      return {
        ...state,
        provTests: action.payload,
      };
    default:
      return state;
  }
};
export default VaccineAndPaperwork;
