import jwtAxios from "../../services/auth/jwt-auth";
import { LoginModel } from "../../types/models/Login";

export const onSubmitLogin = async (loginData: LoginModel) => {
  try {
    const res = await jwtAxios.post("/login", { loginData });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
