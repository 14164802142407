import { ReactElement } from 'react';

export const CrossIcon = (): ReactElement => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1248 4.70337L11.0007 9.82754L5.87648 4.70337L4.58398 5.99587L9.70815 11.12L4.58398 16.2442L5.87648 17.5367L11.0007 12.4125L16.1248 17.5367L17.4173 16.2442L12.2932 11.12L17.4173 5.99587L16.1248 4.70337Z"
        fill="white"
      />
    </svg>
  );
};
