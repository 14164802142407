import React, { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { styled } from "@mui/material/styles";
import { Stack, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { VAButton, VerifyScheduleAppointment, VerifyTestSA } from "../../../../components";
import { verifyTestAppointment, verifyVaccineAppointment } from "../../../../redux/actionCreators";
import { AppState } from "../../../../redux/store";
import { getFormattedDate } from "../../../../shared/Util";
import { ScheduleAppointmentModel } from "../../../../types/models/Provider/ScheduleAppointment";
import VAAlert from "../../../../components/Alert/Alert";
import { AlertType } from "../../../../shared/constants/AppConst";
dayjs.extend(utc);
const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));

interface TableItemProps {
  data: ScheduleAppointmentModel;
  pendingVaccineAppointments: ScheduleAppointmentModel[];
  setPendingVaccineAppointments: React.Dispatch<React.SetStateAction<ScheduleAppointmentModel[]>>;
}

const TableItem: React.FC<TableItemProps> = ({ data, setPendingVaccineAppointments, pendingVaccineAppointments }) => {
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  /** START VERIFY DIALOG STATES */
  const [openVerify, setOpenVerify] = useState(false);
  /** END VERIFY DIALOG STATES */
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);

  const onClickVerify = async (verifyformData: any) => {
    try {
      let reqData = {
        biz_branch_id: userData.selected_branch.biz_branch_id,
        schedule_appointment_id: data.schedule_appointment_id,
        patient_id: data.patient_id,
        verify_by: userData.user_id,
        ...verifyformData,
      };
      let res = data.vaccine_type === "TEST" ? await verifyTestAppointment(reqData) : await verifyVaccineAppointment(reqData);
      setPendingVaccineAppointments(res.data);
      setAlertMessage({ type: "success", description: res.message, open: true });
      setOpenVerify(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleOpen = () => {
    setOpenVerify(true);
  };
  const actionCell = () => {
    return (
      <Stack direction="row" alignItems="start" justifyContent={"center"} spacing={2}>
        <VAButton size="small" dark text="Verify" onClick={handleOpen} />
      </Stack>
    );
  };
  const formatedDate = `${getFormattedDate(data.date)}, ${data.slot}`;
  return (
    <React.Fragment>
      <TableRow key={data.schedule_appointment_id} className="item-hover">
        <StyledTableCell align="left">{data.patient_name}</StyledTableCell>
        <StyledTableCell align="left" sx={{ color: "#FF9800" }}>
          {data.vaccine_name}
        </StyledTableCell>
        <StyledTableCell align="left">{formatedDate}</StyledTableCell>
        <StyledTableCell align="left">{data.status}</StyledTableCell>
        <StyledTableCell align="center">{actionCell()}</StyledTableCell>
      </TableRow>
      {openVerify && data.vaccine_type === "VACCINE" && (
        <VerifyScheduleAppointment
          open={openVerify}
          setOpen={setOpenVerify}
          patient_name={data.patient_name}
          prov_vaccine_id={data.prov_vaccine_id}
          biz_branch_id={data.biz_branch_id}
          vaccine_type={data.vaccine_type}
          scheduled_mfg_id={data.mfg_id}
          onClickVerify={onClickVerify}
        />
      )}
      {openVerify && data.vaccine_type === "TEST" && (
        <VerifyTestSA
          open={openVerify}
          setOpen={setOpenVerify}
          patient_name={data.patient_name}
          prov_vaccine_id={data.prov_vaccine_id}
          biz_branch_id={data.biz_branch_id}
          vaccine_type={data.vaccine_type}
          scheduled_mfg_id={data.mfg_id}
          onClickVerify={onClickVerify}
        />
      )}
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </React.Fragment>
  );
};

export default TableItem;
