import React, { ReactElement } from "react";
import { VAButton, DashboardCard } from "../../../components";
import { FaTimes } from "react-icons/fa";

interface VaccineCardsProps {
  selectedVaccine: any;
  setOpen: any;
  setModalVaccineId: any;
  age_less: any;
  age_greater: any;
  page: any;
  setRemoveModalOpen: any;
  setScreeningId: any;
  handleCancel: any;
  setVISModalOpen?: any;
}

export const VaccineCards = ({
  selectedVaccine,
  setOpen,
  setModalVaccineId,
  age_less,
  age_greater,
  page,
  setRemoveModalOpen,
  setScreeningId,
  handleCancel,
  setVISModalOpen,
}: VaccineCardsProps): ReactElement => {
  return (
    <React.Fragment>
      {selectedVaccine.map((vaccine: any) => {
        return (
          <div className="flex flex-col gap-[20px]" style={{ marginTop: "20px" }}>
            <DashboardCard>
              <div className="w-[full] flex gap-[12px] justify-between items-center bg-[#b7cbd6] border-b-[1px] border-[ #0F5175] px-[20px] py-[12px] rounded-t-[12px]">
                {page === "add vaccine" ? (
                  <>
                    <h2 className="text-[#114059] text-[18px] font-[600]">
                      {vaccine.vaccine_name}
                      <span className="text-[16px] font-[400] text-[#1F2124]">
                        (Age group : Current age is {age_greater} years or older and {age_less} years or younger)
                      </span>
                    </h2>
                  </>
                ) : (
                  <React.Fragment>
                    <h2 className="text-[#114059] text-[18px] font-[600]">
                      {vaccine.vaccine_name}
                      <span className="text-[16px] font-[400] text-[#1F2124]">
                        (Age group : Current age is {vaccine.v_age_greater} years or older and {vaccine.v_age_less} years or younger)
                      </span>
                    </h2>
                    {/* delete btn here */}
                    <button className="btn btn-danger" style={{ padding: "10px", margin: "10px" }}>
                      <FaTimes
                        onClick={() => {
                          setModalVaccineId(vaccine.vaccine_id);
                          handleCancel();
                        }}
                      />
                    </button>
                  </React.Fragment>
                )}
              </div>

              <div className="bg-[#FFFFFF] p-[20px] rounded-b-[12px]">
                <div className="flex items-center justify-between border-b-[1px] border-[ #0F5175] pb-[9px]">
                  <h2 className="text-[20px] font-[600] text-[#000000]">Screening questions</h2>
                  {page === "add vaccine" ? (
                    <VAButton
                      outlined
                      text="View Forms"
                      onClick={() => {
                        setOpen(true);
                        setModalVaccineId(vaccine.vaccine_id);
                      }}
                    />
                  ) : (
                    <VAButton
                      outlined
                      text="View Additional"
                      onClick={() => {
                        setOpen(true);
                        setModalVaccineId(vaccine.vaccine_id);
                      }}
                    />
                  )}
                </div>
                {page === "add vaccine"
                  ? vaccine.screeningQuestions.map((item: any) =>
                      item.isChecked === true ? (
                        <div className="flex justify-between items-center border-b-[1px] border-[ #0F5175] ">
                          <p className="text-[16px] font-[400] text-[#1F2124] py-[12px]">{item.screening_name}</p>
                          <button
                            className="text-[#114059] text-[18px] font-[600] mt-[18px] cursor-pointer"
                            onClick={() => {
                              setRemoveModalOpen(true);
                              setModalVaccineId(vaccine.vaccine_id);
                              setScreeningId(item.screening_id);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      ) : null
                    )
                  : vaccine.screening.map((item: any) => {
                      return item.isChecked === true ? (
                        <div className="flex justify-between items-center border-b-[1px] border-[ #0F5175] ">
                          <p className="text-[16px] font-[400] text-[#1F2124] py-[12px]">{item.screening_name}</p>
                          <button
                            className="text-[#114059] text-[18px] font-[600] mt-[18px] cursor-pointer"
                            onClick={() => {
                              setRemoveModalOpen(true);
                              setModalVaccineId(vaccine.vaccine_id);
                              setScreeningId(item.screening_id);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      ) : null;
                    })}

                <div className="flex items-center justify-between border-b-[1px] border-[ #0F5175] py-[18px]">
                  <h2 className="text-[20px] font-[600] text-[#000000]">Consent Form</h2>
                </div>
                <div className="flex justify-between items-center border-b-[1px] border-[ #0F5175] ">
                  <p className="text-[16px] font-[400] text-[#1F2124] py-[12px]">{vaccine.vc_title}</p>
                </div>
                <h2 className="text-[20px] font-[600] text-[#000000] border-b-[1px] border-[ #0F5175] py-[18px]">Vaccine Information Statement (VIS)</h2>
                <div className="flex justify-between items-center border-b-[1px] border-[ #0F5175]">
                  <p className="text-[16px] font-[400] text-[#1F2124] py-[12px]">{vaccine.vis_title}</p>
                  <button
                    className="text-[#114059] text-[18px] font-[600] mt-[18px] cursor-pointer"
                    onClick={() => {
                      setVISModalOpen(true);
                      setModalVaccineId(vaccine.vaccine_id);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </DashboardCard>
          </div>
        );
      })}
    </React.Fragment>
  );
};
