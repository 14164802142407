import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DashboardLayout, DashboardCard } from "../../../components";
import { Back } from "../../../icons";
import { AppState } from "../../../redux/store";
import { Link } from "@mui/material";

export const ViewProfile = (): ReactElement => {
  const navigate = useNavigate();

  const { provVaccines }: any = useSelector<AppState, AppState["VaccineAndPaperwork"]>(({ VaccineAndPaperwork }) => VaccineAndPaperwork);

  return (
    <>
      <DashboardLayout>
        <div>
          <div className="flex items-center gap-[5px] cursor-pointer">
            <Back />
            <Link onClick={() => navigate(-1)} className="text-[16px] font-[600] text-[#888A8C]" underline="none">
              Back
            </Link>
          </div>
          <div className="mt-[14px]">
            <h2 className="text-[24px] text-primary-main font-[600]">{provVaccines[0].category}</h2>
            <h5 style={{ color: "gray" }}>Notes:</h5>
            <p>{provVaccines[0].categorynotes}</p>
          </div>
          <div className="mt-[24px] grid grid-cols-[10%_auto] items-center gap-[20px]">
            <h2 className="text-[18px] text-[#114059] font-[600]">Vaccines</h2>
          </div>
          <div className="mt-[12px] ">
            {provVaccines.map((vaccine: any) => (
              <div className="flex flex-col gap-[20px]" style={{ marginTop: "20px" }}>
                <DashboardCard>
                  <div className="w-[full] flex gap-[12px] justify-between items-center border-b-[1px] border-[ #0F5175] px-[20px] py-[12px] rounded-t-[12px]">
                    <h2 className="text-[#114059] text-[18px] font-[600]">{vaccine.vaccine_name}</h2>
                  </div>

                  <div className="bg-[#FFFFFF] p-[20px] rounded-b-[12px]">
                    <div className="w-[full] flex gap-[12px] justify-between items-center border-b-[1px] border-[ #0F5175]">
                      <h6 style={{ color: "gray" }}>Doses: {vaccine.no_of_doses}</h6>
                      <h6 style={{ color: "gray" }}>
                        In between time span: {vaccine.time_span_no}
                        {vaccine.time_span}
                      </h6>
                      <h6 style={{ color: "gray" }}>
                        (Age group : Current age is {vaccine.v_age_greater} years or older and {vaccine.v_age_less} years or younger))
                      </h6>
                    </div>
                    <div className="mt-[14px]">
                      <h5 style={{ color: "gray" }}>Notes:</h5>
                      <p>{provVaccines[0].vaccinenotes}</p>
                    </div>
                    <h5 style={{ color: "gray" }}>Forms:</h5>
                    <div className="w-[full] flex gap-[12px] justify-between items-center border-b-[1px] border-[ #0F5175]">
                      <div>
                        {vaccine.screening.map((question: any) => (
                          <h6 style={{ color: "blue" }}>
                            <u>{question.screening_name}</u>
                          </h6>
                        ))}
                      </div>
                      <div>
                        <h6 style={{ color: "blue" }}>
                          <u>{vaccine.vc_title.substring(0, vaccine.vc_title.indexOf("t ") + 1)}</u>
                        </h6>
                      </div>
                      <div>
                        <h6 style={{ color: "blue" }}>
                          <u>{vaccine.vis_title}</u>
                        </h6>
                      </div>
                    </div>
                  </div>
                </DashboardCard>
              </div>
            ))}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
