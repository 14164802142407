import { ReactElement } from "react";
import { CrossIcon, EditPenIcon } from "../../icons";

interface TagsProps {
  text?: string;
  item?: any;
  onClickEdit: (text: string, item: any) => void;
  onClickCancel: (text: string, item: any) => void;
}

export const CustomTags = ({ text, item, onClickEdit, onClickCancel }: TagsProps): ReactElement => {
  return (
    <div className="">
      <div className="flex items-center p-[9px] bg-[#B7CBD6] rounded-[16px] gap-[10px]  w-fit">
        <div className="text-[#114059] text-[16px] font-[400]">{text}</div>
        <div className="flex items-center px-[15px] py-[5px] gap-[6px] rounded-[12px] bg-[#114059]">
          <span className="cursor-pointer" onClick={() => onClickEdit(text ? text : "", item)}>
            <EditPenIcon />
          </span>
          <span className="cursor-pointer" onClick={() => onClickCancel(text ? text : "", item)}>
            <CrossIcon />
          </span>
        </div>
      </div>
    </div>
  );
};
