import { Dialog, DialogContent, DialogProps } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useDate from "../../hooks/useDate";
import { updateVaccineSchedules } from "../../redux/actionCreators";
import { AppState } from "../../redux/store";
import { VAButton } from "../VAButton/VAButton.component";
import { FromSelect } from "../FormFields";
export const EditScheduleDialog = ({
  day,
  startTimeSlots,
  endTimeSlots,
  setEndTimeSlots,
  timeDurationSlots,
  handleStartTime,
  starttime,
  setStartTime,
  endtime,
  setEndTime,
  timeduration,
  setTimeDuration,
  timeSlots,
  setTimeSlots,
  openEditScheduleModal,
  handleEditScheduleModal,
  selectedScheduleDate,
  setSelectedMonthEvents,
}: any) => {
  const date = useDate();
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  const [timeSlotsError, setTimeSlotsError] = useState(false);

  const onClickAddTimeSlot = () => {
    if (starttime === "" || endtime === "" || timeduration === "") {
      setTimeSlotsError(true);
    } else {
      setTimeSlotsError(false);
      // if the time slot is already present in the time slots array, then don't add it
      const isTimeSlotPresent = timeSlots.find((item: any) => item.starttime === starttime && item.endtime === endtime && item.timeduration === parseInt(timeduration));

      if (!isTimeSlotPresent) {
        const timeSlotsToDisplay: { starttime: any; endtime: any; timeduration: any; date: any }[] = [];
        // Add the selected time slots to the previous time slots
        timeSlots.forEach((item: any) => {
          timeSlotsToDisplay.push(item);
        });
        // add the new time slot

        timeSlotsToDisplay.push({ starttime, endtime, timeduration, date: selectedScheduleDate });
        setTimeSlots(timeSlotsToDisplay);
      }
      // reset the start time and end time
      setStartTime("");
      setEndTime("");
      setTimeDuration("");
      // set endTimeSlots  empty array of type this type { value: string; label: string }
      setEndTimeSlots([]);
    }
  };

  const handleEndTime = (e: any) => {
    setEndTime(e.target.value);
  };

  const handleTimeDuration = (e: any) => {
    setTimeDuration(e.target.value);
  };

  const handleEditTimeSlot = (index: number) => {
    // remove the time slot from the time slots array
    const timeSlotsToDisplay = [...timeSlots];
    const timeSlotToEdit = timeSlotsToDisplay.splice(index, 1);
    // set the start time, end time and time duration to the selected time slot

    setStartTime(timeSlotToEdit[0].starttime);
    // setEndTimeSlots after the selected start time
    const startTimeIndex = startTimeSlots.findIndex((item: any) => item.value === timeSlotToEdit[0].starttime);
    const endTimeSlots = [];
    for (let i = startTimeIndex + 1; i < startTimeSlots.length; i++) {
      endTimeSlots.push(startTimeSlots[i]);
    }
    setEndTimeSlots(endTimeSlots);

    setEndTime(timeSlotToEdit[0].endtime);
    setTimeDuration(timeSlotToEdit[0].timeduration);
    setTimeSlots(timeSlotsToDisplay);
  };

  const handleDeleteTimeSlot = (index: number) => {
    const timeSlotsToDisplay = [...timeSlots];
    timeSlotsToDisplay.splice(index, 1);
    setTimeSlots(timeSlotsToDisplay);
  };

  const handleSaveSchedule = async () => {
    try {
      // save the time slots to the database  and close the modal and update the calendar events
      let data = {
        biz_branch_id: userData.selected_branch.biz_branch_id,
        timeSlots,
        date: selectedScheduleDate,
      };
      let res = await updateVaccineSchedules(data);
      setSelectedMonthEvents(res.data);
      handleEditScheduleModal();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={openEditScheduleModal} onClose={handleEditScheduleModal} aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <div className="p-[20px] bg-[white]  w-[300px] md:w-[550px]  shadow-[0px_4px_10px_rgba(0,0,0,0.2)] rounded-[8px]">
          <div className=" flex gap-[5px] text-[#1F2124] text-[15px] md:text-[18px] md:flex md:justify-center font-medium">
            <p>Edit schedule for</p>
            <p>{date.month}</p>
            <p>{day},</p>
            <p>{date.year}</p>
          </div>
          <div className="flex flex-col items-center md:flex-row gap-[12px] w-full mt-[20px] md:items-end">
            <FromSelect name="starttime" options={startTimeSlots} value={starttime} heading="Start Time" onChange={handleStartTime} />
            <FromSelect name="endtime" options={endTimeSlots} value={endtime} heading="End Time" onChange={handleEndTime} />
            <FromSelect name="timeduration" options={timeDurationSlots} value={timeduration} heading="Slot Duration" onChange={handleTimeDuration} />
            <VAButton text="Add" onClick={onClickAddTimeSlot} outlined />
          </div>
          {timeSlotsError && <p className="text-[#FF0000] text-[12px] mt-[5px]">Please select all the fields</p>}
          <div className="flex flex-col gap-[12px] mt-[20px]">
            {timeSlots.map((item: any, index: number) => (
              <div className=" grid grid-cols-5 items-center border border-[#CDCFD2] py-[4px] mt-[12px] text-[#1F2124] text-[16px] font-medium text-center ">
                <p>{item.starttime}</p>
                <p>{item.endtime}</p>
                <p>{item.timeduration + "m"}</p>
                <span className="text-[#114059] text-[16px] font-[600] cursor-pointer" onClick={() => handleDeleteTimeSlot(index)}>
                  Delete
                </span>
                <span className="text-[#114059] text-[16px] font-[600] cursor-pointer" onClick={() => handleEditTimeSlot(index)}>
                  Edit
                </span>
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-center gap-[12px] mt-[20px]">
            <VAButton text="Save schedule" onClick={handleSaveSchedule} dark />
            <VAButton text="Cancel" onClick={handleEditScheduleModal} outlined />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
