import axios from "axios";

const jwtAxios = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: { "Content-Type": "application/json" } });

export const setAuthToken = (token?: string) => {
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem("token");
  }
};

export default jwtAxios;
