import { Box, Modal } from "@mui/material";
import { ReactElement } from "react";
import { VAButton } from "../VAButton/VAButton.component";

interface ModalProps {
  removeModalOpen: boolean;
  setRemoveModalOpen: (a: boolean) => void;
  handleRemove: any;
  selectedVaccine: any;
  selectedScreeningId: any;
  modalVaccineId: any;
  page: any;
}

const style = {
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  marginTop: "10px",
};

export const RemoveModal = ({ removeModalOpen = false, setRemoveModalOpen, handleRemove, selectedVaccine, selectedScreeningId, modalVaccineId, page }: ModalProps): ReactElement => {
  let vaccine_data: any;
  if (page === "vaccine and paperwork" || "Test and paperwork") {
    vaccine_data = selectedVaccine[0]?.screening.filter((x: any) => x.screening_id === selectedScreeningId);
  } else {
    vaccine_data = selectedVaccine[0]?.screeningQuestions.filter((x: any) => x.screening_id === selectedScreeningId);
  }
  // const provVacScreenId =vaccine_data[0].prov_vaccine_screening_id;
  console.log("remModalVacselectedScreeningId", selectedScreeningId);
  console.log("remModalVacvaccine_data", vaccine_data);

  return (
    <div>
      <>
        {vaccine_data ? (
          // vaccine_data.map((item:any)=>{
          <Modal
            open={removeModalOpen}
            style={{
              display: "flex",
              alignItems: "center",
              overflowY: "scroll",
              justifyContent: "center",
              margin: "50px",
            }}
            onClose={() => setRemoveModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="">
                <div className="flex justify-center">
                  <h2 className="text-[16px] md:text-[20px] text-[#000000] font-[600]" style={{ textAlign: "center" }}>
                    Remove Screening Question Document
                  </h2>
                </div>
                <div className="mt-[24px]">
                  {page === "vaccine and paperwork" || "Test and paperwork" ? (
                    <h2 className="text-[16px] md:text-[15px] text-[#000000] font-[600] flex justify-center" style={{ textAlign: "center" }}>
                      Are you sure you want to remove {vaccine_data[0].screening_name} screening form
                    </h2>
                  ) : (
                    <h2 className="text-[16px] md:text-[15px] text-[#000000] font-[600] flex justify-center" style={{ textAlign: "center" }}>
                      Are you sure you want to remove this screening form
                    </h2>
                  )}
                </div>
                <div className="mt-[10px] flex justify-end cursor-pointer justify-center" style={{ width: "100%" }}>
                  {page === "vaccine and paperwork" || "Test and paperwork" ? (
                    <VAButton onClick={() => handleRemove(vaccine_data[0].prov_vaccine_screening_id)} outlined text="Yes, Remove" />
                  ) : (
                    <VAButton onClick={() => handleRemove(modalVaccineId, selectedScreeningId)} outlined text="Yes, Remove" />
                  )}
                </div>
                <div
                  className="mt-[10px] flex justify-end cursor-pointer justify-center"
                  onClick={() => {
                    setRemoveModalOpen(false);
                  }}
                  style={{ width: "100%" }}
                >
                  <VAButton outlined text="No, Not Now" />
                </div>
              </div>
            </Box>
          </Modal>
        ) : // })
        null}
      </>
    </div>
  );
};
