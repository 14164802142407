import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DashboardLayout, FormInput, FromSelect, RemoveModal, ScreeningModal, VAButton } from "../../../components";
import VAAlert from "../../../components/Alert/Alert";
import { deleteProviderVaccine, deleteProviderVaccineScreeningItem, getProviderVaccineData, getVIS, uploadVISDocument } from "../../../redux/actionCreators";
import { AppState } from "../../../redux/store";
import { VaccineCards } from "../../../sections/Provider/AddVaccine";
import { AlertType } from "../../../shared/constants/AppConst";
import { GET_PROV_VACCINES } from "../../../types/actions/Provider/vaccineAndPaperwork.action";
import { Box, Modal } from "@mui/material";

interface UploadVISDialogProps {
  visModalOpen: boolean;
  setVISModalOpen: any;
  visDocuments: any;
  errorsVIS: any;
  validateErrorVIS: any;
  selectedVISDocument: any;
  handleChange: any;
  onClickUpdateVIS: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};

// const UploadVISDialog = ({ visModalOpen, setVISModalOpen, fileData, errorsVIS, validateErrorVIS, handleChangeVIS, visDocName, handleChange, onClickUpdateVIS }: UploadVISDialogProps) => {
//   return (
//     <Modal open={visModalOpen} onClose={() => setVISModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
//       <Box sx={style}>
//         <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
//           <div className="max-w-[360px] justify-center items-center flex flex-col gap-[22px] mt-[17px]">
//             <h2 className="text-[20px] font-[600] text-[#0F5175] max-w-[300px]">VIS Information</h2>
//           </div>
//           <div className="mt-[30px]">
//             <div className="grid grid-cols-[1fr_1fr] items-center gap-[12px]">
//               <FormUploadFile
//                 name="uploadVIS"
//                 label="Upload vis document"
//                 value={fileData.fileName}
//                 validate={{ error: errorsVIS.fileData.error, message: errorsVIS.fileData.message }}
//                 onBlur={validateErrorVIS}
//                 onChange={handleChangeVIS}
//                 required
//               />
//               <FormInput
//                 name="visDocName"
//                 label="Name the document"
//                 value={visDocName}
//                 validate={{ error: errorsVIS.visDocName.error, message: errorsVIS.visDocName.message }}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>
//           <div className="flex flex-col gap-[20px] mt-[28px] w-full">
//             <VAButton
//               text="Update"
//               dark
//               onClick={() => {
//                 onClickUpdateVIS();
//               }}
//             />
//             <VAButton text="Close" onClick={() => setVISModalOpen(false)} outlined />
//           </div>
//         </div>
//       </Box>
//     </Modal>
//   );
// };

const UploadVISDialog = ({ visModalOpen, setVISModalOpen, visDocuments, errorsVIS, validateErrorVIS, selectedVISDocument, handleChange, onClickUpdateVIS }: UploadVISDialogProps) => {
  return (
    <Modal open={visModalOpen} onClose={() => setVISModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
          <div className="max-w-[360px] justify-center items-center flex flex-col gap-[22px] mt-[17px]">
            <h2 className="text-[20px] font-[600] text-[#0F5175] max-w-[300px]">SELECT VIS Document</h2>
          </div>
          <div className="flex flex-col gap-[20px] mt-[30px] w-full">
            <FromSelect
              name="selectedVISDocument"
              label="Select VIS"
              options={visDocuments}
              value={selectedVISDocument}
              onChange={handleChange}
              onBlur={validateErrorVIS}
              validate={{ error: errorsVIS.selectedVISDocument.error, message: errorsVIS.selectedVISDocument.message }}
            />
          </div>

          <div className="flex flex-col gap-[20px] mt-[28px] w-full">
            <VAButton
              text="Update"
              dark
              onClick={() => {
                onClickUpdateVIS();
              }}
            />
            <VAButton text="Close" onClick={() => setVISModalOpen(false)} outlined />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export const VaccinesAndPaperwork = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [selectedVaccine, setselectedVaccine] = useState<any>([]);
  const page = "vaccine and paperwork";
  const [modalVaccineId, setModalVaccineId] = useState<any>([]);
  const [selectedScreeningId, setScreeningId] = useState<any>(0);
  const [cat, setCat] = useState<any>("");
  const [searchVaccine, setSearchVaccine] = useState("");
  const [visModalOpen, setVISModalOpen] = useState(false);
  const [selectedVISDocument, setSelectedVISDocument] = useState("");
  const [visDocuments, setVISDocuments] = useState<{ value: string; label: string }[]>([]);
  const [errorsVIS, setErrorsVIS] = useState({ selectedVISDocument: { error: false, message: "" } });
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);

  const handleChange = (event: any) => {
    if (event.target.name === "vaccine") {
      setSearchVaccine(event.target.value as string);
    }
    if (event.target.name === "selectedVISDocument") {
      setSelectedVISDocument(event.target.value as string);
    }
  };

  const handleCancel = async () => {
    const selectedVac = selectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId);
    console.log("selectedVac", selectedVac);
    let data = {
      biz_branch_id: userData.selected_branch.biz_branch_id,
      prov_vaccine_id: selectedVac[0].prov_vaccine_id, // vaccine id se provVaccine_id get krni h
    };
    let res = await deleteProviderVaccine(data);
    if (res.message === "success") setAlertMessage({ type: "success", description: "vaccine deleted successfully", open: true });
    getProviderVaccienData();
  };

  const handleRemove = async (prov_vaccine_screening_id: any) => {
    let data = {
      biz_branch_id: userData.selected_branch.biz_branch_id,
      prov_vaccine_screening_id: prov_vaccine_screening_id,
    };
    await deleteProviderVaccineScreeningItem(data);
    getProviderVaccienData();
    setRemoveModalOpen(false);
  };

  const getProviderVaccienData = async () => {
    try {
      let res = await getProviderVaccineData({ biz_branch_id: userData.selected_branch.biz_branch_id });
      setselectedVaccine(res.data);
      setCat(res.data[0].category);
      dispatch({ type: GET_PROV_VACCINES, payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };
  // START VIS methods
  useEffect(() => {
    if (visModalOpen) {
      const getVISData = async () => {
        try {
          const selectedVac = selectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId);
          let res = await getVIS({ prov_vaccine_id: selectedVac[0].prov_vaccine_id, biz_branch_id: selectedVac[0].biz_branch_id });
          let VISDocuments = [];
          for (let i = 0; i < res.data.length; i++) {
            const element = res.data[i];
            VISDocuments.push({ value: element.id, label: element.name });
          }
          setVISDocuments(VISDocuments);
          setSelectedVISDocument(res.selected_sa_vis_id === 0 ? res.selected_sa_vis_id : "");
        } catch (err) {
          console.log(err);
        }
      };
      getVISData();
    }
  }, [visModalOpen, selectedVaccine, modalVaccineId]);
  const validateErrorVIS = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsVIS({ ...errorsVIS, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsVIS({ ...errorsVIS, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormVIS = () => {
    let formIsValid = true;
    let localErrorsVIS = errorsVIS;

    if (selectedVISDocument === "") {
      formIsValid = false;
      localErrorsVIS.selectedVISDocument = { error: true, message: "Required Field!" };
    }
    setErrorsVIS({ ...errorsVIS, ...localErrorsVIS });
    return formIsValid;
  };
  // const handleChangeVIS = (e: any) => {
  //   if (e.target.files.length === 0) return;

  //   const files = e.target.files;
  //   const fileName = e.target.files[0].name;

  //   // Separate file name and extension
  //   const lastDotIndex = fileName.lastIndexOf(".");
  //   const nameWithoutExtension = lastDotIndex !== -1 ? fileName.substring(0, lastDotIndex) : fileName;
  //   const extension = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1) : "";
  //   // Capitalize the first letter of nameWithoutExtension if it contains alphabetical characters
  //   const capitalizedFileName = /[a-zA-Z]/.test(nameWithoutExtension) ? nameWithoutExtension.charAt(0).toUpperCase() + nameWithoutExtension.slice(1) : nameWithoutExtension;
  //   // Check if the file is a PDF
  //   if (extension !== "pdf") {
  //     alert("Please select a PDF file.");
  //     e.target.value = ""; // Clear the selected file to allow selecting a PDF again
  //     return;
  //   }

  //   const fileReader = new FileReader();

  //   if (fileReader && files && files.length) {
  //     fileReader.readAsArrayBuffer(e.target.files[0]);

  //     fileReader.onload = async () => {
  //       const uploadFile = window.confirm("Do you want to upload " + fileName + "? OK=yes, Cancel=no");
  //       if (uploadFile) {
  //         const _fileData = { ArrayBufferData: fileReader.result, fileName };
  //         setFileData(_fileData);
  //         setVISDocName(capitalizedFileName);
  //       }
  //     };

  //     fileReader.onerror = () => {
  //       // Handle errors more gracefully, e.g., display an error message on the UI
  //       console.error("Error reading the file:", fileReader.error);
  //     };
  //   } else {
  //     // Handle unknown errors more gracefully, e.g., display an error message on the UI
  //     console.error("Unknown error in: handleChangeVIS");
  //   }
  // };

  // const onClickUpdateVIS = async () => {
  //   try {
  //     //To prevent to move further in case of empty fields
  //     if (!validateFormVIS()) return;
  //     const selectedVac = selectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId);
  //     let res = await uploadVISDocument({
  //       prov_vaccine_id: selectedVac[0].prov_vaccine_id,
  //       biz_branch_id: selectedVac[0].biz_branch_id,
  //       fileData: Array.from(new Uint8Array(fileData.ArrayBufferData as ArrayBuffer)), // Convert ArrayBuffer to a regular array
  //       fileName: visDocName + ".pdf",
  //     });
  //     await getProviderVaccienData();
  //     setFileData({ ArrayBufferData: null, fileName: "" });
  //     setVISDocName("");
  //     setErrorsVIS({ fileData: { error: false, message: "" }, visDocName: { error: false, message: "" } });
  //     setVISModalOpen(false);
  //     setAlertMessage({ type: "success", description: res.message, open: true });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const onClickUpdateVIS = async () => {
    try {
      //To prevent to move further in case of empty fields
      if (!validateFormVIS()) return;
      const selectedVac = selectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId);
      let res = await uploadVISDocument({
        prov_vaccine_id: selectedVac[0].prov_vaccine_id,
        biz_branch_id: selectedVac[0].biz_branch_id,
        sa_vis_id: selectedVISDocument,
      });
      await getProviderVaccienData();
      setSelectedVISDocument("");
      setErrorsVIS({ selectedVISDocument: { error: false, message: "" } });
      setVISModalOpen(false);
      setAlertMessage({ type: "success", description: res.message, open: true });
    } catch (err) {
      console.error(err);
    }
  };
  // END VIS methods
  useEffect(() => {
    getProviderVaccienData();
  }, []);

  const filteredSelectedVaccine = selectedVaccine.filter((vaccine: any) => vaccine.vaccine_name.toLowerCase().includes(searchVaccine.toLowerCase()));

  return (
    <React.Fragment>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      <DashboardLayout>
        <div>
          <div>
            <h2 className="text-[24px] text-primary-main font-[600]">Vaccines and Paperwork</h2>
          </div>
          <div className="flex items-center justify-between py-[20px]">
            <FormInput label="Search for vaccine 🔍" name="vaccine" value={searchVaccine} onChange={handleChange} />
            <VAButton dark text="Add new Vaccine" onClick={() => navigate("/add-vaccine")} />
          </div>
          {cat !== undefined ? (
            <div className="w-[full] flex gap-[12px] justify-between items-center px-[20px] py-[12px] rounded-t-[12px] gap-[20px]">
              <h2 className="text-[#114059] text-[18px] font-[600]">{cat}</h2>
              <span className="border-t-[1px] border-primary-main"></span>
              <Link to="/view-profile" className="text-[#114059] text-[18px] font-[600] mt-[18px] cursor-pointer" style={{ float: "right" }}>
                View Profile
              </Link>
            </div>
          ) : null}
          <div className="mt-[12px] ">
            <VaccineCards
              selectedVaccine={filteredSelectedVaccine}
              setOpen={setOpen}
              setModalVaccineId={setModalVaccineId}
              page={page}
              age_less={undefined}
              age_greater={undefined}
              handleCancel={handleCancel}
              setRemoveModalOpen={setRemoveModalOpen}
              setScreeningId={setScreeningId}
              setVISModalOpen={setVISModalOpen}
            />
          </div>

          <ScreeningModal page={page} open={open} selectedVaccine={filteredSelectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId)} modalVaccineId={modalVaccineId} setOpen={setOpen} />
          {removeModalOpen ? (
            <RemoveModal
              page={page}
              removeModalOpen={removeModalOpen}
              setRemoveModalOpen={setRemoveModalOpen}
              handleRemove={handleRemove}
              selectedVaccine={filteredSelectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId)}
              selectedScreeningId={selectedScreeningId}
              modalVaccineId={modalVaccineId}
            />
          ) : null}
        </div>
        <UploadVISDialog
          visModalOpen={visModalOpen}
          setVISModalOpen={setVISModalOpen}
          visDocuments={visDocuments}
          selectedVISDocument={selectedVISDocument}
          errorsVIS={errorsVIS}
          validateErrorVIS={validateErrorVIS}
          handleChange={handleChange}
          onClickUpdateVIS={onClickUpdateVIS}
        />
      </DashboardLayout>
    </React.Fragment>
  );
};
