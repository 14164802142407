import jwtAxios from "../../../services/auth/jwt-auth";

export const getProviderVaccineData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/getprovidervaccines", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProviderTests = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/getprovidertests", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProviderVaccine = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/deleteprovidervaccine", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateScreeningList = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/updatescreeninglist", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProviderVaccineScreeningItem = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/deleteprovidervaccinescreeningitem", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVaccineScreeningData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/getvaccinescreeningdata", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVIS = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/getvis", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadVISDocument = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccineandpaperwork/uploadvisdocument", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
