import jwtAxios from "../../../services/auth/jwt-auth";

export const getMyProfoleInfo = async (data: any) => {
  try {
    const res = await jwtAxios.post("/myprofile/getmyprofileinfo", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBusinessInfo = async (data: any) => {
  try {
    const res = await jwtAxios.post("/myprofile/updateBusinessInfo", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePrimaryInfo = async (data: any) => {
  try {
    const res = await jwtAxios.post("/myprofile/updatePrimaryInfo", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
