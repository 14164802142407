import React, { ReactElement } from "react";
import "./VAButton.styles.scss";

interface ButtonProps {
  text?: string;
  light?: any;
  outlined?: any;
  disabled?: boolean;
  dark?: any;
  size?: "small" | "medium" | "large";
  onClick?: () => void;
  icon?: ReactElement; // Define the icon prop
}

export const VAButton = ({ text, light, outlined, dark, onClick, disabled, size, icon }: ButtonProps): ReactElement => {
  return (
    <div className={`${disabled ? "opacity-50 pointer-events-none" : ""}`}>
      <span
        onClick={!disabled ? onClick : undefined}
        className={`cursor-pointer flex items-center justify-center px-[45px] py-[14px] text-[16px] font-[600] block text-center rounded-[16px]
        ${light ? "btn-light" : ""}
        ${dark ? "btn-dark" : ""}
        ${outlined ? "bg-[#ffffff] text-primary-main border border-primary-main" : ""}
        ${size === "small" ? "text-sm px-[30px] py-[10px] rounded-[12px]" : ""}
        ${size === "medium" ? "text-base" : ""}
        ${size === "large" ? "text-lg px-[60px] py-[20px] rounded-[20px]" : ""}`}
        aria-disabled={disabled}
      >
        {/* {icon && React.cloneElement(icon, { style: { height: "0.8em" } })} */}
        {icon && <span className="mr-2">{icon}</span>}
        {text}
      </span>
    </div>
  );
};
