import { ReactElement, useState } from "react";
import { VAButton, FormInput } from "../../components";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { AuthenticateAccount } from "../../icons";
import { MuiOtpInput } from "mui-one-time-password-input";
import { ErrorsProviderInfoModel, ProviderInfoModel } from "../../types/models/Provider";
import { FormControl, FormControlLabel, FormGroup, FormHelperText } from "@mui/material";

interface providerInfoProps {
  inputs: ProviderInfoModel;
  errorsProviderInfo: ErrorsProviderInfoModel;
  openEmailModal: boolean;
  setOpenEmailModal: any;
  validateErrorProviderInfo: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onSubmitSignUp: any;
  onSubmitVerificationCode: any;
  onClickResendCode: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};

export const SignUpForm = ({
  inputs,
  errorsProviderInfo: errors,
  openEmailModal,
  setOpenEmailModal,
  validateErrorProviderInfo: validateError,
  onChange,
  onSubmitSignUp,
  onSubmitVerificationCode,
  onClickResendCode,
}: providerInfoProps): ReactElement => {
  const handleClose = () => setOpenEmailModal(false);
  const [value, setValue] = useState("");

  const handleChange = (newValue: any) => {
    setValue(newValue);
    inputs.verificationCode = newValue;
  };

  return (
    <>
      <div className="">
        <Modal open={openEmailModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
              <div>
                <AuthenticateAccount />
              </div>
              <div className="max-w-[360px] flex flex-col gap-[22px] mt-[17px]">
                <h2 className="text-[20px] font-[600] text-[#000000]">Authenticate your account</h2>
                <p className="text-[16px] font-[400] text-[#1F2124]">A 6 digit verification code has been sent on your email. This code will be valid for 15 min.</p>
              </div>
              <div className="mt-[28px]">
                <MuiOtpInput length={6} value={value} onChange={handleChange} />
              </div>
              <div className="flex flex-col gap-[20px] mt-[28px] w-full">
                <VAButton text="Verify" dark onClick={onSubmitVerificationCode} />
                <VAButton text="Resend Code" onClick={onClickResendCode} outlined />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="flex flex-col gap-[30px]">
        <FormInput
          name="firstName"
          label="Legal First Name"
          type="text"
          value={inputs.firstName}
          validate={{ error: errors.firstName.error, message: errors.firstName.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />

        <FormInput
          name="lastName"
          label="Legal Last Name"
          type="text"
          value={inputs.lastName}
          validate={{ error: errors.lastName.error, message: errors.lastName.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />

        <FormInput
          name="email"
          label="Email"
          type="email"
          value={inputs.email}
          validate={{ error: errors.email.error, message: errors.email.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />

        <FormInput
          name="password"
          label="Password"
          type="password"
          value={inputs.password}
          validate={{ error: errors.password.error, message: errors.password.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />

        <FormInput
          name="confirm_password"
          label="Confirm Password"
          type="password"
          value={inputs.confirm_password}
          validate={{ error: errors.confirm_password.error, message: errors.confirm_password.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />
        <div className="flex gap-[12px]">
          <FormControl required error={errors.conditionCheck.error} component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={inputs.conditionCheck} onChange={onChange} name="conditionCheck" />}
                label={<span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[20px]">By creating an account, you agree to All Terms & Conditions and Privacy Policy.</span>}
                style={{ border: "none", padding: 0 }}
              />
            </FormGroup>
            <FormHelperText>{errors.conditionCheck.message}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <VAButton text="Create Account" dark onClick={onSubmitSignUp} />
        </div>
      </div>
    </>
  );
};
