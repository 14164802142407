import { ReactElement } from 'react';

export const DoneIcon = (): ReactElement => {
  return (
    <div className="relative">
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_898_40180)">
          <path
            d="M21 41.9999C32.5979 41.9999 41.9999 32.5979 41.9999 21C41.9999 9.402 32.5979 0 21 0C9.402 0 0 9.402 0 21C0 32.5979 9.402 41.9999 21 41.9999Z"
            fill="#4CAF50"
          />
          <path
            d="M32.0631 9.87927L17.2176 24.7248L9.933 17.4405L6.23438 21.1385L17.2176 32.122L35.7617 13.5774L32.0631 9.87927Z"
            fill="#EBF0F3"
          />
        </g>
        <defs>
          <clipPath id="clip0_898_40180">
            <rect width="42" height="42" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
