import { Fragment, ReactElement } from "react";
import { FormInput, FormUploadFile, VAButton } from "../../../components";
import { Box } from "@mui/material";
interface VaccineDataImporterProps {
  visDocName: string;
  fileData: any;
  errorsVIS: any;
  selectedMenu: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateError: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleSubmit: () => void;
  onClickBack: () => void;
}
export const UploadVISSection = ({
  fileData,
  visDocName,
  errorsVIS,
  selectedMenu,
  handleChange,
  handleFileChange,
  validateError,
  handleSubmit,
  onClickBack,
}: VaccineDataImporterProps): ReactElement => {
  return (
    <Fragment>
      <div className="mt-[22px]">
        <div className="mt-[20px]">
          <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
            <FormUploadFile
              name="uploadVIS"
              label="Upload vis document"
              value={fileData.fileName}
              validate={{ error: errorsVIS.fileData.error, message: errorsVIS.fileData.message }}
              onBlur={validateError}
              onChange={handleFileChange}
              required
            />
            <FormInput
              name="visDocName"
              label="Document name"
              value={visDocName}
              validate={{ error: errorsVIS.visDocName.error, message: errorsVIS.visDocName.message }}
              onBlur={validateError}
              onChange={handleChange}
              required
            />
          </div>
          <Box mt={10} display="flex" flexDirection="row" alignItems="center" width={1} justifyContent="center">
            <Box sx={{ mr: 2 }}>
              <VAButton text="Back" light onClick={onClickBack} />
            </Box>
            <Box>
              <VAButton text={`${selectedMenu === "ADD" ? "Add" : "Update"}`} dark onClick={handleSubmit} />
            </Box>
          </Box>
        </div>
      </div>
    </Fragment>
  );
};
