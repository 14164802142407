import { ReactElement } from "react";
import { FormInput, NumberFormatField, FromSelect, FormUploadFile } from "../../../../components";
import { PrimaryUserModel, ErrorsPrimaryUserModel } from "../../../../types/models/Provider/createProfile/PrimaryUser";
interface PrimaryUserProps {
  providerRoles: any;
  primaryUserInputs: PrimaryUserModel;
  errorsPrimaryUser: ErrorsPrimaryUserModel;
  validateErrorPrimaryUser: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handlePrimaryUserChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const PrimaryUser = ({ providerRoles, primaryUserInputs, errorsPrimaryUser: errors, validateErrorPrimaryUser: validateError, handlePrimaryUserChange }: PrimaryUserProps): ReactElement => {
  return (
    <div className="mt-[22px]">
      <div className="text-[14px] font-[600] text-[#1F2124]">Primary User</div>
      <div className="mt-[20px]">
        <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
          <FormInput name="firstName" label="Legal First Name" type="text" value={primaryUserInputs.firstName} readOnly />
          <FormInput name="lastName" label="Legal Last Name" type="text" value={primaryUserInputs.lastName} readOnly />
        </div>
        <div className="mt-[20px]">
          <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px] ">
            <FormInput name="email" label="Email" value={primaryUserInputs.email} readOnly />
            <FromSelect
              name="role"
              label="Role"
              value={primaryUserInputs.role}
              validate={{ error: errors.role.error, message: errors.role.message }}
              required
              onChange={handlePrimaryUserChange}
              options={providerRoles}
              onBlur={validateError}
            />
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
            <NumberFormatField
              name="phone"
              label="Phone Number"
              value={primaryUserInputs.phone}
              validate={{ error: errors.phone.error, message: errors.phone.message }}
              required
              onChange={handlePrimaryUserChange}
              onBlur={validateError}
            />
            <FormUploadFile
              name="uploadStateLicenceOrURL"
              label="Upload State License or URL"
              value={primaryUserInputs.uploadStateLicenceOrURL}
              validate={{ error: errors.uploadStateLicenceOrURL.error, message: errors.uploadStateLicenceOrURL.message }}
              required
              onChange={handlePrimaryUserChange}
              onBlur={validateError}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
