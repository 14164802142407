import React from "react";
import { Table, TableHead, TableBody } from "@mui/material";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

interface BranchesTableProps {
  branches: any[];
  onClickEditLocation: any;
}

const BranchesTable: React.FC<BranchesTableProps> = ({ branches, onClickEditLocation }) => {
  return (
    <Table stickyHeader sx={{ minWidth: 650 }} size="small" className="table">
      <TableHead>
        <TableHeading />
      </TableHead>
      <TableBody>
        {branches.map((row: any, index: number) => (
          <TableItem row={row} key={row.id} onClickEditLocation={onClickEditLocation} />
        ))}
      </TableBody>
    </Table>
  );
};

export default BranchesTable;
