import React, { useEffect, useState } from "react";
import { DashboardLayout, FormInput, VAButton } from "../../../components";
import { addSuperAdminVIS, deleteSuperAdminVIS, editSuperAdminVIS, getSuperAdminVIS, updateSuperAdminVIS } from "../../../redux/actionCreators";
import { AlertType } from "../../../shared/constants/AppConst";
import VAAlert from "../../../components/Alert/Alert";
import { MENU } from "../../../types/actions/Common.action";
import UploadVISTable from "./UploadVISTable";
import { UploadVISSection } from "../../../sections/Superadmin";

interface FileDataModel {
  ArrayBufferData: string | ArrayBuffer | null;
  fileName: string;
}
// Usage example in a React component
export const UploadVIS: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState<MENU>("VIEW");
  const [searchVIS, setSearchVIS] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState<FileDataModel>({ ArrayBufferData: null, fileName: "" });
  const [visDocName, setVISDocName] = useState("");
  const [selected_sa_vis_id, setSelectedVISId] = useState("");
  const [visDocuments, setVISDocuments] = useState<any>([]);
  const [errorsVIS, setErrorsVIS] = useState({ fileData: { error: false, message: "" }, visDocName: { error: false, message: "" } });
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);

  const handleChange = (event: any) => {
    if (event.target.name === "visDocName") {
      setVISDocName(event.target.value as string);
    }
    if (event.target.name === "searchVIS") {
      setSearchVIS(event.target.value as string);
    }
  };

  const handleFileChange = (e: any) => {
    if (e.target.files.length === 0) return;

    const files = e.target.files;
    const fileName = e.target.files[0].name;

    // Separate file name and extension
    const lastDotIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = lastDotIndex !== -1 ? fileName.substring(0, lastDotIndex) : fileName;
    const extension = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1) : "";
    // Capitalize the first letter of nameWithoutExtension if it contains alphabetical characters
    const capitalizedFileName = /[a-zA-Z]/.test(nameWithoutExtension) ? nameWithoutExtension.charAt(0).toUpperCase() + nameWithoutExtension.slice(1) : nameWithoutExtension;
    // Check if the file is a PDF
    if (extension !== "pdf") {
      alert("Please select a PDF file.");
      e.target.value = ""; // Clear the selected file to allow selecting a PDF again
      return;
    }

    const fileReader = new FileReader();

    if (fileReader && files && files.length) {
      fileReader.readAsArrayBuffer(e.target.files[0]);

      fileReader.onload = async () => {
        const uploadFile = window.confirm("Do you want to upload " + fileName + "? OK=yes, Cancel=no");
        if (uploadFile) {
          const _fileData = { ArrayBufferData: fileReader.result, fileName };
          setFileData(_fileData);
          setVISDocName(capitalizedFileName);
        }
      };

      fileReader.onerror = () => {
        // Handle errors more gracefully, e.g., display an error message on the UI
        console.error("Error reading the file:", fileReader.error);
      };
    } else {
      // Handle unknown errors more gracefully, e.g., display an error message on the UI
      console.error("Unknown error in: handleChangeVIS");
    }
  };

  const validateError = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsVIS({ ...errorsVIS, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorsVIS({ ...errorsVIS, [e.target.name]: { error: false, message: "" } });
    }
  };

  const handleSubmit = async () => {
    try {
      let res = null;
      if (selectedMenu === "ADD") {
        res = await addSuperAdminVIS({
          fileData: { data: Array.from(new Uint8Array(fileData.ArrayBufferData as ArrayBuffer)), fileName: fileData.fileName }, // Convert ArrayBuffer to a regular array
          visDocName,
        });
      } else {
        res = await updateSuperAdminVIS({
          sa_vis_id: selected_sa_vis_id,
          visDocName,
          fileData: { data: fileData.ArrayBufferData === null ? [] : Array.from(new Uint8Array(fileData.ArrayBufferData as ArrayBuffer)), fileName: fileData.fileName },
        });
      }

      setAlertMessage({ type: "success", description: res.message, open: true });
      setVISDocuments(res.data);
      setSelectedMenu("VIEW");
    } catch (err) {
      setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
      console.log(err);
    }
  };
  const onClickAdd = () => {
    setSelectedMenu("ADD");
    setFileData({ ArrayBufferData: null, fileName: "" });
    setVISDocName("");
    setErrorsVIS({ fileData: { error: false, message: "" }, visDocName: { error: false, message: "" } });
  };

  const onClickEdit = async (sa_vis_id: any) => {
    try {
      let res = await editSuperAdminVIS({ sa_vis_id });
      setVISDocName(res.data.name);
      setSelectedVISId(res.data.id);
      setFileData({ ArrayBufferData: null, fileName: res.data.file_name });
      setSelectedMenu("EDIT");
    } catch (err) {
      console.log(err);
      setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
    }
  };

  const onClickDelete = async (sa_vis_id: any) => {
    if (window.confirm("Are you sure, you want to delete this record?")) {
      try {
        let res = await deleteSuperAdminVIS({ sa_vis_id });
        setAlertMessage({ type: "success", description: res.message, open: true });
        setVISDocuments(res.data);
        setSelectedMenu("VIEW");
      } catch (err) {
        setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
        console.log(err);
      }
    }
  };
  const onClickBack = () => {
    setSelectedMenu("VIEW");
  };
  useEffect(() => {
    const getSuperAdminVISData = async () => {
      try {
        let res = await getSuperAdminVIS();
        setVISDocuments(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getSuperAdminVISData();
  }, []);
  const filteredVISDocuments = visDocuments.filter((saVIS: any) => saVIS.name.toLowerCase().includes(searchVIS.toLowerCase()));
  const renderMenu = () => {
    switch (selectedMenu) {
      case "VIEW":
        return (
          <div>
            <div>
              <h2 className="text-[24px] text-primary-main font-[600]">VIS Documents</h2>
            </div>
            <div className="flex items-center justify-between py-[20px]">
              <FormInput label="Search by name 🔍" name="searchVIS" value={searchVIS} onChange={handleChange} />
              <VAButton dark text="Add New" onClick={onClickAdd} />
            </div>
            <div className="mt-[30px]">
              <UploadVISTable rows={filteredVISDocuments} onClickDelete={onClickDelete} onClickEdit={onClickEdit} />
            </div>
          </div>
        );
      case "ADD":
      case "EDIT":
        return (
          <React.Fragment>
            {/* {isLoading && <p>Loading...</p>} */}
            <div>
              <h2 className="text-[20px] font-[600] text-[#0F5175]">{"Add new VIS"}</h2>
              <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">Upload document</p>
            </div>
            <div className="mt-[30px]">
              <UploadVISSection
                visDocName={visDocName}
                fileData={fileData}
                errorsVIS={errorsVIS}
                selectedMenu={selectedMenu}
                handleChange={handleChange}
                handleFileChange={handleFileChange}
                validateError={validateError}
                handleSubmit={handleSubmit}
                onClickBack={onClickBack}
              />
            </div>
          </React.Fragment>
        );

      default:
        break;
    }
  };
  return (
    <DashboardLayout>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      {renderMenu()}
    </DashboardLayout>
  );
};
