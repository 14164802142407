import { ReactElement } from "react";
import { FormUploadFile, FromSelect, VAButton } from "../../../components";
interface VaccineDataImporterProps {
  vaccineCategories: any;
  selectedVaccineCategory: string;
  vaccineMfgs: any;
  selectedVaccineMfg: string;
  importVaccinefile: string | File | null;
  onChangeTextFields: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateError: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleSubmit: () => void;
}
export const VaccineDataImporterSection = ({
  vaccineCategories,
  selectedVaccineCategory,
  vaccineMfgs,
  selectedVaccineMfg,
  importVaccinefile,
  onChangeTextFields,
  handleFileChange,
  validateError,
  handleSubmit,
}: VaccineDataImporterProps): ReactElement => {
  return (
    <>
      <div className="mt-[22px]">
        <div className="mt-[20px]">
          <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
            <FromSelect name="selectedVaccineCategory" label="Vaccine Category" value={selectedVaccineCategory} options={vaccineCategories} onChange={onChangeTextFields} onBlur={validateError} />
            <FromSelect name="selectedVaccineMfg" label="Vaccine Manufacturer" value={selectedVaccineMfg} options={vaccineMfgs} onChange={onChangeTextFields} onBlur={validateError} />
            <FormUploadFile name="importVaccinefile" label="Upload Vaccine Data" value={importVaccinefile} onChange={handleFileChange} onBlur={validateError} />
            <div>
              <VAButton text="Add" dark onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
