import { Drawer } from "@mui/material";
import { ReactElement, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconMenu from "./MuiMenuList.component";
import { ProfileUser } from "./ProfileUser.component";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";

export const SidebarEl = (): ReactElement => {
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);

  return (
    <div className="h-[100vh] flex flex-col justify-between">
      <div>
        <div className="w-full p-[15px] pb-[0px]">
          <img src={`${process.env.PUBLIC_URL}/imgs/sider-text-image.svg`} alt="vaccine text" className=" w-[200px] " />
        </div>
        <IconMenu />
      </div>
      <div className="mb-[10px] ml-[24px]">
        <ProfileUser src={`${process.env.PUBLIC_URL}/imgs/profile-user.png`} name={userData.name} number="6" />
      </div>
    </div>
  );
};

export const Sidebar = (): ReactElement => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Drawer onClose={onClose} open={open} className="md:hidden">
        <div className="bg-primary-main h-[100vh] overflow-y-auto">
          <Sidebar />
        </div>
      </Drawer>
      <div className="block md:hidden absolute right-[10px] top-[10px]">
        <div onClick={showDrawer} className="cursor-pointer">
          <MenuIcon />
        </div>
      </div>
      <div className="hidden bg-primary-main md:block h-[100vh] overflow-y-auto">
        <SidebarEl />
      </div>
    </>
  );
};
