import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { checkEmail } from "../../redux/actionCreators/Signup";
import { SignUpForm } from "../../sections/SignUp";
import { ProviderInfoModel } from "../../types/models/Provider";
import { useFormFieldsHandleChange } from "../../utils";
import emailjs from "@emailjs/browser";
import { SIGN_UP } from "../../types/actions/Signup.actions";
import { AlertType } from "../../shared/constants/AppConst";
import VAAlert from "../../components/Alert/Alert";

export const SignUp = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verificationCode, setVCode] = useState(0);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);

  // Provider State and Handlers
  const [providerInfo] = useState<ProviderInfoModel>({ firstName: "", lastName: "", email: "", password: "", confirm_password: "", conditionCheck: false, verificationCode: 0 });
  const [providerInfoInputs, handleProviderInfoChange] = useFormFieldsHandleChange(providerInfo);
  const [errorsProviderInfo, setErrorsProviderInfo] = useState({
    firstName: { error: false, message: "" },
    lastName: { error: false, message: "" },
    email: { error: false, message: "" },
    password: { error: false, message: "" },
    confirm_password: { error: false, message: "" },
    conditionCheck: { error: false, message: "" },
    verificationCode: { error: false, message: "" },
  });
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const validateErrorProviderInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsProviderInfo({ ...errorsProviderInfo, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorsProviderInfo({ ...errorsProviderInfo, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateFormProviderInfo = () => {
    let formIsValid = true;
    let localerrorsProviderInfo = {
      firstName: { error: false, message: "" },
      lastName: { error: false, message: "" },
      email: { error: false, message: "" },
      password: { error: false, message: "" },
      confirm_password: { error: false, message: "" },
      conditionCheck: { error: false, message: "" },
      verificationCode: { error: false, message: "" },
    };
    if (providerInfoInputs.firstName === "") {
      formIsValid = false;
      localerrorsProviderInfo.firstName = { error: true, message: "First Name is Required!" };
    } else if (providerInfoInputs.firstName.length < 3) {
      formIsValid = false;
      localerrorsProviderInfo.firstName = { error: true, message: "First Name should have at least three letters!" };
    }

    if (providerInfoInputs.lastName === "") {
      formIsValid = false;
      localerrorsProviderInfo.lastName = { error: true, message: "Last Name is Required!" };
    }

    if (providerInfoInputs.email === "") {
      formIsValid = false;
      localerrorsProviderInfo.email = { error: true, message: "Email is Required!" };
    } else if (!emailPattern.test(providerInfoInputs.email)) {
      formIsValid = false;
      localerrorsProviderInfo.email = { error: true, message: "Invalid Email!" };
    }

    if (providerInfoInputs.password === "") {
      formIsValid = false;
      localerrorsProviderInfo.password = { error: true, message: "Password is Required!" };
    }

    if (providerInfoInputs.confirm_password === "") {
      formIsValid = false;
      localerrorsProviderInfo.confirm_password = { error: true, message: "Confirm Password is Required!" };
    }

    if (providerInfoInputs.password !== providerInfoInputs.confirm_password) {
      formIsValid = false;
      localerrorsProviderInfo.password = { error: true, message: "Password should be same as Confirm Password!" };
      localerrorsProviderInfo.confirm_password = { error: true, message: "Confirm Password should be same as Password!" };
    }
    if (providerInfoInputs.conditionCheck === false) {
      formIsValid = false;
      localerrorsProviderInfo.conditionCheck = { error: true, message: "Please check the condition!" };
    }
    setErrorsProviderInfo({ ...errorsProviderInfo, ...localerrorsProviderInfo });
    return formIsValid;
  };

  const sendEmail = () => {
    let Code = Math.floor(Math.random() * 900000) + 100000;
    setVCode(Code);
    const values = {
      fullName: providerInfoInputs.firstName,
      email: providerInfoInputs.email,
      role: "",
      message: `Your Verification code is ${Code}`,
    };

    emailjs.send("service_q1sj92a", "template_fgbr8ag", values, "bPnkTyXgeUXVCp7PY"); //"2gvN4VDEjbhK8zs33");
  };

  const onSubmitSignUp = async () => {
    if (validateFormProviderInfo()) {
      //check if email exists
      let res = await checkEmail(providerInfoInputs.email);
      if (res.response === "Email Already Exist") {
        setOpenEmailModal(false);
        setAlertMessage({ type: "warning", description: "Email Already Exist", open: true });
        return;
      } else {
        setOpenEmailModal(true);
        sendEmail();
      }
    }
  };

  const onSubmitVerificationCode = async () => {
    if (providerInfoInputs.verificationCode.toString() === verificationCode.toString()) {
      dispatch({ type: SIGN_UP, payload: providerInfoInputs });
      navigate("/create-profile");
    }
  };

  const onClickResendCode = () => {
    sendEmail();
  };

  return (
    <>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      <div className="flex gap-[40px] w-full">
        <div className="hidden md:block md:w-[55%] md:relative ">
          <div className="w-full h-[100vh]">
            <img src={`${process.env.PUBLIC_URL}/imgs/sider-image.png`} alt="Vaccifi" className="object-cover fixed w-[55%] h-full" />
          </div>
          <div className="absolute left-[50px] top-[40px]">
            <img src={`${process.env.PUBLIC_URL}/imgs/sider-text-image.svg`} alt="vaccine text" className="fixed w-[25%]" />
          </div>
        </div>
        <div className="w-full p-[20px]  flex justify-center items-center md:w-[45%]">
          <div className="w-[380px]">
            <div>
              <h2 className="text-[20px] font-[600] text-[#0F5175]">Sign Up</h2>
              <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">Enter your Details</p>
            </div>
            <div className="mt-[30px]">
              <SignUpForm
                inputs={providerInfoInputs}
                errorsProviderInfo={errorsProviderInfo}
                openEmailModal={openEmailModal}
                setOpenEmailModal={setOpenEmailModal}
                validateErrorProviderInfo={validateErrorProviderInfo}
                onChange={handleProviderInfoChange}
                onSubmitSignUp={onSubmitSignUp}
                onSubmitVerificationCode={onSubmitVerificationCode}
                onClickResendCode={onClickResendCode}
              />
            </div>
            <div className="mt-[40px]">
              <span className="text-[16px] font-[400] text-[#888A8C]">
                Already have an account? &nbsp;
                <Link to="/" className="text-[16px] text-[#000000] font-[400px] underline">
                  Sign In
                </Link>
              </span>
            </div>
            <div className="mt-[28px]">
              <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">
                By clicking "Continue", you agree to our
                <span className="text-[14px] font-[400] text-[#276EF1]">Terms of Service and Privacy Policy</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
