import { Box, Card, Grid, Stack } from "@mui/material";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardLayout, VAButton, FormInput, FromSelect, NumberFormatField, FormUploadFile } from "../../../../components";
import { updateBusinessInfo, updatePrimaryInfo, getMyProfoleInfo } from "../../../../redux/actionCreators";
import { AppState } from "../../../../redux/store";
import { useFormFieldsHandleChange } from "../../../../utils";
import { getProviderRolesAndLocTypes } from "../../../../redux/actionCreators/Provider/ManageUsers";
export const MyProfile = (): ReactElement => {
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [bInfoEditClick, setBInfoEditClick] = useState(false);
  const [pInfoEditClick, setPInfoEditClick] = useState(false);
  const [providerRoles, setProviderRoles] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);

  const onBInfoEditClick = () => {
    setBInfoEditClick(!bInfoEditClick);
  };

  const onPInfoEditClick = () => {
    setPInfoEditClick(!pInfoEditClick);
  };

  const [businessInfo, setBusinessInfo] = useState({
    name: "",
    locationtype: "",
    address: "",
    state: "",
    zipcode: "",
    city: "",
  });

  const [businessInfoInputs, handleBusinessInfoChange] = useFormFieldsHandleChange(businessInfo);
  const [errorsBusinessInfo, setErrorsBusinessInfo] = useState({
    name: { error: false, message: "" },
    locationtype: { error: false, message: "" },
    address: { error: false, message: "" },
    state: { error: false, message: "" },
    zipcode: { error: false, message: "" },
    city: { error: false, message: "" },
  });
  const validateErrorBusinessInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsBusinessInfo({ ...errorsBusinessInfo, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsBusinessInfo({ ...errorsBusinessInfo, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormBusinessInfo = () => {
    let formIsValid = true;
    let localErrorsBusinessInfo = errorsBusinessInfo;

    if (businessInfoInputs.name === "") {
      formIsValid = false;
      localErrorsBusinessInfo.name = { error: true, message: "Business Name is Required!" };
    }
    if (businessInfoInputs.locationtype === "") {
      formIsValid = false;
      localErrorsBusinessInfo.locationtype = { error: true, message: "location Type is Required!" };
    }
    if (businessInfoInputs.address === "") {
      formIsValid = false;
      localErrorsBusinessInfo.address = { error: true, message: "Address is Required!" };
    }
    if (businessInfoInputs.state === "") {
      formIsValid = false;
      localErrorsBusinessInfo.state = { error: true, message: "State is Required!" };
    }
    if (businessInfoInputs.zipcode === "") {
      formIsValid = false;
      localErrorsBusinessInfo.zipcode = { error: true, message: "Zip Code is Required!" };
    }
    if (businessInfoInputs.city === "") {
      formIsValid = false;
      localErrorsBusinessInfo.city = { error: true, message: "City is Required!" };
    }
    setErrorsBusinessInfo({ ...errorsBusinessInfo, ...localErrorsBusinessInfo });
    return formIsValid;
  };

  const onSubmitBusinessInfo = async () => {
    setBInfoEditClick(!bInfoEditClick);
    if (!validateFormBusinessInfo()) return;
    const updateBusinessData: any = {
      email: userData.email,
      businessInfo: businessInfoInputs,
    };
    console.log("createProfileData", updateBusinessData);
    await updateBusinessInfo(updateBusinessData);
    await fetchData();
  };

  const [primaryUserInfo, setPrimaryUserInfo] = useState({
    name: "",
    role: "",
    email: "",
    phone_no: "",
    licence: "",
  });

  const [primaryUserInfoInputs, handlePrimaryUserInfoChange] = useFormFieldsHandleChange(primaryUserInfo);
  const [errorsPrimaryUserInfo, setErrorsPrimaryUserInfo] = useState({
    name: { error: false, message: "" },
    role: { error: false, message: "" },
    email: { error: false, message: "" },
    phone_no: { error: false, message: "" },
    licence: { error: false, message: "" },
  });
  const validateErrorPrimaryUserInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsPrimaryUserInfo({ ...errorsPrimaryUserInfo, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsPrimaryUserInfo({ ...errorsPrimaryUserInfo, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormPrimaryUserInfo = () => {
    let formIsValid = true;
    let localErrorsPrimaryUserInfo = errorsPrimaryUserInfo;

    if (primaryUserInfoInputs.name === "") {
      formIsValid = false;
      localErrorsPrimaryUserInfo.name = { error: true, message: "Primary User Name is Required!" };
    }
    if (primaryUserInfoInputs.role === "") {
      formIsValid = false;
      localErrorsPrimaryUserInfo.role = { error: true, message: "Role is Required!" };
    }
    if (primaryUserInfoInputs.email === "") {
      formIsValid = false;
      localErrorsPrimaryUserInfo.email = { error: true, message: "Email is Required!" };
    }
    if (primaryUserInfoInputs.phone_no === "") {
      formIsValid = false;
      localErrorsPrimaryUserInfo.phone_no = { error: true, message: "Phone number is Required!" };
    }
    if (primaryUserInfoInputs.licence === "") {
      formIsValid = false;
      localErrorsPrimaryUserInfo.licence = { error: true, message: "licence is Required!" };
    }
    setErrorsPrimaryUserInfo({ ...errorsPrimaryUserInfo, ...localErrorsPrimaryUserInfo });
    return formIsValid;
  };

  const onSubmitPrimaryUserInfo = async () => {
    setPInfoEditClick(!pInfoEditClick);
    if (!validateFormPrimaryUserInfo()) return;
    const updatePrimaryUserData: any = {
      email: userData.email,
      primaryInfo: primaryUserInfoInputs,
    };
    await updatePrimaryInfo(updatePrimaryUserData);
    await fetchData();
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await getMyProfoleInfo({ email: userData.email });
      setBusinessInfo(response.data.businessInfo);
      businessInfoInputs.name = response.data.businessInfo.name;
      businessInfoInputs.locationtype = response.data.businessInfo.locationtype;
      businessInfoInputs.address = response.data.businessInfo.address;
      businessInfoInputs.state = response.data.businessInfo.state;
      businessInfoInputs.zipcode = response.data.businessInfo.zipcode;
      businessInfoInputs.city = response.data.businessInfo.city;

      setPrimaryUserInfo(response.data.primaryUserInfo);
      primaryUserInfoInputs.name = response.data.primaryUserInfo.name;
      primaryUserInfoInputs.email = response.data.primaryUserInfo.email;
      const primaryUserrole: any = providerRoles.find((item: any) => item.value === response.data.primaryUserInfo.role);
      primaryUserInfoInputs.role = primaryUserrole ? primaryUserrole.value : "";
      primaryUserInfoInputs.phone_no = response.data.primaryUserInfo.phone_no;
      primaryUserInfoInputs.licence = response.data.primaryUserInfo.licence;

      const res = await getProviderRolesAndLocTypes();
      setProviderRoles(res.providerRoles);
      setLocationTypes(res.locationTypes);
    } catch (err) {
      console.log(err);
    }
  }, [userData.email, businessInfoInputs, primaryUserInfoInputs, providerRoles]);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <div>
        <h2 className="text-[24px] text-primary-main font-[600]">My Profile</h2>
      </div>
      <Box flex={1}>
        <Box mx="auto">
          <Box mb={6}>
            {/* businessInfo */}
            {bInfoEditClick === false ? (
              <Card
                sx={{
                  width: "100%",
                  padding: 2.5,
                  paddingLeft: { xs: 8, md: 2 },
                  overflow: "hidden",
                  borderRadius: 3,
                  boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                  mt: 2,
                  mb: 4,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box display="flex" flexDirection="row" width={1} justifyContent="start">
                      <h2 className="text-xl text-[#1F2124] font-bold leading-none">Business Info</h2>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" flexDirection="row" alignItems="center" width={1} justifyContent="end">
                      <VAButton size="small" dark text="Edit" onClick={onBInfoEditClick} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Business Name: </span>
                        {businessInfo.name}
                      </Box>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Health Department: </span>
                        {businessInfo.locationtype}
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Address: </span>
                        {businessInfo.address}
                      </Box>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">City: </span>
                        {businessInfo.city}
                      </Box>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">State: </span>
                        {businessInfo.state}
                      </Box>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Zip Code: </span>
                        {businessInfo.zipcode}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <Card
                sx={{
                  width: "100%",
                  padding: 2.5,
                  paddingLeft: { xs: 8, md: 2 },
                  overflow: "hidden",
                  borderRadius: 3,
                  boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                  mt: 2,
                  mb: 4,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box display="flex" flexDirection="row" width={1} justifyContent="start">
                      <h2 className="text-xl text-[#1F2124] font-bold leading-none">Business Info</h2>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" flexDirection="row" alignItems="center" width={1} justifyContent="end">
                      <Box sx={{ mr: 2 }}>
                        <VAButton size="small" light text="Cancel" onClick={onBInfoEditClick} />
                      </Box>
                      <Box>
                        <VAButton size="small" dark text="Save" onClick={onSubmitBusinessInfo} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Box>
                        <FormInput
                          name="name"
                          label="Business Name"
                          value={businessInfoInputs.name}
                          validate={{ error: errorsBusinessInfo.name.error, message: errorsBusinessInfo.name.message }}
                          required
                          onChange={handleBusinessInfoChange}
                          onBlur={validateErrorBusinessInfo}
                        />
                      </Box>
                      <Box>
                        <FromSelect
                          name="locationtype"
                          label="Health Department"
                          options={locationTypes}
                          value={businessInfoInputs.locationtype}
                          validate={{ error: errorsBusinessInfo.locationtype.error, message: errorsBusinessInfo.locationtype.message }}
                          required
                          onChange={handleBusinessInfoChange}
                          onBlur={validateErrorBusinessInfo}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Box>
                        <FormInput
                          name="address"
                          label="Address"
                          value={businessInfoInputs.address}
                          validate={{ error: errorsBusinessInfo.address.error, message: errorsBusinessInfo.address.message }}
                          required
                          onChange={handleBusinessInfoChange}
                          onBlur={validateErrorBusinessInfo}
                        />
                      </Box>
                      <Box>
                        <FormInput
                          name="city"
                          label="City"
                          value={businessInfoInputs.city}
                          validate={{ error: errorsBusinessInfo.city.error, message: errorsBusinessInfo.city.message }}
                          required
                          onChange={handleBusinessInfoChange}
                          onBlur={validateErrorBusinessInfo}
                        />
                      </Box>
                      <Box>
                        <FormInput
                          name="state"
                          label="State"
                          value={businessInfoInputs.state}
                          validate={{ error: errorsBusinessInfo.state.error, message: errorsBusinessInfo.state.message }}
                          required
                          onChange={handleBusinessInfoChange}
                          onBlur={validateErrorBusinessInfo}
                        />
                      </Box>
                      <Box>
                        <NumberFormatField
                          name="zipcode"
                          label="Zip Code"
                          value={businessInfoInputs.zipcode}
                          validate={{ error: errorsBusinessInfo.zipcode.error, message: errorsBusinessInfo.zipcode.message }}
                          required
                          onChange={handleBusinessInfoChange}
                          onBlur={validateErrorBusinessInfo}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            )}

            {pInfoEditClick === false ? (
              <Card
                sx={{
                  width: "100%",
                  padding: 2.5,
                  paddingLeft: { xs: 8, md: 2 },
                  overflow: "hidden",
                  borderRadius: 3,
                  boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box display="flex" flexDirection="row" width={1} justifyContent="start">
                      <h2 className="text-xl text-[#1F2124] font-bold leading-none">Primary User</h2>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" flexDirection="row" alignItems="center" width={1} justifyContent="end">
                      <VAButton size="small" dark text="Edit" onClick={onPInfoEditClick} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Name: </span>
                        {primaryUserInfo.name}
                      </Box>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Role: </span>
                        {primaryUserInfo.role}
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Email: </span>
                        {primaryUserInfo.email}
                      </Box>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Phone Number: </span>
                        {primaryUserInfo.phone_no}
                      </Box>
                      <Box>
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[12px]">Licence: </span>
                        {primaryUserInfo.licence}
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <Card
                sx={{
                  width: "100%",
                  padding: 2.5,
                  paddingLeft: { xs: 8, md: 2 },
                  overflow: "hidden",
                  borderRadius: 3,
                  boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box display="flex" flexDirection="row" width={1} justifyContent="start">
                      <h2 className="text-xl text-[#1F2124] font-bold leading-none">Primary User</h2>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" flexDirection="row" alignItems="center" width={1} justifyContent="end">
                      <Box sx={{ mr: 2 }}>
                        <VAButton size="small" light text="Cancel" onClick={onPInfoEditClick} />
                      </Box>
                      <Box>
                        <VAButton size="small" dark text="Save" onClick={onSubmitPrimaryUserInfo} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Box>
                        <FormInput
                          name="name"
                          label="Primary User Name"
                          value={primaryUserInfoInputs.name}
                          validate={{ error: errorsPrimaryUserInfo.name.error, message: errorsPrimaryUserInfo.name.message }}
                          required
                          onChange={handlePrimaryUserInfoChange}
                          onBlur={validateErrorPrimaryUserInfo}
                        />
                      </Box>

                      <Box>
                        <FromSelect
                          name="role"
                          label="Role"
                          value={primaryUserInfoInputs.role}
                          validate={{ error: errorsPrimaryUserInfo.role.error, message: errorsPrimaryUserInfo.role.message }}
                          required
                          onChange={handlePrimaryUserInfoChange}
                          options={providerRoles}
                          onBlur={validateErrorPrimaryUserInfo}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" spacing={1}>
                      <Box>
                        <FormInput
                          name="email"
                          label="Email"
                          value={primaryUserInfoInputs.email}
                          validate={{ error: errorsPrimaryUserInfo.email.error, message: errorsPrimaryUserInfo.email.message }}
                          required
                          onChange={handlePrimaryUserInfoChange}
                          onBlur={validateErrorPrimaryUserInfo}
                        />
                      </Box>
                      <Box>
                        <NumberFormatField
                          name="phoneNo"
                          label="Phone Number"
                          value={primaryUserInfoInputs.phone_no}
                          validate={{ error: errorsPrimaryUserInfo.phone_no.error, message: errorsPrimaryUserInfo.phone_no.message }}
                          required
                          onChange={handlePrimaryUserInfoChange}
                          onBlur={validateErrorPrimaryUserInfo}
                        />
                      </Box>
                      <Box>
                        <FormUploadFile
                          name="licence"
                          label="Upload State License or URL"
                          value={primaryUserInfoInputs.licence}
                          validate={{ error: errorsPrimaryUserInfo.licence.error, message: errorsPrimaryUserInfo.licence.message }}
                          required
                          onChange={handlePrimaryUserInfoChange}
                          onBlur={validateErrorPrimaryUserInfo}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};
