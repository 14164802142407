import jwtAxios from "../../../services/auth/jwt-auth";

export const getBusinesses = async () => {
  try {
    const res = await jwtAxios.post("/superadmin/businesses/getbusinesses", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const suspendBusiness = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/businesses/suspendbusiness", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateBusiness = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/businesses/updatebusiness", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteBusiness = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/businesses/deletebusiness", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
