import { ReactElement, useState } from "react";
import { checkEmail } from "../../redux/actionCreators";
import { ForgotPasswordForm } from "../../sections/ForgotPassword";
import { ProviderInfoModel } from "../../types/models/Provider";
import { useFormFieldsHandleChange } from "../../utils";
import emailjs from "@emailjs/browser";

export const ForgotPassword = (): ReactElement => {
  //State and Handlers
  const [forgotPassInfo] = useState<ProviderInfoModel>({ firstName: "", lastName: "", email: "", password: "", confirm_password: "", conditionCheck: false, verificationCode: 0 });
  const [forgotPassInfoInputs, handleforgotPassInfoChange] = useFormFieldsHandleChange(forgotPassInfo);
  const [errorsforgotPassInfo, setErrorsforgotPassInfo] = useState({
    firstName: { error: false, message: "" },
    lastName: { error: false, message: "" },
    email: { error: false, message: "" },
    password: { error: false, message: "" },
    confirm_password: { error: false, message: "" },
    conditionCheck: { error: false, message: "" },
    verificationCode: { error: false, message: "" },
    newPassword: { error: false, message: "" },
    confirmPassword: { error: false, message: "" },
  });

  const validateErrorforgotPassInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsforgotPassInfo({ ...errorsforgotPassInfo, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorsforgotPassInfo({ ...errorsforgotPassInfo, [e.target.name]: { error: false, message: "" } });
    }
  };

  const sendEmail = () => {
    const values = {
      fullName: forgotPassInfoInputs.firstName,
      email: forgotPassInfoInputs.email,
      role: "",
      message: `Click the link below to reset your password.`,
    };
    emailjs.send("service_q1sj92a", "template_lh72jpi", values, "bPnkTyXgeUXVCp7PY");
  };

  const onSubmit = async () => {
    //check if email exists
    let res = await checkEmail(forgotPassInfoInputs.email);
    if (res.response === "Email Already Exist") {
      //send email
      forgotPassInfoInputs.firstName = res.data.first_name;
      sendEmail();
      return;
    } else {
      setErrorsforgotPassInfo({ ...errorsforgotPassInfo, email: { error: true, message: "Couldn't found the entered email." } });
    }
  };

  return (
    <div className="flex gap-[40px] w-full">
      <div className="hidden md:block md:w-[55%] md:relative ">
        <div className="w-full h-[100vh]">
          <img src={`${process.env.PUBLIC_URL}/imgs/sider-image.png`} alt="Vaccifi" className="object-cover fixed w-[55%] h-full" />
        </div>
        <div className="absolute left-[50px] top-[40px]">
          <img src={`${process.env.PUBLIC_URL}/imgs/sider-text-image.svg`} alt="vaccine text" className="fixed w-[25%]" />
        </div>
      </div>
      <div className="w-full p-[20px]  flex justify-center items-center md:w-[45%]">
        <div className="w-[380px]">
          <div>
            <h2 className="text-[20px] font-[600] text-[#0F5175]">Forgot Your Password?</h2>
            <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">
              Enter your email and we'll send you a <br /> link to get back into your account.
            </p>
          </div>
          <div className="mt-[30px]">
            <ForgotPasswordForm
              inputs={forgotPassInfoInputs}
              errorsforgotPassInfo={errorsforgotPassInfo}
              validateErrorforgotPassInfo={validateErrorforgotPassInfo}
              onChange={handleforgotPassInfoChange}
              onSubmit={onSubmit}
            />
          </div>
          <div className="mt-[50px]">
            <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">
              By clicking "Continue", you agree to our
              <span className="text-[14px] font-[400] text-[#276EF1]">Terms of Service and Privacy Policy</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
