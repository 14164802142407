import { ReactElement, useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { FiEye, FiEyeOff, FiSearch } from "react-icons/fi";
import { Box, IconButton } from "@mui/material";

const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
  },
  "& input.MuiInputBase-input": {
    paddingLeft: "18px",
  },
  "& label.Mui-focused": {
    color: "#888A8C",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CDCFD2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#CDCFD2",
    },
    "&:hover fieldset": {
      borderColor: "#CDCFD2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CDCFD2",
    },
  },
});
interface InputProps {
  name?: string;
  label?: any;
  heading?: string;
  icon?: any;
  value?: string | number;
  placeholder?: string;
  validate?: { error: boolean; message: string };
  required?: boolean;
  readOnly?: boolean;
  autoComplete?: "off" | "new-password";
  type?: string;
  variant?: "filled" | "outlined" | "standard";
  size?: "medium" | "small";
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const FormInput = ({ label, type, variant, size, heading, icon, name, value, placeholder, validate, required, readOnly, autoComplete, onChange, onBlur }: InputProps): ReactElement => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div>
      {heading ? (
        <div className="flex flex-col gap-[12px]">
          <h2 className="text-[16px] font-[600] text-[#1F2124]">{heading}</h2>
          {icon ? (
            <div className="relative w-full">
              <div className="absolute left-[5px] top-[50%] transform -translate-y-[50%]">{icon}</div>
              <CssTextField
                label={label}
                type={show ? "text" : type}
                name={name}
                value={value}
                variant={variant}
                placeholder={placeholder}
                error={validate?.error}
                helperText={validate?.message}
                required={required}
                size={size}
                onChange={onChange}
                onBlur={onBlur}
                id="custom-css-outlined-input"
                InputProps={{ readOnly: readOnly }}
                autoComplete={autoComplete}
              />
            </div>
          ) : (
            <>
              <CssTextField
                label={label}
                type={show ? "text" : type}
                variant={variant}
                name={name}
                value={value}
                placeholder={placeholder}
                error={validate?.error}
                helperText={validate?.message}
                required={required}
                onChange={onChange}
                size={size}
                onBlur={onBlur}
                id="custom-css-outlined-input"
                InputProps={{ readOnly: readOnly }}
                autoComplete={autoComplete}
              />
            </>
          )}
        </div>
      ) : (
        <div className="relative w-full">
          <Box>
            <CssTextField
              label={label}
              type={show ? "text" : type}
              variant={variant}
              name={name}
              value={value}
              placeholder={placeholder}
              error={validate?.error}
              helperText={validate?.message}
              required={required}
              onChange={onChange}
              size={size}
              onBlur={onBlur}
              id="custom-css-outlined-input"
              InputProps={{ readOnly: readOnly }}
              autoComplete={autoComplete}
            />
            {type === "password" ? (
              <div className="absolute right-[20px] top-[50%] transform -translate-y-[50%]">
                <IconButton style={{ marginTop: validate?.error ? "-20px" : 0 }} aria-label="toggle password visibility" onClick={() => setShow((prev) => !prev)} edge="end">
                  {show ? <FiEyeOff /> : <FiEye />}
                </IconButton>
              </div>
            ) : type === "search" ? (
              <div className="absolute left-[20px] top-[50%] transform -translate-y-[50%]">
                <IconButton style={{ marginTop: validate?.error ? "-20px" : 0 }} aria-label="toggle password visibility" onClick={() => setShow((prev) => !prev)} edge="end">
                  <FiSearch />
                </IconButton>
              </div>
            ) : (
              <></>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};
