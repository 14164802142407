import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { Fonts } from "../../shared/constants/AppEnums";

const TableHeaderRow = styled(TableRow)(({ theme }) => {
  return {
    "& th": {
      fontSize: 14,
      padding: 8,
      paddingTop: 14,
      paddingBottom: 14,
      // give border radius to the table header first and last child
      "&:first-child": {
        borderTopLeftRadius: 12,
      },
      "&:last-child": {
        borderTopRightRadius: 12,
      },
      fontWeight: Fonts.BOLD600,
      color: "#114059",
      backgroundColor: "#B7CBD6",
      "&:first-of-type": {
        paddingLeft: 20,
      },
      "&:last-of-type": {
        paddingRight: 20,
      },
    },
  };
});

interface TableHeaderProps {
  children: ReactNode;

  [x: string]: any;
}

const TableHeader: React.FC<TableHeaderProps> = ({ children, ...rest }) => {
  return <TableHeaderRow {...rest}>{children}</TableHeaderRow>;
};

export default TableHeader;
TableHeader.propTypes = {
  children: PropTypes.node,
};
