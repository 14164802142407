import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { styled } from "@mui/material/styles";
import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import { EditPenIcon, EyeIcon } from "../../../../icons";
dayjs.extend(utc);
const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));

interface TableItemProps {
  row: any;
  onClickEditLocation: any;
}
const TableItem: React.FC<TableItemProps> = ({ row, onClickEditLocation }) => {
  const actionCell = () => {
    return (
      <Stack direction="row" alignItems="start" justifyContent={"center"} spacing={2}>
        <Tooltip title="View Details">
          <IconButton>
            <EyeIcon fill="#114059" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit Location">
          <IconButton onClick={() => onClickEditLocation("EDIT", row)}>
            <EditPenIcon fill="#114059" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };
  return (
    <React.Fragment>
      <TableRow key={row.id} className="item-hover">
        <StyledTableCell align="left">{row.name}</StyledTableCell>
        <StyledTableCell align="left" sx={{ color: "#FF9800" }}>
          {row.loc_type}
        </StyledTableCell>
        <StyledTableCell align="left">{row.city}</StyledTableCell>
        <StyledTableCell align="left">{row.state}</StyledTableCell>
        <StyledTableCell align="left">{row.phone_no}</StyledTableCell>
        <StyledTableCell align="left">{row.active ? "Active" : "Deactive"}</StyledTableCell>
        <StyledTableCell align="center">{actionCell()}</StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default TableItem;
