import React, { useEffect, useState } from "react";
import { DashboardLayout, FormInput, VAButton } from "../../../components";
import { AlertType } from "../../../shared/constants/AppConst";
import VAAlert from "../../../components/Alert/Alert";
import { addManufacturer, deleteManufacturer, getManufacturers, updateManufacturer } from "../../../redux/actionCreators/SuperAdmin/Manufacturer";
import { MENU } from "../../../types/actions/Common.action";
import ManufacturersTable from "./ManufacturersTable";
import { Manufacturer } from "../../../sections/Superadmin";

// Usage example in a React component
export const Manufacturers: React.FC = () => {
  const [manufacturers, setManufacturers] = useState<any>([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [manufacturerId, setManufacturerId] = useState("");
  const [searchManufacturer, setSearchManufacturer] = useState("");
  const [errorFields, setErrorFields] = useState({ name: { error: false, message: "" } });
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<MENU>("VIEW");
  const handleChange = (event: any) => {
    if (event.target.name === "name") {
      setName(event.target.value as string);
    }
    if (event.target.name === "description") {
      setDescription(event.target.value as string);
    }
    if (event.target.name === "manufacturer") {
      setSearchManufacturer(event.target.value as string);
    }
  };
  const onBlurField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "name" && !value) {
      setErrorFields({ ...errorFields, name: { error: true, message: "Name is required" } });
    } else {
      setErrorFields({ ...errorFields, name: { error: false, message: "" } });
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    const errorFields = { name: { error: false, message: "" } };
    if (!name) {
      errorFields.name.error = true;
      errorFields.name.message = "Name is required";
      formIsValid = false;
    }
    setErrorFields(errorFields);
    return formIsValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        let res = selectedMenu === "ADD" ? await addManufacturer({ name, description }) : await updateManufacturer({ manufacturer_id: manufacturerId, name, description });
        setAlertMessage({ type: "success", description: res.message, open: true });
        setManufacturers(res.data);
        setSelectedMenu("VIEW");
      } catch (err) {
        setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
        console.log(err);
      }
    }
  };
  const onClickAdd = () => {
    setSelectedMenu("ADD");
    setName("");
    setDescription("");
  };
  const onClickEdit = (rowData: any) => {
    setSelectedMenu("EDIT");
    setManufacturerId(rowData.id);
    setName(rowData.name);
    setDescription(rowData.description);
  };
  const onClickDelete = async (manufacturer_id: any) => {
    if (window.confirm("Are you sure, you want to delete this record?")) {
      try {
        let res = await deleteManufacturer({ manufacturer_id });
        setAlertMessage({ type: "success", description: res.message, open: true });
        setManufacturers(res.data);
        setSelectedMenu("VIEW");
      } catch (err) {
        setAlertMessage({ type: "error", description: "Somthing wen wrong", open: true });
        console.log(err);
      }
    }
  };
  const onClickBack = () => {
    setSelectedMenu("VIEW");
  };
  const getManufacturersData = async () => {
    try {
      let res = await getManufacturers();
      setManufacturers(res.data);
      setSelectedMenu("VIEW");
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getManufacturersData();
  }, []);
  const filteredManufacturers = manufacturers.filter((manufacturer: any) => manufacturer.name.toLowerCase().includes(searchManufacturer.toLowerCase()));
  const renderMenu = () => {
    switch (selectedMenu) {
      case "VIEW":
        return (
          <div>
            <div>
              <h2 className="text-[24px] text-primary-main font-[600]">Vaccine Manufacturers</h2>
            </div>
            <div className="flex items-center justify-between py-[20px]">
              <FormInput label="Search by name 🔍" name="manufacturer" value={searchManufacturer} onChange={handleChange} />
              <VAButton dark text="Add New" onClick={onClickAdd} />
            </div>
            <div className="mt-[30px]">
              <ManufacturersTable manufacturers={filteredManufacturers} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
            </div>
          </div>
        );
      case "ADD":
      case "EDIT":
        return (
          <div>
            <div>
              <h2 className="text-[20px] font-[600] text-[#0F5175]">{selectedMenu === "ADD" ? "Add New Manufacturer" : "Update Manufacturer"}</h2>
              <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">Enter Information</p>
            </div>
            <div className="mt-[30px]">
              <Manufacturer
                selectedMenu={selectedMenu}
                name={name}
                description={description}
                handleChange={handleChange}
                onBlurField={onBlurField}
                errorFields={errorFields}
                handleSubmit={handleSubmit}
                onClickBack={onClickBack}
              />
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <DashboardLayout>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      {renderMenu()}
    </DashboardLayout>
  );
};
