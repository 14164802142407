import { ReactElement, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { CustomTags, FormInput, FromSelect, NumberFormatField, VAButton } from "../../../../../components";
import { AddIcon } from "../../../../../icons";
import { ErrorsLocationSecondaryUserModel, LocationSecondaryUserModel } from "../../../../../types/models/Provider/Location/LocationSecondaryUser";

interface LocationSUProps {
  providerRoles: any;
  setSecondaryUserInputs: any;
  secondaryUserInputs: LocationSecondaryUserModel;
  errorsSecondaryUser: ErrorsLocationSecondaryUserModel;
  validateErrorSecondaryUser: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  validateFormSecondaryUser: () => boolean;
  handleSecondaryUserChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  secondaryUsers: LocationSecondaryUserModel[];
  setSecondaryUsers: (e: LocationSecondaryUserModel[]) => void;
  handleResetSecondaryUser: () => void;
}

export const LocationSecondaryUser = ({
  providerRoles,
  secondaryUsers,
  setSecondaryUsers,
  setSecondaryUserInputs,
  secondaryUserInputs,
  errorsSecondaryUser: errors,
  validateErrorSecondaryUser: validateError,
  validateFormSecondaryUser: validateForm,
  handleSecondaryUserChange,
  handleResetSecondaryUser,
}: LocationSUProps): ReactElement => {
  const [permissions, setPermissions] = useState([
    { id: 1, desc: "View Schedule", checked: true },
    { id: 2, desc: "Pending Vaccine Records to Verify", checked: true },
    { id: 3, desc: "Create/modify Vaccination Schedule", checked: true },
  ]);

  const onCheckBoxClick = (item: { id: number; desc: string; checked: boolean }) => {
    item.checked = !item.checked;
    setPermissions((prev) => prev.map((i) => (i.id === item.id ? item : i)));
  };

  const onAddSecondaryUser = () => {
    if (!validateForm()) return;
    var selectedPermissions: any[] = [];
    permissions.forEach((item) => {
      if (item.checked) selectedPermissions.push(item.desc);
    });

    let newArr = [...secondaryUsers]; // copying the old data array
    secondaryUserInputs.permissions = selectedPermissions;
    newArr.push(secondaryUserInputs); // push new data to the array
    setSecondaryUsers(newArr); // update the state
    setPermissions([
      { id: 1, desc: "View Schedule", checked: true },
      { id: 2, desc: "Pending Vaccine Records to Verify", checked: true },
      { id: 3, desc: "Create/modify Vaccination Schedule", checked: true },
    ]);
    handleResetSecondaryUser();
  };

  const onClickCancel = (text: string, user: any) => {
    const index = secondaryUsers.findIndex((item) => item.firstName === text && item.email === user.email);
    if (index > -1) {
      secondaryUsers.splice(index, 1);
    }
    setSecondaryUsers([...secondaryUsers]);
  };

  const onEditClick = (text: string, user: any) => {
    // Find the index of the user in the secondaryUsers array
    const index = secondaryUsers.findIndex((item) => item.firstName === text && item.email === user.email);
    if (index !== -1) {
      // Get the user's information based on the index
      const editedUser = secondaryUsers[index];

      // Update the state with the user's information
      setSecondaryUserInputs({
        ...secondaryUserInputs,
        recordType: editedUser.recordType,
        id: editedUser.id,
        firstName: editedUser.firstName,
        lastName: editedUser.lastName,
        email: editedUser.email,
        role: editedUser.role,
        phone: editedUser.phone,
        active: editedUser.active,
        // You may need to update other fields based on your data structure
      });

      // Set the permissions based on the user's information
      const updatedPermissions = permissions.map((item: any) => ({
        ...item,
        checked: editedUser.permissions.includes(item.desc),
      }));
      setPermissions(updatedPermissions);

      // Remove the edited user from the secondaryUsers list
      const updatedUsers = [...secondaryUsers];
      updatedUsers.splice(index, 1);
      setSecondaryUsers(updatedUsers);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <div>
            <span className="text-[14px] text-[#1F2124] font-[600]">Secondary Users</span>
          </div>
          <span className="text-[12px] text-[#888A8C] font-[400]">Invite link will be sent to the users from where they can login and create profile</span>
        </div>

        <div className="cursor-pointer">
          <AddIcon />
        </div>
      </div>
      <div className="mt-[12px] grid grid-cols-3 gap-2">
        {secondaryUsers.map((item: any) => {
          return <CustomTags text={item.firstName} item={item} onClickEdit={onEditClick} onClickCancel={onClickCancel} />;
        })}
      </div>
      <div className="mt-[20px]">
        <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
          <FormInput
            name="firstName"
            label="Legal First Name"
            value={secondaryUserInputs.firstName}
            validate={{ error: errors.firstName.error, message: errors.firstName.message }}
            required
            onChange={handleSecondaryUserChange}
            onBlur={validateError}
            autoComplete={"off"}
          />
          <FormInput
            name="lastName"
            label="Legal First Name"
            value={secondaryUserInputs.lastName}
            validate={{ error: errors.lastName.error, message: errors.lastName.message }}
            required
            onChange={handleSecondaryUserChange}
            onBlur={validateError}
            autoComplete={"off"}
          />
        </div>
      </div>
      <div className="mt-[30px] grid grid-cols-[1fr_1fr] items-center gap-[50px]">
        <FormInput
          name="email"
          label="Email"
          value={secondaryUserInputs.email}
          validate={{ error: errors.email.error, message: errors.email.message }}
          required
          onChange={handleSecondaryUserChange}
          onBlur={validateError}
          autoComplete={"off"}
        />
        <FromSelect
          name="role"
          label="Role"
          value={secondaryUserInputs.role}
          validate={{ error: errors.role.error, message: errors.role.message }}
          required
          onChange={handleSecondaryUserChange}
          options={providerRoles}
          onBlur={validateError}
        />
      </div>
      <div className="mt-[30px] grid grid-cols-[1fr_1fr] items-center gap-[50px]">
        <NumberFormatField
          name="phone"
          label="Phone Number"
          value={secondaryUserInputs.phone}
          validate={{ error: errors.phone.error, message: errors.phone.message }}
          required
          onChange={handleSecondaryUserChange}
          onBlur={validateError}
          autoComplete={"off"}
        />
        {secondaryUserInputs.recordType === "ADD" && (
          <FormInput
            name="password"
            label="Password"
            type="password"
            value={secondaryUserInputs.password}
            validate={secondaryUserInputs.recordType === "ADD" ? { error: errors.password.error, message: errors.password.message } : undefined}
            required
            onChange={handleSecondaryUserChange}
            onBlur={validateError}
            autoComplete={"new-password"}
          />
        )}
      </div>
      <div className="mt-[30px] grid grid-cols-[1fr_1fr] items-center gap-[50px]">
        {secondaryUserInputs.recordType === "ADD" && (
          <FormInput
            name="confirm_password"
            label="Confirm Password"
            type="password"
            value={secondaryUserInputs.confirm_password}
            validate={secondaryUserInputs.recordType === "ADD" ? { error: errors.confirm_password.error, message: errors.confirm_password.message } : undefined}
            required
            onChange={handleSecondaryUserChange}
            onBlur={validateError}
            autoComplete={"off"}
          />
        )}
        <div className="flex items-center ">
          <Checkbox name="active" checked={secondaryUserInputs.active} color={secondaryUserInputs.active ? "success" : "default"} onChange={handleSecondaryUserChange} />
          <span className="text-[#1F2124] text-[14px] font-[400]">{"Active user"}</span>
        </div>
      </div>
      <div className="mt-[30px] grid grid-cols-[1fr_1fr] items-center gap-[50px]">
        <div className="flex items-center ">
          <Checkbox name="branch_access" checked={secondaryUserInputs.branch_access} color={secondaryUserInputs.branch_access ? "success" : "default"} onChange={handleSecondaryUserChange} />
          <span className="text-[#1F2124] text-[14px] font-[400]">{"Branch access"}</span>
        </div>
      </div>
      <div className="mt-[30px]">
        <span className="text-[#1F2124] text-[14px] font-[400]">Assign permissions</span>
        <div className="mt-[12px]">
          {permissions.map((item) => (
            <div className="flex items-center gap-[12px]">
              <Checkbox checked={item.checked} color="success" onClick={() => onCheckBoxClick(item)} />
              <span className="text-[#1F2124] text-[14px] font-[400] cursor-pointer" onClick={() => onCheckBoxClick(item)}>
                {item.desc}
              </span>
            </div>
          ))}
          <div className="flex justify-end">
            <VAButton light text="Add" onClick={onAddSecondaryUser} />
          </div>
        </div>
      </div>
    </div>
  );
};
