import { ReactElement } from "react";
import { FormInput, FromSelect, VAButton } from "../../../components";
import { Box } from "@mui/material";
import { MENU } from "../../../types/actions/Common.action";
interface DiagnosticProps {
  selectedMenu: MENU;
  categories: [];
  category_id: string;
  manufacturers: [];
  mfg_id: string;
  name: string;
  no_of_doses: string;
  vaccine_cvx_code: string;
  time_spans: [];
  time_span: string;
  time_span_no: string;
  age_greater: string;
  age_less: string;
  note: string;
  handleChange: (event: any) => void;
  onBlurField: (event: React.FocusEvent<HTMLInputElement>) => void;
  errorFields: {
    category_id: { error: boolean; message: string };
    mfg_id: { error: boolean; message: string };
    name: { error: boolean; message: string };
    no_of_doses: { error: boolean; message: string };
    vaccine_cvx_code: { error: boolean; message: string };
    time_span: { error: boolean; message: string };
    time_span_no: { error: boolean; message: string };
    age_greater: { error: boolean; message: string };
    age_less: { error: boolean; message: string };
    note: { error: boolean; message: string };
  };
  handleSubmit: () => void;
  onClickBack: () => void;
}
export const Diagnostic = ({
  selectedMenu,
  categories,
  category_id,
  manufacturers,
  mfg_id,
  name,
  no_of_doses,
  vaccine_cvx_code,
  time_spans,
  time_span,
  time_span_no,
  age_greater,
  age_less,
  note,
  handleChange,
  onBlurField,
  errorFields,
  handleSubmit,
  onClickBack,
}: DiagnosticProps): ReactElement => {
  return (
    <div className="mt-[22px]">
      <div className="mt-[20px]">
        <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
          <div className="flex flex-col gap-[30px]">
            <FromSelect
              name="category_id"
              label="Category"
              value={category_id}
              options={categories}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.category_id.error, message: errorFields.category_id.message }}
            />
            <FromSelect
              name="mfg_id"
              label="Manufacturer"
              value={mfg_id}
              options={manufacturers}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.mfg_id.error, message: errorFields.mfg_id.message }}
            />
            <FormInput
              name="name"
              label="Diagnostic name"
              type="text"
              value={name}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.name.error, message: errorFields.name.message }}
            />
            <FormInput
              name="no_of_doses"
              label="Number of doses needed"
              type="number"
              value={no_of_doses}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.no_of_doses.error, message: errorFields.no_of_doses.message }}
            />
            <FormInput
              name="vaccine_cvx_code"
              label="Diagnostic CVX Code"
              type="number"
              value={vaccine_cvx_code}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.vaccine_cvx_code.error, message: errorFields.vaccine_cvx_code.message }}
            />
            <FromSelect
              name="time_span"
              heading="Time span between doses"
              label="Total Quantity"
              value={time_span}
              options={time_spans}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.time_span.error, message: errorFields.time_span.message }}
            />
            <FormInput
              name="time_span_no"
              label="Number"
              type="number"
              value={time_span_no}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.time_span_no.error, message: errorFields.time_span_no.message }}
            />
            <FormInput
              name="age_greater"
              heading="Age Group"
              label="Greater than"
              type="number"
              value={age_greater}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.age_greater.error, message: errorFields.age_greater.message }}
            />
            <FormInput
              name="age_less"
              label="Less than"
              type="number"
              value={age_less}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.age_less.error, message: errorFields.age_less.message }}
            />
            <FormInput
              name="note"
              heading="Notes"
              label="Enter description..."
              type="text"
              value={note}
              onChange={handleChange}
              onBlur={onBlurField}
              validate={{ error: errorFields.note.error, message: errorFields.note.message }}
            />
            <div>
              <Box display="flex" flexDirection="row" alignItems="center" width={1} justifyContent="end">
                <Box sx={{ mr: 2 }}>
                  <VAButton text="Back" light onClick={onClickBack} />
                </Box>
                <Box>
                  <VAButton text={selectedMenu === "ADD" ? "Add" : "Update"} dark onClick={handleSubmit} />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
