import { categoryModel, vaccinesModel } from "../../models/Provider/AddVaccine";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_VACCINES = "GET_VACCINES";

export interface getCategoryAction {
  type: typeof GET_CATEGORY;
  payload: categoryModel;
}

export interface getVaccineAction {
  type: typeof GET_VACCINES;
  payload: vaccinesModel;
}

export type AddVaccineActionTypes = getCategoryAction | getVaccineAction;
