import jwtAxios from "../../../services/auth/jwt-auth";
import { CreateLocationModel } from "../../../types/models/Provider/Location/CreateLocation";
import { UpdateLocationModel } from "../../../types/models/Provider/Location/UpdateLocation";

export const getBizBranches = async (data: any) => {
  try {
    const res = await jwtAxios.post("/managebiz/getbizbranches", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateBiz = async (data: any) => {
  try {
    const res = await jwtAxios.post("/managebiz/updatebiz", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const onCreateLocation = async (createLocationData: CreateLocationModel) => {
  try {
    const res = await jwtAxios.post("/managebiz/createlocation", createLocationData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const onEditLocation = async (data: any) => {
  try {
    const res = await jwtAxios.post("/managebiz/editlocation", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const onUpdateLocation = async (updateLocationData: UpdateLocationModel) => {
  try {
    const res = await jwtAxios.post("/managebiz/updatelocation", updateLocationData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
