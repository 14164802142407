import { ReactElement, useEffect } from "react";
// components
import { MonthAndYear } from "./MonthAndYear.component";
import { DayCardList } from "./DayCardList.component";
import { parse } from "date-fns";
import { AllDay } from "./AllDays.component";
import useDate from "../../hooks/useDate";
import { getEventsByDayNumber, getMatchMonthAndYear } from "../../utils/calendar";
interface VaccineCalendarProps {
  selectedMonthEvents: any;
  selectedDays: { day: number }[];
  setSelectedDays: any;
  setSelectedDate: any;
}
export const VaccineCalendar = ({ selectedMonthEvents, selectedDays, setSelectedDays, setSelectedDate }: VaccineCalendarProps): ReactElement => {
  const date = useDate();
  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  const eventsInSelectedMonth = getMatchMonthAndYear(date.month, date.year, selectedMonthEvents);
  // An array of days containing events for populating the calendar
  const days = Array.from({ length: date.daysInMonth }, (_, i) => {
    const currentDay = i + 1;
    const currentDayEvents: any = getEventsByDayNumber(currentDay, eventsInSelectedMonth);

    //Creates dateObject using month spelled out in a string, currentDay and year
    const dateObject = parse(`${date.month}, ${currentDay}, ${date.year}`, "MMMM, d, yyyy", new Date());
    return {
      date: dateObject,
      events: currentDayEvents.length > 0 ? currentDayEvents[0].events : [],
    };
  });

  return (
    <div className="overflow-x-scroll w-full md:w-full md:pr-[0px] flex flex-grow h-[500px] md:overflow-auto text-gray-700">
      <div className="flex flex-col flex-grow">
        <MonthAndYear month={date.month} year={date.year} handleNextMonth={date.getNextMonth} handlePreviousMonth={date.getPreviousMonth} />
        <AllDay />
        <DayCardList selectedDays={selectedDays} setSelectedDays={setSelectedDays} data={days} firstDayOfMonth={date.firstDay} />
      </div>
    </div>
  );
};
