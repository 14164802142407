import { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { VaccineCalendar, FromSelect, VAButton } from "../../../components";
import VAAlert from "../../../components/Alert/Alert";
import useDate from "../../../hooks/useDate";
import { Back } from "../../../icons";
import { addVaccineSchedules } from "../../../redux/actionCreators/Provider/VaccineSchedule";
import { AppState } from "../../../redux/store";
import { AlertType } from "../../../shared/constants/AppConst";
import { Box } from "@mui/system";
interface CreateVaccineScheduleProps {
  activeStep: any;
  setActiveStep: any;
  selectedMonthEvents: any;
  setSelectedMonthEvents: any;
  // ALERT
  alertMessage: AlertType | null;
  setAlertMessage: any;
}
export const CreateVaccineSchedule = ({
  setActiveStep,
  selectedMonthEvents,
  setSelectedMonthEvents,
  // ALERT
  alertMessage,
  setAlertMessage,
}: CreateVaccineScheduleProps): ReactElement => {
  const date = useDate();

  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [timeSlots, setTimeSlots] = useState<{ starttime: string; endtime: string; timeduration: string }[]>([]);
  const [timeSlotsError, setTimeSlotsError] = useState(false);
  // set the end time slots argument type to { value: string; label: string; }[]
  const [endTimeSlots, setEndTimeSlots] = useState<{ value: string; label: string }[]>([]);
  const [starttime, setStartTime] = useState("");
  const [endtime, setEndTime] = useState("");
  const [timeduration, setTimeDuration] = useState(""); //put the time slots here
  const [startTimeSlots] = useState([
    { value: "9:00 AM", label: "9:00 AM" },
    { value: "9:30 AM", label: "9:30 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "10:30 AM", label: "10:30 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "11:30 AM", label: "11:30 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "12:30 PM", label: "12:30 PM" },
    { value: "1:00 PM", label: "1:00 PM" },
    { value: "1:30 PM", label: "1:30 PM" },
    { value: "2:00 PM", label: "2:00 PM" },
    { value: "2:30 PM", label: "2:30 PM" },
    { value: "3:00 PM", label: "3:00 PM" },
    { value: "3:30 PM", label: "3:30 PM" },
    { value: "4:00 PM", label: "4:00 PM" },
    { value: "4:30 PM", label: "4:30 PM" },
    { value: "5:00 PM", label: "5:00 PM" },
    { value: "5:30 PM", label: "5:30 PM" },
    { value: "6:00 PM", label: "6:00 PM" },
    { value: "6:30 PM", label: "6:30 PM" },
    { value: "7:00 PM", label: "7:00 PM" },
    { value: "7:30 PM", label: "7:30 PM" },
  ]);
  const timeDurationSlots = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "45", label: "45" },
    { value: "60", label: "60" },
  ];

  // Start Calender States
  const [selectedDays, setSelectedDays] = useState<{ day: number }[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>(useDate());
  // Start Calender States

  const handleStartTime = (e: any) => {
    setStartTime(e.target.value);
    // setEndTimeSlots after the selected start time
    const startTimeIndex = startTimeSlots.findIndex((item) => item.value === e.target.value);
    let endTimeSlots = [];
    for (let i = startTimeIndex + 1; i < startTimeSlots.length; i++) {
      endTimeSlots.push(startTimeSlots[i]);
    }
    setEndTimeSlots(endTimeSlots);
  };
  const handleEndTime = (e: any) => {
    setEndTime(e.target.value);
  };

  const handleTimeDuration = (e: any) => {
    setTimeDuration(e.target.value);
  };
  const onClickAddTimeSlot = () => {
    if (selectedDays.length === 0) {
      setAlertMessage({ type: "error", title: "Select Dates", description: "Please select dates before proceed", open: true });
    } else if (starttime === "" || endtime === "" || timeduration === "") {
      setTimeSlotsError(true);
    } else {
      setTimeSlotsError(false);
      setAlertMessage({ type: "", title: "", description: "", open: false });
      // if the time slot is already present in the time slots array, then don't add it
      const isTimeSlotPresent = timeSlots.find((item: any) => item.starttime === starttime && item.endtime === endtime && item.timeduration === parseInt(timeduration));

      if (!isTimeSlotPresent) {
        const timeSlotsToDisplay: { starttime: any; endtime: any; timeduration: any; date: any }[] = [];
        // Add the selected time slots to the previous time slots
        timeSlots.forEach((item: any) => {
          timeSlotsToDisplay.push(item);
        });

        console.log(selectedDate);
        for (let i = 0; i < selectedDays.length; i++) {
          const element = selectedDays[i];
          //convert selectedDayDate string to UTC Date
          const selectedDayDate = new Date(selectedDate.year + "-" + selectedDate.month + "-" + element.day).toUTCString();

          // Check if the new time slot conflicts with any previously added time slots
          const isConflicting = timeSlots.some(
            (slot: any) => slot.date === selectedDayDate && ((slot.starttime <= starttime && slot.endtime >= endtime) || (slot.starttime <= endtime && slot.endtime >= endtime))
          );

          // If there is a conflict, do not add the time slot
          if (isConflicting) {
            setAlertMessage({
              type: "error",
              title: "Duplicate time slot",
              description: "Time slot conflicts with a previously added time slot. Please add the new time slot after the end time selected previously",
              open: true,
            });

            console.log("Time slot conflicts with a previously added time slot.");
            return;
          }
          // add the new time slot
          timeSlotsToDisplay.push({ starttime, endtime, timeduration, date: selectedDayDate });
          setTimeSlots(timeSlotsToDisplay);
        }
      }
      // reset the start time and end time
      setStartTime("");
      setEndTime("");
      setTimeDuration("");
      // set endTimeSlots  empty array of type this type { value: string; label: string }
      setEndTimeSlots([]);
    }
  };

  const handleEditTimeSlot = (index: number) => {
    // remove the time slot from the time slots array
    const timeSlotsToDisplay = [...timeSlots];
    const timeSlotToEdit = timeSlotsToDisplay.splice(index, 1);
    // set the start time, end time and time duration to the selected time slot

    setStartTime(timeSlotToEdit[0].starttime);
    // setEndTimeSlots after the selected start time
    const startTimeIndex = startTimeSlots.findIndex((item: any) => item.value === timeSlotToEdit[0].starttime);
    const endTimeSlots = [];
    for (let i = startTimeIndex + 1; i < startTimeSlots.length; i++) {
      endTimeSlots.push(startTimeSlots[i]);
    }
    setEndTimeSlots(endTimeSlots);

    setEndTime(timeSlotToEdit[0].endtime);
    setTimeDuration(timeSlotToEdit[0].timeduration);
    setTimeSlots(timeSlotsToDisplay);
  };

  const handleDeleteTimeSlot = (index: number) => {
    const updatedTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(updatedTimeSlots);
  };

  const handleSave = async () => {
    try {
      if (selectedDays.length > 0) {
        let data = { biz_branch_id: userData.selected_branch.biz_branch_id, timeSlots };
        let res = await addVaccineSchedules(data);

        setSelectedMonthEvents(res.data);
        setActiveStep(1);
      } else {
        setAlertMessage({ type: "error", title: "Select Dates", description: "Please select dates before proceed", open: true });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const uniqueTimeSlots = timeSlots.reduce((acc: any[], curr: any) => {
    if (!acc.some((item: any) => item.starttime === curr.starttime && item.endtime === curr.endtime && item.timeduration === curr.timeduration)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="flex items-center gap-[5px] cursor-pointer" onClick={() => setActiveStep(1)}>
          <Back />
          <span className="text-[16px] font-[600] text-[#888A8C]">Back</span>
        </div>
        <div className="mt-[14px]">
          <h2 className="text-[24px] text-primary-main font-[600]">Create Vaccine Schedule</h2>
        </div>
        <h1 className="mt-[36px] font-semibold text-[16px] text-[#1F2124]">Set up schedule (Select dates)</h1>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-[20px]">
          <VaccineCalendar selectedMonthEvents={selectedMonthEvents} selectedDays={selectedDays} setSelectedDays={setSelectedDays} setSelectedDate={setSelectedDate} />
          <div className="">
            <h2 className="text-[#1F2124] text-[16px] font-semibold pt-[15px]">Add time slots for selected dates</h2>
            <div className="flex flex-col md:grid md:items-end md:grid-cols-[2fr_2fr_2fr_1fr] justify-between w-full gap-[10px] mt-[10px]">
              <FromSelect name="starttime" options={startTimeSlots} value={starttime} heading="Start Time" onChange={handleStartTime} />
              <FromSelect name="endtime" options={endTimeSlots} value={endtime} heading="End Time" onChange={handleEndTime} />
              <FromSelect name="timeduration" options={timeDurationSlots} value={timeduration} heading="Slot Duration" onChange={handleTimeDuration} />
              <VAButton text="Add" onClick={onClickAddTimeSlot} outlined />
            </div>
            {timeSlotsError && <p className="text-[#FF0000] text-[12px] mt-[5px]">Please select all the fields</p>}
            <div className={`${timeSlots.length > 0 ? "block" : "hidden"}`}>
              <p className="text-[16px] font-[400] text-[#888A8C] mt-[15px]">
                Dates: {selectedDate.month} (
                {selectedDays
                  .map((dayObj) => dayObj.day)
                  .sort((a, b) => a - b)
                  .join(", ")}
                )
              </p>
              <div className={` mt-[12px] bg-white`}>
                {uniqueTimeSlots.map((item: any, index: number) => (
                  <div className="border border-[#CDCFD2] grid grid-cols-4 py-[6px] px-[10px] items-center mt-[0px] text-[#1F2124] text-[16px] font-medium text-center">
                    <p>{item.starttime}</p>
                    <p>{item.endtime}</p>
                    <p>{item.timeduration + "m"}</p>
                    <Box>
                      <span style={{ marginRight: 10 }} className="text-[#114059] text-[16px] font-[600] cursor-pointer" onClick={() => handleDeleteTimeSlot(index)}>
                        Delete
                      </span>
                      <span className="text-[#114059] text-[16px] font-[600] cursor-pointer" onClick={() => handleEditTimeSlot(index)}>
                        Edit
                      </span>
                    </Box>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[20px]">
        <VAButton text="Cancel" outlined onClick={() => setActiveStep(1)} />
        <VAButton text="Save" onClick={handleSave} dark />
      </div>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </div>
  );
};
