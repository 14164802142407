import { AppActions } from "../../types";
import { SEND_LOGIN_DATA } from "../../types/actions/Login";
import { LoginModel } from "../../types/models/Login";

const initialState: {
  loginData: LoginModel;
} = {
  loginData: {
    email: "",
    password: "",
  },
};
const Login = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case SEND_LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };

    default:
      return state;
  }
};
export default Login;
