import { ReactElement } from 'react';
import Radio from '@mui/material/Radio';

interface RadioProper {
  setActive: (e: string) => void;
  options: string[];
  active: string;
}

export const CustomRadioButton = ({
  options,
  active,
  setActive,
}: RadioProper): ReactElement => {
  return (
    <div className="flex items-center gap-[34px]">
      {options?.map((opt) => {
        return (
          <div
            className="border-solid border-[1px] border-[#000000] rounded-[12px] cursor-pointer"
            key={opt}
            onClick={() => {
              setActive(opt);
            }}
          >
            <div className="flex items-center py-[8px] pr-[20px] pl-[10px]">
              <Radio
                name="radio-buttons"
                color="default"
                value={opt}
                checked={active === opt}
              />
              <span className="text-[#1F2124] text-[16px] font-[400]">
                {opt}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
