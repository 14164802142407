import { vaccinesModel } from "../../models/Provider/AddVaccine";

export const GET_PROV_VACCINES = "GET_PROV_VACCINES";
export const GET_PROV_TESTS = "GET_PROV_TESTS";

export interface VaccineAndPaperworkAction {
  type: typeof GET_PROV_VACCINES;
  payload: vaccinesModel;
}

export interface TestsAndPaperworkAction {
  type: typeof GET_PROV_TESTS;
  payload: vaccinesModel;
}

export type VaccineAndPaperworkActionTypes = VaccineAndPaperworkAction | TestsAndPaperworkAction;
