import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DashboardLayout, FormInput, RemoveModal, ScreeningModal, VAButton } from "../../../components";
import VAAlert from "../../../components/Alert/Alert";
import { deleteProviderVaccine, deleteProviderVaccineScreeningItem, getProviderTests } from "../../../redux/actionCreators";
import { AppState } from "../../../redux/store";
import { TestVaccineCards } from "../../../sections/Provider/AddTest";
import { AlertType } from "../../../shared/constants/AppConst";
import { GET_PROV_TESTS } from "../../../types/actions/Provider/vaccineAndPaperwork.action";
export const TestsAndPaperwork = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [selectedVaccine, setselectedVaccine] = useState<any>([]);
  const page = "Test and paperwork";
  const [modalVaccineId, setModalVaccineId] = useState<any>([]);
  const [selectedScreeningId, setScreeningId] = useState<any>(0);
  const [cat, setCat] = useState<any>("");
  const [searchVaccine, setSearchVaccine] = useState("");
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const handleChange = (event: any) => {
    if (event.target.name === "vaccine") {
      setSearchVaccine(event.target.value as string);
    }
  };

  const handleCancel = async () => {
    const selectedVac = selectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId);
    let data = {
      biz_branch_id: userData.selected_branch.biz_branch_id,
      prov_vaccine_id: selectedVac[0].prov_vaccine_id, // vaccine id se provVaccine_id get krni h
    };
    let res = await deleteProviderVaccine(data);
    if (res.message === "success") setAlertMessage({ type: "success", description: "test deleted successfully", open: true });
    await getProviderVaccienData();
  };

  const handleRemove = async (prov_vaccine_screening_id: any) => {
    let data = {
      biz_branch_id: userData.selected_branch.biz_branch_id,
      prov_vaccine_screening_id: prov_vaccine_screening_id,
    };
    await deleteProviderVaccineScreeningItem(data);
    getProviderVaccienData();
    setRemoveModalOpen(false);
  };

  const getProviderVaccienData = async () => {
    try {
      let res = await getProviderTests({ biz_branch_id: userData.selected_branch.biz_branch_id });
      setselectedVaccine(res.data);
      setCat(res.data[0].category);
      dispatch({ type: GET_PROV_TESTS, payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProviderVaccienData();
  }, []);

  const filteredSelectedVaccine = selectedVaccine.filter((vaccine: any) => vaccine.vaccine_name.toLowerCase().includes(searchVaccine.toLowerCase()));

  return (
    <React.Fragment>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      <DashboardLayout>
        <div>
          <div>
            <h2 className="text-[24px] text-primary-main font-[600]">Tests and Paperwork</h2>
          </div>
          <div className="flex items-center justify-between py-[20px]">
            <FormInput label="Search for test 🔍" name="vaccine" value={searchVaccine} onChange={handleChange} />
            <VAButton dark text="Add new Test" onClick={() => navigate("/add-test")} />
          </div>
          {typeof cat !== "undefined" && cat !== "" ? (
            <div className="w-[full] flex gap-[12px] justify-between items-center px-[20px] py-[12px] rounded-t-[12px] gap-[20px]">
              <h2 className="text-[#114059] text-[18px] font-[600]">{cat}</h2>
              <span className="border-t-[1px] border-primary-main"></span>
              <Link to="/view-profile" className="text-[#114059] text-[18px] font-[600] mt-[18px] cursor-pointer" style={{ float: "right" }}>
                View Profile
              </Link>
            </div>
          ) : null}
          <div className="mt-[12px] ">
            <TestVaccineCards
              selectedVaccine={filteredSelectedVaccine}
              setOpen={setOpen}
              setModalVaccineId={setModalVaccineId}
              page={page}
              age_less={undefined}
              age_greater={undefined}
              handleCancel={handleCancel}
              setRemoveModalOpen={setRemoveModalOpen}
              setScreeningId={setScreeningId}
            />
          </div>

          <ScreeningModal page={page} open={open} selectedVaccine={filteredSelectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId)} modalVaccineId={modalVaccineId} setOpen={setOpen} />
          {removeModalOpen ? (
            <RemoveModal
              page={page}
              removeModalOpen={removeModalOpen}
              setRemoveModalOpen={setRemoveModalOpen}
              handleRemove={handleRemove}
              selectedVaccine={filteredSelectedVaccine.filter((x: any) => x.vaccine_id === modalVaccineId)}
              selectedScreeningId={selectedScreeningId}
              modalVaccineId={modalVaccineId}
            />
          ) : null}
        </div>
      </DashboardLayout>
    </React.Fragment>
  );
};
