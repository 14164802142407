import { ReactElement } from 'react';

export const Back = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9999 9.50019H7.4099L8.7099 8.21019C8.8982 8.02188 9.00399 7.76649 9.00399 7.50019C9.00399 7.23388 8.8982 6.97849 8.7099 6.79019C8.52159 6.60188 8.2662 6.49609 7.9999 6.49609C7.73359 6.49609 7.4782 6.60188 7.2899 6.79019L4.2899 9.79019C4.19886 9.88529 4.12749 9.99743 4.0799 10.1202C3.97988 10.3636 3.97988 10.6367 4.0799 10.8802C4.12749 11.0029 4.19886 11.1151 4.2899 11.2102L7.2899 14.2102C7.38286 14.3039 7.49346 14.3783 7.61532 14.4291C7.73718 14.4798 7.86788 14.506 7.9999 14.506C8.13191 14.506 8.26261 14.4798 8.38447 14.4291C8.50633 14.3783 8.61693 14.3039 8.7099 14.2102C8.80362 14.1172 8.87802 14.0066 8.92879 13.8848C8.97956 13.7629 9.00569 13.6322 9.00569 13.5002C9.00569 13.3682 8.97956 13.2375 8.92879 13.1156C8.87802 12.9937 8.80362 12.8831 8.7099 12.7902L7.4099 11.5002H16.9999C17.2651 11.5002 17.5195 11.6055 17.707 11.7931C17.8945 11.9806 17.9999 12.235 17.9999 12.5002V16.5002C17.9999 16.7654 18.1053 17.0198 18.2928 17.2073C18.4803 17.3948 18.7347 17.5002 18.9999 17.5002C19.2651 17.5002 19.5195 17.3948 19.707 17.2073C19.8945 17.0198 19.9999 16.7654 19.9999 16.5002V12.5002C19.9999 11.7045 19.6838 10.9415 19.1212 10.3789C18.5586 9.81626 17.7955 9.50019 16.9999 9.50019V9.50019Z"
        fill="#888A8C"
      />
    </svg>
  );
};
