import { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardLayout, FormInput, VAButton } from "../../../../components";
import VAAlert from "../../../../components/Alert/Alert";
import { onSubmitChangePass } from "../../../../redux/actionCreators";
import { AppState } from "../../../../redux/store";
import { AlertType } from "../../../../shared/constants/AppConst";
import { useFormFieldsHandleChange } from "../../../../utils";

export const ChangePassword = (): ReactElement => {
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [changePassInfo] = useState<any>({ email: userData.email, oldPassword: "", newPassword: "", confirmPassword: "" });
  const [changePassInfoInputs, handlechangePassInfoChange] = useFormFieldsHandleChange(changePassInfo);

  const [errorschangePassInfo, setErrorschangePassInfo] = useState({
    oldPassword: { error: false, message: "" },
    newPassword: { error: false, message: "" },
    confirmPassword: { error: false, message: "" },
  });

  const validateErrorchangePassInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorschangePassInfo({ ...errorschangePassInfo, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorschangePassInfo({ ...errorschangePassInfo, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateFormchangePassInfo = () => {
    let formIsValid = true;
    let localerrorschangePassInfo = errorschangePassInfo;

    if (changePassInfoInputs.oldPassword === "") {
      formIsValid = false;
      localerrorschangePassInfo.oldPassword = { error: true, message: "Old Password is Required!" };
    }

    if (changePassInfoInputs.newPassword === "") {
      formIsValid = false;
      localerrorschangePassInfo.newPassword = { error: true, message: "New Password is Required!" };
    }

    if (changePassInfoInputs.confirmPassword === "") {
      formIsValid = false;
      localerrorschangePassInfo.confirmPassword = { error: true, message: "Confirm Password is Required!" };
    }

    setErrorschangePassInfo({ ...errorschangePassInfo, ...localerrorschangePassInfo });
    return formIsValid;
  };

  const onSubmit = async () => {
    if (!validateFormchangePassInfo()) return;
    if (changePassInfoInputs.newPassword === changePassInfoInputs.confirmPassword) {
      let res = await onSubmitChangePass(changePassInfoInputs);
      if (res.response === "Success") {
        setAlertMessage({ type: "info", description: "Password Updated Successfully.", open: true });
      } else {
        setAlertMessage({ type: "warning", description: "Invalid Old Password.", open: true });
      }
    } else {
      setErrorschangePassInfo({ ...errorschangePassInfo, confirmPassword: { error: true, message: "New password and confirm password are not same." } });
    }
  };

  return (
    <>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      <DashboardLayout>
        <div>
          <h2 className="text-[24px] text-primary-main font-[600]">Change Password</h2>
          <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">Password should have atleast 6 characters</p>
        </div>
        <div className="flex flex-col gap-[30px] w-[380px] mt-[40px]">
          <FormInput
            type="password"
            name="oldPassword"
            label="Old Password"
            value={changePassInfoInputs.oldPassword}
            validate={{ error: errorschangePassInfo.oldPassword.error, message: errorschangePassInfo.oldPassword.message }}
            required
            onChange={handlechangePassInfoChange}
            onBlur={validateErrorchangePassInfo}
          />
          <FormInput
            type="password"
            name="newPassword"
            label="New Password"
            value={changePassInfoInputs.newPassword}
            validate={{ error: errorschangePassInfo.newPassword.error, message: errorschangePassInfo.newPassword.message }}
            required
            onChange={handlechangePassInfoChange}
            onBlur={validateErrorchangePassInfo}
          />
          <FormInput
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            value={changePassInfoInputs.confirmPassword}
            validate={{ error: errorschangePassInfo.confirmPassword.error, message: errorschangePassInfo.confirmPassword.message }}
            required
            onChange={handlechangePassInfoChange}
            onBlur={validateErrorchangePassInfo}
          />
          <div>
            <VAButton text="Update Password" dark onClick={onSubmit} />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
