import jwtAxios from "../../../services/auth/jwt-auth";
import { CreateProfileModel } from "../../../types/models/Provider/createProfile/CreateProfile";

export const onCreateProfile = async (createProfiledata: CreateProfileModel) => {
  try {
    const res = await jwtAxios.post("/profile/createprofile", { createProfiledata });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// export const onCreateProfile = (createProfiledata: CreateProfileModel) => {
//   return (dispatch: Dispatch<AppActions>) => {
//     dispatch(fetchStart());
//     jwtAxios
//       .post("/profile/createprofile", { createProfiledata })
//       .then((data) => {
//         if (data.status === 200) {
//           dispatch(fetchSuccess());
//           dispatch({ type: CREATE_PROFILE, payload: data.data });
//           dispatch(showMessage("Profile Created Successfully"));
//         } else {
//           dispatch(fetchError("Something went wrong while creating profile"));
//         }
//       })
//       .catch((error) => {
//         dispatch(fetchError(error.message));
//       });
//   };
// };
