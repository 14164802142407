import React, { Fragment, ReactElement } from "react";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FromSelect, InputWithIcon } from "../../../components";
import { AddIcon } from "../../../icons";
import BranchesTable from "./BranchesTable";
import ViewMainBranch from "./ViewMainBranch";

interface ManageBizSectionProps {
  mainBranch: any[];
  editMainBranch: boolean;
  setEditMainBranch: any;
  subBranches: any[];
  searchSubBranchName: any;
  searchStatus: any;
  statusOptions: any[];
  searchLocType: any;
  locTypeOptions: any[];
  allLocTypeOptions: any[];
  handleChange: any;
  onClickUpdateBiz: any;
  onClickAddEditLocation: any;
}

export const ManageBizSection = ({
  mainBranch,
  editMainBranch,
  setEditMainBranch,
  subBranches,
  searchSubBranchName,
  searchStatus,
  statusOptions,
  searchLocType,
  locTypeOptions,
  allLocTypeOptions,
  handleChange,
  onClickUpdateBiz,
  onClickAddEditLocation,
}: ManageBizSectionProps): ReactElement => {
  const ViewSubBranch = () => {
    return (
      <Fragment>
        <Box flex={1} mt={2}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h2 className="text-[24px] text-primary-main font-[600]">Locations</h2>
              <Tooltip title={"Add new location"}>
                <div className="cursor-pointer ml-2" onClick={() => onClickAddEditLocation("ADD")}>
                  <AddIcon />
                </div>
              </Tooltip>
            </div>
          </div>
          <Stack direction="row" justifyContent="space-between" sx={{ mb: { xs: 3, xl: 4 }, mt: { xs: 2, xl: 3 } }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputWithIcon label="Search by name" name="searchSubBranchName" value={searchSubBranchName} onChange={handleChange} StartIcon={SearchIcon} />
              </Grid>

              <Grid item xs={3}>
                <FromSelect name="searchStatus" label="Status" value={searchStatus} onChange={handleChange} options={statusOptions} />
              </Grid>
              <Grid item xs={3}>
                <FromSelect name="searchLocType" label="Location Type" value={searchLocType} onChange={handleChange} options={locTypeOptions} />
              </Grid>
            </Grid>
          </Stack>
          <Box flex={1} mt={2}>
            <Box mx="auto">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BranchesTable branches={subBranches} onClickEditLocation={onClickAddEditLocation} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ViewMainBranch mainBranch={mainBranch} editMainBranch={editMainBranch} setEditMainBranch={setEditMainBranch} allLocTypeOptions={allLocTypeOptions} onClickUpdateBiz={onClickUpdateBiz} />
      <ViewSubBranch />
    </Fragment>
  );
};
