import { ReactElement } from "react";

export const Share = (): ReactElement => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4544_6461)">
        <path
          d="M9.00488 4.53073C4.01761 4.79044 0.00683594 8.92997 0.00683594 13.9805V17.5L1.26606 14.5685C2.75536 11.5904 5.7102 9.68628 9.00488 9.5112V13.5393L16.9952 7.00781L9.00488 0.5V4.53073Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4544_6461">
          <rect width="17" height="17" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
