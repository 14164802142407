import React from "react";
import { styled } from "@mui/material/styles";
import { Stack, TableCell, TableRow } from "@mui/material";
import { VAButton } from "../../../../components";

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));

interface TableItemProps {
  row: any;
  onClickDelete: (category_id: any) => void;
  onClickEdit: (category_id: any) => void;
}

const TableItem: React.FC<TableItemProps> = ({ row, onClickDelete, onClickEdit }) => {
  const actionCell = () => {
    return (
      <Stack direction="row" alignItems="start" justifyContent={"center"} spacing={2}>
        <VAButton size="small" dark text="Edit" onClick={() => onClickEdit(row.id)} />
        <VAButton size="small" light text="Delete" onClick={() => onClickDelete(row.id)} />
      </Stack>
    );
  };

  return (
    <React.Fragment>
      <TableRow key={row.id} className="item-hover">
        <StyledTableCell align="left">{row.name}</StyledTableCell>
        <StyledTableCell align="left">{row.file_name}</StyledTableCell>
        <StyledTableCell align="center">{actionCell()}</StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default TableItem;
