import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../../components/Table/TableHeader";
const TableHeading: React.FC = () => {
  return (
    <TableHeader>
      <TableCell align="left">Diagnostic</TableCell>
      <TableCell align="left">Category</TableCell>
      <TableCell align="left">Manufacturer</TableCell>
      <TableCell align="right">No of Doses</TableCell>
      <TableCell align="left">Timespan</TableCell>
      <TableCell align="right">Timespan No</TableCell>
      <TableCell align="right">Age Group</TableCell>
      <TableCell align="center">Action</TableCell>
    </TableHeader>
  );
};

export default TableHeading;
