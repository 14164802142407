import React from "react";
import { Box, Modal } from "@mui/material";
import { VAButton } from "..";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};
interface props {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  heading?: string;
  subHeading?: string;
  onClickConfirm?: () => void;
}
export const ConfirmDialog: React.FC<props> = ({ heading, subHeading, open, setOpen, onClickConfirm }) => {
  const handleClose = () => setOpen(false);
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
          <div className="max-w-[360px] flex flex-col gap-[22px] mt-[17px]">
            <h2 className="text-[20px] font-[600] text-[#000000] text-center">{heading}</h2>
            <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] text-center">{subHeading}</span>
          </div>
          <div className="flex flex-col gap-[20px] mt-[28px] w-full">
            <VAButton text="Confirm" dark onClick={onClickConfirm} />
            <VAButton text="Cancel" onClick={handleClose} outlined />
          </div>
        </div>
      </Box>
    </Modal>
  );
};
