import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Stack, TableCell, TableRow } from "@mui/material";
import { ConfirmDialog, VAButton } from "../../../../components";
import { suspendBusiness } from "../../../../redux/actionCreators/SuperAdmin/Business";
import VAAlert from "../../../../components/Alert/Alert";
import { AlertType } from "../../../../shared/constants/AppConst";

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));

interface TableItemProps {
  data: any;
  setBusinesses: any;
}

const TableItem: React.FC<TableItemProps> = ({ data, setBusinesses }) => {
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const onClickSuspend = () => {
    setOpenConfirm(true);
  };
  const onClickConfirm = async () => {
    try {
      let res = await suspendBusiness({ biz_id: data.biz_id, biz_active: data.biz_active ? false : true });
      setBusinesses(res.businesses);
      setOpenConfirm(false);
      setAlertMessage({ type: "success", description: res.message, open: true });
    } catch (err) {
      console.log(err);
    }
  };
  const actionCell = () => {
    return (
      <Stack direction="row" alignItems="start" justifyContent={"center"} spacing={2}>
        <VAButton size="small" light text={!data.biz_active ? "Resume" : "Suspend"} onClick={onClickSuspend} />
      </Stack>
    );
  };
  return (
    <React.Fragment>
      <TableRow key={data.id} className="item-hover">
        <StyledTableCell align="left">{data.biz_name}</StyledTableCell>
        <StyledTableCell align="left">{data.provider_first_name}</StyledTableCell>
        <StyledTableCell align="left">{data.biz_address}</StyledTableCell>
        <StyledTableCell align="left">{data.loc_name}</StyledTableCell>
        <StyledTableCell align="left">{data.biz_email}</StyledTableCell>
        <StyledTableCell align="left">{data.biz_phone_no}</StyledTableCell>
        <StyledTableCell align="left">{!data.biz_active ? "YES" : "NO"}</StyledTableCell>
        {!data.verify ? <StyledTableCell align="center">{actionCell()}</StyledTableCell> : null}
      </TableRow>
      <ConfirmDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        heading="Are you sure?"
        subHeading={`you want to ${data.biz_active ? "suspend" : "resume"} this provider`}
        onClickConfirm={onClickConfirm}
      />
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </React.Fragment>
  );
};

export default TableItem;
