import { parse } from "date-fns";
import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardLayout } from "../../../components";
import useDate from "../../../hooks/useDate";
import { getVaccineDetail, getVaccineSchedules } from "../../../redux/actionCreators";
import { AppState } from "../../../redux/store";
import { VaccineSchedule, CreateVaccineSchedule } from "../../../sections/Provider/VaccineSchedule";
import { AlertType } from "../../../shared/constants/AppConst";
export const VaccineSchedules = (): ReactElement => {
  const date = useDate();
  const [activeStep, setActiveStep] = useState(1);
  const { userData } = useSelector<AppState, AppState["User"]>(({ User }) => User);
  const [selectedMonthEvents, setSelectedMonthEvents] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const handleCreateNewClick = async () => {
    try {
      const dateObject = parse(`${date.month}, ${date.year}`, "MMMM, yyyy", new Date());
      let data = { biz_branch_id: userData.selected_branch.biz_branch_id, date: dateObject };
      let res = await getVaccineDetail(data);
      setSelectedMonthEvents(res.vaccineSchedulles);
      setActiveStep(2);
    } catch (err) {
      console.log(err);
    }
  };

  const getVaccineSchedulesData = async () => {
    try {
      const dateObject = parse(`${date.month}, ${date.year}`, "MMMM, yyyy", new Date());
      let data = {
        biz_branch_id: userData.selected_branch.biz_branch_id,
        // prov_vaccine_id: prov_vaccine_id,
        date: dateObject,
      };
      let res = await getVaccineSchedules(data);

      setSelectedMonthEvents(res.data);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const getProviderVaccienData = async () => {
      await getVaccineSchedulesData();
    };
    getProviderVaccienData();
  }, []);

  return (
    <DashboardLayout>
      {activeStep === 1 ? (
        <VaccineSchedule activeStep={activeStep} setSelectedMonthEvents={setSelectedMonthEvents} selectedMonthEvents={selectedMonthEvents} handleCreateNewClick={handleCreateNewClick} />
      ) : null}
      {activeStep === 2 ? (
        <CreateVaccineSchedule
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setSelectedMonthEvents={setSelectedMonthEvents}
          selectedMonthEvents={selectedMonthEvents}
          // ALERT
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      ) : null}
    </DashboardLayout>
  );
};
