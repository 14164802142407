import React from "react";
import { Table, TableHead, TableBody } from "@mui/material";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";

interface CategoriesTableProps {
  categories: any[];
  onClickEdit: (rowData: any) => void;
  onClickDelete: (category_id: any) => void;
}

const CategoriesTable: React.FC<CategoriesTableProps> = ({ categories, onClickEdit, onClickDelete }) => {
  return (
    <Table stickyHeader sx={{ minWidth: 650 }} size="small" className="table">
      <TableHead>
        <TableHeading />
      </TableHead>
      <TableBody>
        {categories.map((data: any, index: number) => (
          <TableItem data={data} key={data.id} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
        ))}
      </TableBody>
    </Table>
  );
};

export default CategoriesTable;
