import jwtAxios from "../../../services/auth/jwt-auth";

export const getManufacturers = async () => {
  try {
    const res = await jwtAxios.post("/superadmin/manufacturers/getmanufacturers", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const addManufacturer = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/manufacturers/addmanufacturer", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const updateManufacturer = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/manufacturers/updatemanufacturer", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteManufacturer = async (data: any) => {
  try {
    const res = await jwtAxios.post("/superadmin/manufacturers/deletemanufacturer", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
