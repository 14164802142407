import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";

import "./App.scss";
import {
  Login,
  CreateProfile,
  SignUp,
  ForgotPassword,
  ResetPassword,
  ScheduleAppointment,
  PendingVaccineAppointment,
  VaccinesAndPaperwork,
  AddVaccine,
  VaccineSchedules,
  MyProfile,
  ManageUsers,
  ChangePassword,
  VaccineDataImporter,
  Categories,
  ViewProfile,
  Manufacturers,
  Businesses,
  Vaccines,
  Diagnostics,
  TestsAndPaperwork,
  AddTest,
  UploadVIS,
  ManageBiz,
} from "./pages";

const store = configureStore();

function App() {
  return (
    <div>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/create-profile" element={<CreateProfile />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/schedule-appointment" element={<ScheduleAppointment />} />
            <Route path="/pending-vaccine-appointment" element={<PendingVaccineAppointment />} />
            <Route path="/add-vaccine" element={<AddVaccine />} />
            <Route path="/vaccines-paperwork" element={<VaccinesAndPaperwork />} />
            <Route path="/view-profile" element={<ViewProfile />} />
            <Route path="/vaccine-schedule" element={<VaccineSchedules />} />
            <Route path="/tests-paperwork" element={<TestsAndPaperwork />} />
            <Route path="/add-test" element={<AddTest />} />
            <Route path="/manage-biz" element={<ManageBiz />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route path="/change-password" element={<ChangePassword />} />
            {/* Super Admin Rotues */}
            <Route path="/superadmin/vaccine-data-importer" element={<VaccineDataImporter />} />
            <Route path="/superadmin/categories" element={<Categories />} />
            <Route path="/superadmin/manufacturers" element={<Manufacturers />} />
            <Route path="/superadmin/vaccines" element={<Vaccines />} />
            <Route path="/superadmin/diagnostics" element={<Diagnostics />} />
            <Route path="/superadmin/uploadvis" element={<UploadVIS />} />
            <Route path="/superadmin/businesses" element={<Businesses />} />
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
