import { ReactElement, useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormFieldsHandleChange } from "../../utils";
import { LoginForm } from "../../sections/Login";
import { onSubmitLogin } from "../../redux/actionCreators/Login";
import { SET_USER_DATA } from "../../types/actions/user.action";
import { AlertType } from "../../shared/constants/AppConst";
import VAAlert from "../../components/Alert/Alert";
import { LoginModel } from "../../types/models/Login";

export const Login = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fields, handleFieldChange] = useFormFieldsHandleChange<LoginModel>({
    email: "",
    password: "",
  });
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const onBlurField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "email" && !value) {
      setErrorFields({
        ...errorFields,
        email: { error: true, message: "Required Field" },
      });
    } else if (name === "email" && !emailPattern.test(value)) {
      setErrorFields({
        ...errorFields,
        email: { error: true, message: "Invalid Email" },
      });
    } else if (name === "password" && !value) {
      setErrorFields({
        ...errorFields,
        password: { error: true, message: "Required Field" },
      });
    } else {
      setErrorFields({
        ...errorFields,
        email: { error: false, message: "" },
        password: { error: false, message: "" },
      });
    }
  };
  const [errorFields, setErrorFields] = useState({
    email: { error: false, message: "" },
    password: { error: false, message: "" },
  });
  const validateForm = () => {
    let formIsValid = true;
    const errorFields = {
      email: { error: false, message: "" },
      password: { error: false, message: "" },
    };
    if (!fields.email) {
      errorFields.email.error = true;
      errorFields.email.message = "Required Field";
      formIsValid = false;
    } else if (!emailPattern.test(fields.email)) {
      errorFields.email.error = true;
      errorFields.email.message = "Invalid Email";
      formIsValid = false;
    }
    if (!fields.password) {
      errorFields.password.error = true;
      errorFields.password.message = "Required Field";
      formIsValid = false;
    }
    setErrorFields(errorFields);
    return formIsValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        let res = await onSubmitLogin(fields);
        if (res.status === "SUCCESS") {
          dispatch({ type: SET_USER_DATA, payload: res.data });
          if (res.data.type === "VACCIFI") {
            navigate("/superadmin/vaccine-data-importer");
          } else {
            navigate("/schedule-appointment");
          }
        } else {
          setAlertMessage({ type: "error", description: res.message, open: true });
        }
      } catch (err) {
        console.log("Login failed: ", err);
        setAlertMessage({ type: "error", description: "Something went wrong. Please contact with system administrator", open: true });
      }
    }
  };
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isRegistered = searchParams.get("registered");
    if (isRegistered === "true") {
      setAlertMessage({ type: "success", description: "Profile created successfully!", open: true });
    }
  }, [location.search]);
  return (
    <Fragment>
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
      <div className="flex gap-[40px] w-full">
        <div className="hidden md:block md:w-[55%] md:relative ">
          <div className="w-full h-[100vh]">
            <img src={`${process.env.PUBLIC_URL}/imgs/sider-image.png`} alt="Vaccifi" className="object-cover fixed w-[55%] h-full" />
          </div>
          <div className="absolute left-[50px] top-[40px]">
            <img src={`${process.env.PUBLIC_URL}/imgs/sider-text-image.svg`} alt="vaccine text" className="fixed w-[25%]" />
          </div>
        </div>
        <div className="w-full p-[20px]  flex justify-center items-center md:w-[45%]">
          <div className="w-[380px]">
            <div>
              <h2 className="text-[20px] font-[600] text-[#0F5175]">Sign In</h2>
              <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">
                Log in to your account using your
                <br />
                email & password
              </p>
            </div>
            <div className="mt-[30px]">
              <LoginForm fields={fields} handleFieldChange={handleFieldChange} onBlurField={onBlurField} errorFields={errorFields} handleSubmit={handleSubmit} />
            </div>

            <div style={{ textAlign: "center" }} className="mt-[40px]">
              <span className="text-[16px] font-[400] text-[#888A8C]">
                Don’t have an account? &nbsp;
                <Link to="/signup" className="text-[16px] text-[#000000] font-[400px] underline">
                  Sign Up
                </Link>
              </span>
            </div>
            <div style={{ textAlign: "center" }} className="mt-[28px]">
              <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">
                By clicking "Continue", you agree to our
                <span className="text-[14px] font-[400] text-[#276EF1]">Terms of Service and Privacy Policy</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
