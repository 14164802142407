import { ReactElement } from 'react';

export const KeyPassword = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M8.73433 11.5154C8.15618 10.0733 8.09367 8.47581 8.55738 6.99295C9.02108 5.5101 9.98256 4.23282 11.2793 3.37703C12.576 2.52124 14.1285 2.13942 15.6742 2.29612C17.22 2.45281 18.6642 3.13841 19.7628 4.23702C20.8614 5.33563 21.547 6.77987 21.7037 8.32562C21.8604 9.87137 21.4786 11.4238 20.6228 12.7205C19.767 14.0173 18.4897 14.9787 17.0069 15.4424C15.524 15.9062 13.9265 15.8436 12.4844 15.2655L12.4845 15.2653L11.25 16.4998H9V18.7498H6.75V20.9998H3V17.2498L8.7345 11.5153L8.73433 11.5154Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.5"
          d="M16.875 7.5C17.0821 7.5 17.25 7.33211 17.25 7.125C17.25 6.91789 17.0821 6.75 16.875 6.75C16.6679 6.75 16.5 6.91789 16.5 7.125C16.5 7.33211 16.6679 7.5 16.875 7.5Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.875 8.25C17.4963 8.25 18 7.74632 18 7.125C18 6.50368 17.4963 6 16.875 6C16.2537 6 15.75 6.50368 15.75 7.125C15.75 7.74632 16.2537 8.25 16.875 8.25Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
